import { Box, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { FilesRepoFileLogic, FilesRepoLogic } from 'src/api';
import {
  EmbeddingsModelType,
  FineTuneModelType,
  ModelType,
  embeddingsLlmModels,
  fineTuneLlmModels,
  llmModels,
} from 'src/common/constants/llm-models.constants';
import { RHSelect } from 'src/components/hook-form';
import { useSelector } from 'src/redux/store';

export interface Props {
  maxWidth?: number;
  onFilesRepoFileSelect: (filesRepoFile: string) => void;
  selectedFilesRepoFileInput?: string | null | undefined;
  filesRepoFiles: FilesRepoFileLogic[];
}

export default function FilesRepoFileSelect({
  maxWidth,
  onFilesRepoFileSelect,
  filesRepoFiles,
  selectedFilesRepoFileInput,
}: Props) {
  const [selectedFilesRepoFile, setSelectedFilesRepoFile] = useState<string>(
    (filesRepoFiles?.length > 0 ?  filesRepoFiles[0].name : '')  ?? ''
  );

  return (
    <>
      <Box sx={{ maxWidth, px: 1 }}>
        <RHSelect
          key="selectFilesRepoFile"
          name="selectFilesRepoFile"
          zIndex={3400}
          size="small"
          required
          sx={{ maxWidth: maxWidth as number }}
          label="Select File"
          value={selectedFilesRepoFileInput ?? selectedFilesRepoFile}
          onChange={(event) => {
            setSelectedFilesRepoFile(event.target.value);
            onFilesRepoFileSelect(event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          // sx={{ maxWidth: { md: 180 } }}
        >
          {filesRepoFiles.map((kbf) => (
            <MenuItem value={kbf.name as string}>
              <span>{kbf.name}</span>
            </MenuItem>
          ))}
        </RHSelect>
      </Box>
    </>
  );
}
