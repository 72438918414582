import { yupResolver } from '@hookform/resolvers/yup';
import { MenuItem, Stack, TextField } from '@mui/material';
import _, { set } from 'lodash';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormProvider, { RHSelect } from 'src/components/hook-form';
import PreviousActionsSelect from 'src/components/previous-actions/PreviousActionsSelect';
import { setActionValidated, updateSelectedAiFlowItem, updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';

export default function AiFlowValidatorActionSetup() {
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const [selectedTargetSource, setSelectedTargetSource] = useState<string>();
  const [selectedExpectedSource, setSelectedExpectedSource] = useState<string>();
  const [selectedValidatorType, setSelectedValidatorType] = useState<string>('');

  const [selectedAutomaticValidationType, setSelectedAutomaticValidationType] =
    useState<string>('Redirect');
  const [selectedManualValidationType, setSelectedManualValidationType] =
    useState<string>('Data Accuracy');
  const defaultValues = {
    targetSource: '',
    expectedSource: '',
  };
  const [validatorTypes, setValidatorTypes] = useState([
    { label: 'Manual', value: 'Manual' },
    { label: 'Automatic', value: 'Automatic' },
  ]);

  const [automaticValidationTypes, setAutomaticValidationTypes] = useState([
    { label: 'Link', value: 'Link' },
    { label: 'Redirect', value: 'Redirect' },
  ]);

  const [manualValidationType, setManualValidationType] = useState([
    { label: 'Data Accuracy', value: 'Data Accuracy' },
    { label: 'Compliance', value: 'Compliance' },
    { label: 'Formatting', value: 'Formatting' },
    { label: 'Error Detection', value: 'Error Detection' },
    { label: 'Other', value: 'Other' },
  ]);

  const manualValidationDescriptionByType = {
    'Data Accuracy': `e.g. Ensuring that the AI-generated product descriptions accurately reflect the features and specifications of the products listed in the company's inventory.`,
    Compliance: `e.g. Verifying that the AI-generated content for marketing campaigns adheres to advertising regulations and company policies, such as avoiding false claims and ensuring appropriate language use.`,
    Formatting: `e.g. Checking that AI-generated reports or articles maintain consistent formatting, such as proper headings, bullet points, and paragraph structure, to meet the company's style guidelines.`,
    'Error Detection': `e.g. Identifying and correcting any factual inaccuracies or logical inconsistencies in AI-generated content, such as historical dates, numerical data, or event sequences.`,
    Other: `e.g. Validating that AI-generated emails or chat responses are contextually appropriate, maintain a professional tone, and address the customer's query effectively.`,
  };

  const AiFlowValidatorActionSetupSchema: any = Yup.object().shape({
    //  targetSource: Yup.string().required('Required'),
    //   expectedSource: Yup.string().required('Required'),
  });

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowValidatorActionSetupSchema),
    mode: 'all',
    defaultValues,
  });

  const [selectedManualValidatorDescription, setSelectedManualValidatorDescription] =
    useState<string>('');

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.inputData?.actionInputData?.functionalInputData?.validatorInput) {
        setSelectedTargetSource(
          selectedItem.inputData?.actionInputData?.functionalInputData?.validatorInput
            ?.targetSource as string
        );
        methods.setValue(
          'targetSource',
          selectedItem.inputData?.actionInputData?.functionalInputData?.validatorInput
            ?.targetSource as string
        );
        setSelectedExpectedSource(
          selectedItem.inputData?.actionInputData?.functionalInputData?.validatorInput
            ?.expectedSource as string
        );
        methods.setValue(
          'expectedSource',
          selectedItem.inputData?.actionInputData?.functionalInputData?.validatorInput
            ?.targetSource as string
        );
        methods.trigger();
        setSelectedValidatorType(
          selectedItem.inputData?.actionInputData?.functionalInputData?.validatorInput
            ?.validatorType as string
        );
        setSelectedAutomaticValidationType(
          selectedItem.inputData?.actionInputData?.functionalInputData?.validatorInput
            ?.automaticValidationType as string
        );
        setSelectedManualValidationType(
          (selectedItem.inputData?.actionInputData?.functionalInputData?.validatorInput
            ?.manualValidationType as string) ?? 'Data Accuracy'
        );
        setSelectedManualValidatorDescription(
          selectedItem.inputData?.actionInputData?.functionalInputData?.validatorInput
            ?.manualValidationDescription as string
        );
      }
    }
  }, [selectedItem, methods]);

  const dispatch = useDispatch();
  const {
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
    trigger,
  } = methods;

  useEffect(() => {
    dispatch(setActionValidated(isValid));
  }, [isValid, dispatch]);
  return (
    <>
      <FormProvider methods={methods}>
        <Stack spacing={3} sx={{ mt: 2, mx: 1 }}>
          <RHSelect
            key="selectedValidatorType"
            name="selectedValidatorType"
            required
            variant="standard"
            size="small"
            label="Select Validator Type"
            zIndex={3400}
            value={selectedValidatorType}
            sx={{ px: 1 }}
            onChange={(event) => {
              setSelectedValidatorType(event.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    functionalInputData: {
                      ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                      validatorInput: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData
                          ?.validatorInput,
                        validatorType: event.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
            InputLabelProps={{ shrink: true }}
          >
            {validatorTypes.map((validationType) => (
              <MenuItem value={validationType.value}>
                <span>{validationType.label}</span>
              </MenuItem>
            ))}
          </RHSelect>
          {selectedValidatorType === 'Manual' && (
            <>
              <RHSelect
                key="selectedManualValidationType"
                name="selectedManualValidationType"
                size="small"
                required
                variant="standard"
                label="Select Manual Validation Type"
                zIndex={3400}
                value={selectedManualValidationType}
                sx={{ px: 1 }}
                onChange={(event) => {
                  setSelectedManualValidationType(event.target.value);

                  const updatedItem = {
                    ...selectedItem,
                    inputData: {
                      ...selectedItem?.inputData,
                      actionInputData: {
                        ...selectedItem?.inputData?.actionInputData,
                        functionalInputData: {
                          ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                          validatorInput: {
                            ...selectedItem?.inputData?.actionInputData?.functionalInputData
                              ?.validatorInput,
                            manualValidationType: event.target.value,
                          },
                        },
                      },
                    },
                  };
                  dispatch(updateTempSelectedAiFlowItem(updatedItem));
                }}
                InputLabelProps={{ shrink: true }}
              >
                {manualValidationType.map((manualValidation) => (
                  <MenuItem value={manualValidation.value}>
                    <span>{manualValidation.label}</span>
                  </MenuItem>
                ))}
              </RHSelect>
              <TextField
                placeholder={
                  manualValidationDescriptionByType[
                    selectedManualValidationType as keyof typeof manualValidationDescriptionByType
                  ]
                }
                key="manualValidationDescription"
                name="manualValidationDescription"
                label="Description(Optional)"
                value={selectedManualValidatorDescription}
                onChange={(event) => {
                  setSelectedManualValidatorDescription(event.target.value);
                  setValue('manualValidationDescription', event.target.value);
                  const updatedItem = {
                    ...selectedItem,
                    inputData: {
                      ...selectedItem?.inputData,
                      actionInputData: {
                        ...selectedItem?.inputData?.actionInputData,
                        functionalInputData: {
                          ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                          validatorInput: {
                            ...selectedItem?.inputData?.actionInputData?.functionalInputData
                              ?.validatorInput,
                            manualValidationDescription: event.target.value,
                          },
                        },
                      },
                    },
                  };
                  dispatch(updateTempSelectedAiFlowItem(updatedItem));
                }}
                multiline
                rows={4}
                sx={{ px: 1 }}
                InputLabelProps={{ shrink: true }}
              />
            </>
          )}
          {selectedValidatorType === 'Automatic' && (
            <>
              <RHSelect
                key="selectedAutomaticValidationType"
                name="selectedAutomaticValidationType"
                size="small"
                required
                variant="standard"
                label="Select Automatic Validation Type"
                zIndex={3400}
                value={selectedAutomaticValidationType}
                sx={{ px: 1 }}
                onChange={(event) => {
                  setSelectedAutomaticValidationType(event.target.value);
                  const updatedItem = {
                    ...selectedItem,
                    inputData: {
                      ...selectedItem?.inputData,
                      actionInputData: {
                        ...selectedItem?.inputData?.actionInputData,
                        functionalInputData: {
                          ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                          validatorInput: {
                            ...selectedItem?.inputData?.actionInputData?.functionalInputData
                              ?.validatorInput,
                              automaticValidationType: event.target.value,
                          },
                        },
                      },
                    },
                  };
                  dispatch(updateTempSelectedAiFlowItem(updatedItem));
                }}
                InputLabelProps={{ shrink: true }}
              >
                {automaticValidationTypes.map((autoValidationType) => (
                  <MenuItem value={autoValidationType.value}>
                    <span>{autoValidationType.label}</span>
                  </MenuItem>
                ))}
              </RHSelect>

              <PreviousActionsSelect
                name="targetSource"
                label="Target Source"
                selectedSourceInput={selectedTargetSource}
                onPreviousActionsSelect={(source: string) => {
                  setSelectedTargetSource(source);
                  setValue('targetSource', source);
                  const updatedItem = {
                    ...selectedItem,
                    inputData: {
                      ...selectedItem?.inputData,
                      actionInputData: {
                        ...selectedItem?.inputData?.actionInputData,
                        functionalInputData: {
                          ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                          validatorInput: {
                            ...selectedItem?.inputData?.actionInputData?.functionalInputData
                              ?.validatorInput,
                            targetSource: source,
                          },
                        },
                      },
                    },
                  };

                  dispatch(updateTempSelectedAiFlowItem(updatedItem));
                }}
              />
              {selectedAutomaticValidationType === 'Redirect' && (
                <PreviousActionsSelect
                  name="expectedSource"
                  label="Expected Source"
                  selectedSourceInput={selectedExpectedSource}
                  onPreviousActionsSelect={(source: string) => {
                    setSelectedExpectedSource(source);
                    setValue('expectedSource', source);
                    const updatedItem = {
                      ...selectedItem,
                      inputData: {
                        ...selectedItem?.inputData,
                        actionInputData: {
                          ...selectedItem?.inputData?.actionInputData,
                          functionalInputData: {
                            ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                            validatorInput: {
                              ...selectedItem?.inputData?.actionInputData?.functionalInputData
                                ?.validatorInput,
                              expectedSource: source,
                            },
                          },
                        },
                      },
                    };

                    dispatch(updateTempSelectedAiFlowItem(updatedItem));
                  }}
                />
              )}
            </>
          )}
        </Stack>
      </FormProvider>
    </>
  );
}
