import { Box, Tooltip } from '@mui/material';
import { format } from 'date-fns';

export const renderDateCell = (params: any) => {
  if (!params.value) {
    return <Box sx={{ fontStyle: 'italic' }}>N/A</Box>;
  }
  const formattedDate = format(new Date(params.value), 'dd/MM/yyyy HH:mm:ss');
  return (
    <Tooltip title={formattedDate}>
      <div>{formattedDate}</div>
    </Tooltip>
  );
};

export const renderCell = (params: any, withToolTip: boolean = true) => {
  if (params.value === null || params.value === undefined || params.value === '') {
    // If value is null or empty, return italicized placeholder
    return <Box sx={{ fontStyle: 'italic' }}>N/A</Box>;
  } else if (withToolTip) {
    return (
      <Tooltip title={params.value}>
        <Box>{params.value}</Box>
      </Tooltip>
    );
  } else {
    return <Box>{params.value}</Box>;
  }
};

export const renderCellTooltipLeft = (params: any) => {
  if (params.value === null || params.value === undefined || params.value === '') {
    // If value is null or empty, return italicized placeholder
    return <Box sx={{ fontStyle: 'italic' }}>N/A</Box>;
  } else {
    // Otherwise, return the value normally
    return (
      <Tooltip placement="left" title={params.value}>
        <Box>{params.value}</Box>
      </Tooltip>
    );
  }
};

export const renderFixedNumberCell = (params: any, fixedNumber: number) => {
  if (
    params.value === null ||
    params.value === undefined ||
    params.value === '' ||
    params.value === 0.0
  ) {
    // If value is null or empty, return italicized placeholder
    return <Box sx={{ fontStyle: 'italic' }}>N/A</Box>;
  } else {
    // Otherwise, return the value normally
    return (
      <Tooltip title={params.value.toFixed(1)}>
        <Box>{params.value.toFixed(1)}</Box>
      </Tooltip>
    );
  }
};
