import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import { updateSelectedAiFlowItem, updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { RHRadioGroup } from 'src/components/hook-form/RHFRadioGroup';
import ProviderSelect from 'src/components/provider-select/ProviderSelect';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import ConnectionSelect from 'src/components/connection/ConnectionSelect';
import MailchimpTriggerWebhookEventsSelect from './providers/MailchimpTriggerWebhookEventsSelect';
import MailchimpListSelect from '../system/input/providers/mailchimp/MailchimpListSelect';

interface Params {
  item?: any;
}

export default function AiFlowTriggerActionSetup({ item }: Params) {
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const [mailchimpListId, setMailchimpListId] = useState<string>('');
  const [webhookEvents, setWebhookEvents] = useState<string[]>([]);
  const [webhookEventType, setWebhookEventType] = useState<string>('');
  const [selectedProvider, setSelectedProvider] = useState<string>();
  const [selectedConnection, setSelectedConnection] = useState<number>();
  const [triggerOptions, setTriggerOptions] = useState([
    { label: 'Provider', value: 'Provider', disabled: runsMode },
    { label: 'Web Hook', value: 'Web Hook', disabled: runsMode },
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTrigger, setSelectedTrigger] = useState<string>('Provider');
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedItem?.inputData?.actionInputData?.triggerData) {
      setSelectedTrigger(
        selectedItem?.inputData?.actionInputData?.triggerData?.triggerType || 'Provider'
      );
      setSelectedProvider(
        selectedItem?.inputData?.actionInputData?.triggerData?.providerTriggerData?.provider || ''
      );
      setSelectedConnection(
        selectedItem?.inputData?.actionInputData?.triggerData?.providerTriggerData?.connectionId ||
          0
      );
      setWebhookEvents(
        selectedItem?.inputData?.actionInputData?.triggerData?.providerTriggerData?.webhookEvents ||
          []
      );
      setWebhookEventType(
        selectedItem?.inputData?.actionInputData?.triggerData?.webhookTriggerData?.eventType || ''
      );
      setMailchimpListId(
        selectedItem?.inputData?.actionInputData?.triggerData?.providerTriggerData
          ?.mailChimpProviderTriggerData?.listId || ''
      );
    }
  }, [selectedItem]);

  return (
    <>
      <Stack spacing={3}>
        <>
          <RHRadioGroup
            value={selectedTrigger}
            onChange={(event) => {
              setSelectedTrigger(event.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    triggerData: {
                      ...selectedItem?.inputData?.actionInputData?.triggerData,
                      triggerType: event.target.value,
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
            row
            spacing={4}
            name="triggerOptions"
            options={triggerOptions}
          />
          {selectedTrigger === 'Web Hook' && (
            <>
              <Stack spacing={3}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `https://trigger.kuverto.com/${selectedAiFlow?.id}`
                            );
                            enqueueSnackbar('Web Hook URL copied to clipboard', {
                              variant: 'success',
                              // position center top
                              anchorOrigin: { vertical: 'top', horizontal: 'center' },
                            });
                          }}
                        >
                          <Iconify icon="ph:copy" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  value={`https://trigger.kuverto.com/${selectedAiFlow?.id}`}
                  name="webHookUrl"
                  label="Web Hook URL"
                  disabled
                />

                <TextField
                  disabled={runsMode}
                  value={webhookEventType}
                  onChange={(e) => {
                    const updatedItem = {
                      ...selectedItem,
                      inputData: {
                        ...selectedItem?.inputData,
                        actionInputData: {
                          ...selectedItem?.inputData?.actionInputData,
                          triggerData: {
                            ...selectedItem?.inputData?.actionInputData?.triggerData,
                            webhookTriggerData: {
                              ...selectedItem?.inputData?.actionInputData?.triggerData?.webhookTriggerData,
                              eventType: e.target.value,
                            },
                          },
                        },
                      },
                    };
                    dispatch(updateTempSelectedAiFlowItem(updatedItem));
                  }}
                  name="webHookEventType"
                  label="Event Type(Optional)"
                />
                <TextField
                  disabled={runsMode}
                  name="webHookHeaders"
                  label="Custom Headers(Optional)"
                />
              </Stack>
            </>
          )}
          {selectedTrigger === 'Provider' && (
            <>
              <ProviderSelect
              notInGroups={['large language models']}
                disabled={runsMode}
                selectedProviderInput={selectedProvider}
                handleSelectProvider={(provider) => {
                  setSelectedProvider(provider);
                  const updatedItem = {
                    ...selectedItem,
                    inputData: {
                      ...selectedItem?.inputData,
                      actionInputData: {
                        ...selectedItem?.inputData?.actionInputData,
                        triggerData: {
                          ...selectedItem?.inputData?.actionInputData?.triggerData,
                          providerTriggerData: {
                            ...selectedItem?.inputData?.actionInputData?.triggerData
                              ?.providerTriggerData,
                            provider,
                          },
                        },
                      },
                    },
                  };
                  dispatch(updateTempSelectedAiFlowItem(updatedItem));
                }}
              />
              <ConnectionSelect
                disabled={runsMode}
                selectedIntegration={selectedProvider}
                selectedConnectionInput={selectedConnection}
                onConnectionSelect={(connectionId: number) => {
                  setSelectedConnection(connectionId);
                  const updatedItem = {
                    ...selectedItem,
                    inputData: {
                      ...selectedItem?.inputData,
                      actionInputData: {
                        ...selectedItem?.inputData?.actionInputData,
                        triggerData: {
                          ...selectedItem?.inputData?.actionInputData?.triggerData,
                          providerTriggerData: {
                            ...selectedItem?.inputData?.actionInputData?.triggerData
                              ?.providerTriggerData,
                            connectionId,
                          },
                        },
                      },
                    },
                  };
                  dispatch(updateTempSelectedAiFlowItem(updatedItem));
                }}
              />
              {selectedProvider === 'Mailchimp' && (
                <>
                  <MailchimpListSelect
                    connectionId={selectedConnection ?? 0}
                    selectedListInput={mailchimpListId}
                    handleSelectMethod={(listId: string) => {
                      setMailchimpListId(listId);
                      const updatedItem = {
                        ...selectedItem,
                        inputData: {
                          ...selectedItem?.inputData,
                          actionInputData: {
                            ...selectedItem?.inputData?.actionInputData,
                            triggerData: {
                              ...selectedItem?.inputData?.actionInputData?.triggerData,
                              providerTriggerData: {
                                ...selectedItem?.inputData?.actionInputData?.triggerData
                                  ?.providerTriggerData,
                                mailChimpProviderTriggerData: {
                                  ...selectedItem?.inputData?.actionInputData?.triggerData
                                    ?.providerTriggerData?.mailChimpProviderTriggerData,
                                  listId,
                                },
                              },
                            },
                          },
                        },
                      };
                      dispatch(updateTempSelectedAiFlowItem(updatedItem));
                    }}
                  />
                  <MailchimpTriggerWebhookEventsSelect
                    webhookEventsInput={webhookEvents}
                    onWebhookEventsSelect={(we: any[]) => {
                      console.log('we', we);
                      const updatedItem = {
                        ...selectedItem,
                        inputData: {
                          ...selectedItem?.inputData,
                          actionInputData: {
                            ...selectedItem?.inputData?.actionInputData,
                            triggerData: {
                              ...selectedItem?.inputData?.actionInputData?.triggerData,
                              providerTriggerData: {
                                ...selectedItem?.inputData?.actionInputData?.triggerData
                                  ?.providerTriggerData,
                                webhookEvents: we.map((w) => w.name),
                              },
                            },
                          },
                        },
                      };
                      dispatch(updateTempSelectedAiFlowItem(updatedItem));
                    }}
                  />
                </>
              )}
            </>
          )}
        </>
      </Stack>
    </>
  );
}
