import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  FormControlLabel,
  IconButton,
  Link,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { AiFlowItemLogic, AiFlowLogic, AiFlowVersionLogic } from 'src/api';
import { ACTION_ICONS, OTHER_ICONS } from 'src/common/constants/icons.constants';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { BiggerStyledIcon, StyledIcon } from 'src/components/nav-section/mini/styles';
import {
  addAiFlowItem,
  removeAiFlowItem,
  resetSelectedAiFlow,
  runAiFlow,
  saveAiFlow,
  saveAiFlowWithoutItems,
  selectAiFlow,
  setFullscreen,
  updateAiFlow,
  updateAiFlowWithoutItems,
} from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';
import * as Yup from 'yup';
import { AiFlowType } from './enums/AiFlowType.enum';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'src/components/snackbar';
import { PATH_MARKETING } from 'src/routes/paths';
import _ from 'lodash';
import { AiFlowItemType } from './enums/AiFlowItemType.enum';
import ConfirmDialog from 'src/components/confirm-dialog';
import { useBoolean } from 'src/hooks/use-boolean';
import AiFlowNewVersionDialog from './dialogs/versions/AiFlowNewVersionDialog';
import React, { useEffect, useRef, useState } from 'react';
import FolderSelect from './folders/FolderSelect';
import { ScheduledOccurenceType } from 'src/@types/aiflow';
import { addMinutes, format, startOfDay } from 'date-fns';
import AiFlowScheduleSettings from './dialogs/Schedule/AiFlowScheduleSettings';
import { ModelType } from 'src/common/constants/llm-models.constants';
import { canRunFlowValidation } from 'src/utils/aiFlows-validator-utils';
import { useQuery } from 'src/utils/query';
import AiFlowVersionsDialog from './dialogs/versions/AiFlowVersionsDialog';

interface Params {}

export default function AiFlowHeader() {
  // TODO: Use form instead of useState
  const dispatch = useDispatch();
  const query = useQuery();
  const flowType = query.get('type');
  const flowTypeRef = useRef(flowType);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const lastSavedAiFlow = useSelector((state) => state.aiFlows.lastSavedSelectedAiFlow);
  const loading = useSelector((state) => state.aiFlows.loading);
  const selectedAiFlowVersion = useSelector((state) => state.aiFlows.selectedAiFlowVersion);
  const isAiFlowDirty = useSelector((state) => state.aiFlows.isDirty);
  const savingAiFlow = useSelector((state) => state.aiFlows.savingAiFlow);
  const { enqueueSnackbar } = useSnackbar();
  const openNewFlowVersionDialog = useBoolean(false);
  const openScheduleSettings = useBoolean(false);
  const [currentVersion, setCurrentVersion] = React.useState<AiFlowVersionLogic>();
  const openConfirmDialog = useBoolean(false);
  const fullScreen = useSelector((state) => state.aiFlows.fullscreen);
  const selectedFolder = useSelector((state) => state.folders.selectedFolder);
  const connections = useSelector((state) => state.connection.connections);
  const navigate = useNavigate();
  const onBackStep = () => {
    navigate(-1);
  };
  const openAiFlowVersionDialog = useBoolean(false);
  const [scheduledActive, setScheduledActive] = useState<boolean | null>();
  const aiFlowTypes = ['OneTime', 'Scheduled'];
  const AiFlowSchema: any = Yup.object().shape({
    // aiFlowName: Yup.string().required('Required'),
    // // aiFlowDescription: Yup.string().required('Required'),
    // aiFlowType: Yup.string()
    //   .oneOf(aiFlowTypes, 'Please select a valid option')
    //   .required('Please select an option'),
  });
  // if (selectedAiFlow) {
  // }

  const defaultValues = {
    aiFlowName: selectedAiFlow?.name ?? '',
    // aiFlowDescription: selectedAiFlow?.description ?? '',
    aiFlowType: 'OneTime', // AiFlowType[selectedAiFlow?.aiFlowType as number] ?? '',
  };

  useEffect(() => {
    if (selectedAiFlowVersion) {
      setCurrentVersion(selectedAiFlowVersion);
    }
  }, [selectedAiFlowVersion]);

  const checkInvalidAiFlowItems = () => {
    const items = selectedAiFlow?.items;
    const result = false;
    // if (items) {
    //   items.forEach((item) => {
    //     if (item.type === AiFlowItemType.Action) {
    //       // if (!item.inputData?.actionInputData?.promptInput) {
    //       //   result = true;
    //       // }
    //     } else if (item.type === AiFlowItemType.Validator) {
    //       // if (
    //       //   !item.inputData?.validatorInputData?.condition &&
    //       //   !item.inputData?.validatorInputData?.manualValidation
    //       // ) {
    //       //   result = true;
    //       // }
    //     }
    //   });
    // }
    return result;
  };

  const [subscriptionTier, setSubscriptionTier] = useState<any>();
  const subscriptionTiers = useSelector((state) => state.subscription.tiers);
  const billingInfo = useSelector((state) => state.subscription.basicBillingInfo);
  useEffect(() => {
    const st = subscriptionTiers.find((tier) => tier.name === billingInfo?.productName);
    if (st && st.monthlyCreditUnits) {
      setSubscriptionTier(st);
    } else {
      setSubscriptionTier({ monthlyCreditUnits: 150 });
    }
  }, [billingInfo, subscriptionTiers]);

  const creditStatus = useSelector((state) => state.creditStatus.creditStatus);

  const hasEnoughCreditsToRun = () => {
    let totalUnits;
    if (subscriptionTier && subscriptionTier.monthlyCreditUnits) {
      totalUnits = subscriptionTier.monthlyCreditUnits as number;
    } else {
      totalUnits = 150;
    }
    return (creditStatus?.monthlyUnitsUsed as number) < totalUnits;
  };

  const getOrdinalSuffix = (day: number) => {
    const ones = day % 10;
    const tens = Math.floor(day / 10);

    if (tens === 1) {
      return 'th';
    } else if (ones === 1) {
      return 'st';
    } else if (ones === 2) {
      return 'nd';
    } else if (ones === 3) {
      return 'rd';
    } else {
      return 'th';
    }
  };

  const formatScheduleOccurence = () => {
    const occurenceType =
      ScheduledOccurenceType[(selectedAiFlow?.aiFlowScheduleInfo?.type as number) ?? 0];
    let formattedOccurence = '';
    if (occurenceType === 'Weekly') {
      const selectedDays = selectedAiFlow?.aiFlowScheduleInfo?.days;
      if (selectedDays) {
        const days = selectedDays.split(',');
        formattedOccurence = days.join(', ');
      }
    } else if (occurenceType === 'Monthly') {
      const dayOfTheMonth = selectedAiFlow?.aiFlowScheduleInfo?.monthlyDate;
      const ordinalSuffix = getOrdinalSuffix(dayOfTheMonth as number);

      formattedOccurence = `${dayOfTheMonth}${ordinalSuffix},`;
    }
    const convertUTCMinutesToLocalFormattedTime = (utcMinutes: number) => {
      // Convert the UTC minutes to milliseconds
      const utcTimeInMs = utcMinutes * 60000;

      // Create a new date for today's date at UTC midnight in milliseconds
      const today = new Date();
      const todayAtUTCMidnight = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());

      // Add the UTC minutes to today's UTC midnight
      const targetUTCTime = new Date(todayAtUTCMidnight + utcTimeInMs);

      // Format the UTC time in local time zone
      const formattedLocalTime = format(targetUTCTime, 'hh:mm a');

      return formattedLocalTime;
    };
    const formattedTime = convertUTCMinutesToLocalFormattedTime(
      (selectedAiFlow?.aiFlowScheduleInfo?.timeInMinutes as number) ?? 0
    );
    return `${occurenceType},${formattedOccurence} ${formattedTime}`;
  };

  const notAllowedToSave = () => {
    const invalidAiFlowItems = checkInvalidAiFlowItems();
    return (
      invalidAiFlowItems ||
      !isValid ||
      !selectedAiFlow?.draft ||
      //   (!isDirty && !isAiFlowDirty && _.isEqual(selectedAiFlow, lastSavedAiFlow)) ||
      savingAiFlow
    );
  };

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowSchema),
    mode: 'all',
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    trigger,
  } = methods;

  const onSubmit = async (data: any) => {
    if (!data.aiFlowName) {
      enqueueSnackbar('Workflow name is required.', {
        variant: 'error',
      });
      return;
    }

    let existingAiFlow = { ...selectedAiFlow };
    if (existingAiFlow) {
      existingAiFlow.name = data.aiFlowName;
      //    existingAiFlow.description = data.aiFlowDescription;
      existingAiFlow.aiFlowType = AiFlowType[data.aiFlowType as keyof typeof AiFlowType];
      existingAiFlow.items = selectedAiFlow?.items as AiFlowItemLogic[];
      existingAiFlow.folderIds = selectedFolder?.id ? [selectedFolder?.id as number] : [];
      if (!existingAiFlow.id) {
        const newExistingFlow = _.cloneDeep(existingAiFlow);
        newExistingFlow.items = newExistingFlow.items?.map((item) => {
          if (
            item.inputData?.actionInputData &&
            item.inputData?.actionInputData?.promptInput &&
            !item.inputData?.actionInputData?.configuration
          ) {
            item.inputData.actionInputData.configuration = {
              model: ModelType.gpt_4_o_mini,
              connectionId: connections.find(
                (connection) => connection.integrationName === 'OpenAI'
              )?.id as number,
            };
          }
          return item;
        });
        existingAiFlow = newExistingFlow;
      }
      // check if there isn't configured connections
      if (selectedAiFlow?.items && selectedAiFlow?.items.length > 0) {
        const canRunFlowValidationResult = canRunFlowValidation(
          existingAiFlow.items as AiFlowItemLogic[]
        );
        if (!canRunFlowValidationResult.validationSuccess) {
          enqueueSnackbar(canRunFlowValidationResult.error, {
            variant: 'error',
          });
          return;
        }
      }
      existingAiFlow.draft = false;
      dispatch(saveAiFlow(existingAiFlow as AiFlowLogic, enqueueSnackbar, navigate));
    }
  };

  useEffect(() => {
    if (!selectedAiFlow) {
      trigger();
    }
  }, [selectedAiFlow, trigger]);

  useEffect(() => {
    if (selectedAiFlow && selectedAiFlow.id) {
      methods.reset({
        aiFlowName: selectedAiFlow.name,
        //    aiFlowDescription: selectedAiFlow.description,
        aiFlowType: AiFlowType[selectedAiFlow.aiFlowType as number],
      });
    }
  }, [selectedAiFlow, methods]);

  useEffect(() => {
    if (selectedAiFlow && scheduledActive === undefined) {
      setScheduledActive(selectedAiFlow.active as boolean);
    }
  }, [selectedAiFlow, scheduledActive]);

  const isDirtyOrInvalid = () =>
    !isValid ||
    savingAiFlow ||
    !selectedAiFlow?.id ||
    selectedAiFlow?.items?.length === 0 ||
    isDirty ||
    isAiFlowDirty;

  const isInvalid = () => !isValid || savingAiFlow || selectedAiFlow?.draft || selectedAiFlow?.items?.length === 0 ||
  selectedAiFlow?.aiFlowType === AiFlowType.Trigger;

  useEffect(() => {
    if (flowTypeRef.current) {
      // const aiFlowType = AiFlowType[flowType as keyof typeof AiFlowType];
      // const updatedSelectedAiFlow = {
      //   ...selectedAiFlow,
      //   aiFlowType,
      // };
      // dispatch(updateAiFlow(updatedSelectedAiFlow as AiFlowLogic));
      methods.reset({
        aiFlowType: flowTypeRef.current,
      });
      flowTypeRef.current = null;
      // const updatedSelectedAiFlow = {
      //   ...selectedAiFlow,
      //   aiFlowType: flowType
      // };
      // dispatch(selectAiFlow({
      //   aiFlow: updatedSelectedAiFlow as any,
      // }));
    }
  }, [flowTypeRef, methods]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Stack direction="row" alignItems="center" spacing={4}>
            {/* {!fullScreen && (
              <Button
                size="small"
                color="inherit"
                sx={{ maxWidth: 100, justifyContent: 'flex-start' }}
                onClick={() => {
                  if (isDirtyOrInvalid()) {
                    openConfirmDialog.onTrue();
                    return;
                  }

                  navigate(PATH_MARKETING.marketing.aiFlows);
                }}
                startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
              >
                Back
              </Button>
            )} */}
            <RHFTextField
              name="aiFlowName"
              size="small"
              label="Workflow Name"
              sx={{ minWidth: 260 }}
              variant="standard"
              onChange={(event) => {
                const typedFlowName = event.target.value;
                const updatedSelectedAiFlow = {
                  ...selectedAiFlow,
                  name: typedFlowName,
                };
                methods.setValue('aiFlowName', typedFlowName);
                trigger();
                dispatch(updateAiFlowWithoutItems(updatedSelectedAiFlow as AiFlowLogic));

                //  dispatch(saveAiFlowWithoutItems(updatedSelectedAiFlow as AiFlowLogic));
              }}
              onBlur={() => {
                const typedFlowName = methods.getValues('aiFlowName');
                const updatedSelectedAiFlow = {
                  ...selectedAiFlow,
                  name: typedFlowName,
                };
                if (selectedAiFlow?.id) {
                  dispatch(saveAiFlowWithoutItems(updatedSelectedAiFlow as AiFlowLogic));
                }
              }}
            />
            <FolderSelect
              disabled={!!selectedAiFlow?.id}
              selectedFolderIdInput={selectedFolder?.id as number}
              maxWidth={200}
              onFolderSelect={(folderId) => {
                const updatedSelectedAiFlow = {
                  ...selectedAiFlow,
                  folderIds: [folderId as number],
                };
                dispatch(saveAiFlowWithoutItems(updatedSelectedAiFlow as AiFlowLogic));
              }}
            />
            <RHFSelect
              key="aiFlowType"
              name="aiFlowType"
              size="small"
              variant="standard"
              label="Workflow Type"
              onChange={(event) => {
                const aiFlowType = event.target.value;
                if (selectedAiFlow?.aiFlowType === AiFlowType.Trigger && aiFlowType !== 'Trigger') {
                  dispatch(removeAiFlowItem({ sequence: 1 }));
                } else if (aiFlowType === 'Trigger') {
                  if (
                    ((selectedAiFlow?.items?.length as number) > 0 &&
                      (selectedAiFlow?.items as any[])[0].header !== 'Trigger') ||
                    selectedAiFlow?.items?.length === 0 ||
                    !selectedAiFlow?.items
                  ) {
                    dispatch(
                      addAiFlowItem({
                        aiFlowItem: {
                          sequence: 1,
                          parentSequence: null,
                          aiFlowId: selectedAiFlow?.id as number,
                          name: 'trigger',
                          header: 'Trigger',
                          aiFlowVersionId: selectedAiFlowVersion?.id as number,
                        },
                        addAfter: 0,
                      })
                    );
                  }
                }
                if (selectedAiFlow?.id) {
                  const updatedSelectedAiFlow = {
                    ...selectedAiFlow,
                    aiFlowType: AiFlowType[aiFlowType as keyof typeof AiFlowType],
                  };

                  dispatch(saveAiFlowWithoutItems(updatedSelectedAiFlow as AiFlowLogic));
                } else {
                  const updatedSelectedAiFlow = {
                    ...selectedAiFlow,
                    aiFlowType: AiFlowType[aiFlowType as keyof typeof AiFlowType],
                  };
                  dispatch(selectAiFlow({ aiFlow: updatedSelectedAiFlow as AiFlowLogic }));
                  methods.setValue('aiFlowType', aiFlowType);
                }

                if (aiFlowType === 'Scheduled') {
                  openScheduleSettings.onTrue();
                }
              }}
              InputLabelProps={{ shrink: true }}
              sx={{ minWidth: { md: 180 } }}
            >
              <MenuItem value="OneTime">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <StyledIcon>{OTHER_ICONS.oneTime}</StyledIcon>
                  <span>Manual</span>
                </Stack>
              </MenuItem>
              <MenuItem value="Scheduled">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <StyledIcon>{OTHER_ICONS.recurringAiFlow}</StyledIcon>
                  <span>Scheduled</span>
                </Stack>
              </MenuItem>
              <MenuItem disabled={!selectedAiFlow?.id} value="Trigger">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="grommet-icons:trigger" />
                  <span>Trigger</span>
                </Stack>
              </MenuItem>
            </RHFSelect>

            {(selectedAiFlow?.aiFlowType === AiFlowType.Scheduled ||
              selectedAiFlow?.aiFlowType === AiFlowType.Trigger) && (
              <>
                {selectedAiFlow?.aiFlowType === AiFlowType.Scheduled && (
                  <Tooltip title={formatScheduleOccurence()}>
                    <TextField
                      autoFocus
                      margin="dense"
                      disabled
                      id="name"
                      value={formatScheduleOccurence()}
                      label="Schedule Occurrence"
                      type="email"
                      fullWidth
                      variant="standard"
                    />
                  </Tooltip>
                )}
                <FormControlLabel
                  sx={{ ml: 2, mt: 2 }}
                  control={
                    <Switch
                      disabled = {selectedAiFlow?.draft}
                      value={scheduledActive}
                      checked={scheduledActive as boolean}
                      onChange={(event, checked) => {
                        setScheduledActive(checked);
                        const updatedSelectedAiFlow = {
                          ...selectedAiFlow,
                          active: checked,
                        };
                        dispatch(saveAiFlowWithoutItems(updatedSelectedAiFlow as AiFlowLogic));
                      }}
                    />
                  }
                  label="Active"
                />
              </>
            )}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/* <RHFTextField
              margin="dense"
              size="small"
              name="aiFlowDescription"
              label="Short Description*"
              multiline
              minRows={1}
              maxRows={1}
              sx={{ minWidth: 350 }}
              variant="standard"
            /> */}
            {selectedAiFlow && (
              <>
                <Stack direction="row" alignItems="center" spacing={2}>
                  {/* <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    Version: {currentVersion?.name}({(currentVersion?.version ?? 1).toFixed(1)})
                  </Typography> */}
                  {selectedAiFlow?.aiFlowType === AiFlowType.Scheduled && (
                    <Tooltip title="Schedule Settings">
                      <Button
                        color="inherit"
                        variant="outlined"
                        sx={{ flexShrink: 0 }}
                        startIcon={<Iconify icon="grommet-icons:schedule-new" />}
                        onClick={() => {
                          openScheduleSettings.onTrue();
                          //     dispatch(resetSelectedAiFlow(selectedAiFlow?.id as number));
                        }}
                      >
                        Schedule Settings
                      </Button>
                    </Tooltip>
                  )}
                  {!!selectedAiFlow?.id && !selectedAiFlow?.draft && (
                    <Tooltip title="Versions">
                      <Button
                        color="inherit"
                        variant="outlined"
                        sx={{ flexShrink: 0 }}
                        startIcon={<Iconify icon="system-uicons:versions" />}
                        // "fluent-mdl2:version-control-push" />}
                        onClick={() => {
                       //   openNewFlowVersionDialog.onTrue();
                          openAiFlowVersionDialog.onTrue();
                          //     dispatch(resetSelectedAiFlow(selectedAiFlow?.id as number));
                        }}
                      >
                        Versions
                      </Button>
                    </Tooltip>
                  )}
                </Stack>
              </>
            )}

            {/* <Tooltip title="Reset to the last saved state">
              <Button
                color="inherit"
                variant="outlined"
                sx={{ flexShrink: 0 }}
                startIcon={<Iconify icon="carbon:reset" />}
                onClick={() => {
                  dispatch(resetSelectedAiFlow(selectedAiFlow?.id as number));
                }}
              >
                Reset
              </Button>
            </Tooltip> */}
            {selectedAiFlow?.draft && (
              <LoadingButton
                color="inherit"
                loading={savingAiFlow}
                disabled={notAllowedToSave()}
                variant="outlined"
                type="submit"
                sx={{ flexShrink: 0 }}
                startIcon={<Iconify icon="material-symbols:save-outline" />}
              >
                Save
              </LoadingButton>
            )}
            <LoadingButton
              loading={loading}
              disabled={isInvalid()}
              variant="outlined"
              sx={{ flexShrink: 0 }}
              startIcon={<Iconify icon="ph:play-bold" />}
              onClick={() => {
                const canRunFlowValidationResult = canRunFlowValidation(
                  selectedAiFlow?.items as AiFlowItemLogic[]
                );
                if (!canRunFlowValidationResult.validationSuccess) {
                  enqueueSnackbar(canRunFlowValidationResult.error, {
                    variant: 'error',
                  });
                  return;
                }
                // if (!hasEnoughCreditsToRun()) {
                //   enqueueSnackbar(<span>
                //     You have reached the monthly limit of tasks.{' '}
                //     <Link href="/upgrade" color="inherit" underline="always">
                //       Click here to upgrade
                //     </Link>
                //   </span>, {
                //     variant: 'error',
                //   });
                //   return;
                // }
                dispatch(
                  runAiFlow(
                    selectedAiFlow as AiFlowLogic,
                    selectedAiFlowVersion?.id as number,
                    !selectedAiFlow?.id
                      ? (selectedAiFlow?.items as AiFlowItemLogic[])
                      : (null as any),
                    enqueueSnackbar
                  )
                );
              }}
            >
              Run
            </LoadingButton>
            {/* {!fullScreen && (
              <IconButton onClick={() => dispatch(setFullscreen(true))}>
                <Iconify width={30} height={30} icon="material-symbols:fullscreen" />
              </IconButton>
            )} */}
          </Stack>
        </Stack>
        <ConfirmDialog
          open={openConfirmDialog.value}
          onClose={openConfirmDialog.onFalse}
          title="Confirm"
          content="AiFlow wasn't saved. Are you sure want to go back?"
          action={
            <Button
              variant="outlined"
              color="error"
              onClick={() => navigate(PATH_MARKETING.pages.aiFlows)}
            >
              Go Back
            </Button>
          }
        />
        <AiFlowNewVersionDialog
          open={openNewFlowVersionDialog.value}
          onClose={openNewFlowVersionDialog.onFalse}
        />
        <AiFlowScheduleSettings
          open={openScheduleSettings.value}
          onClose={openScheduleSettings.onFalse}
        />
        <AiFlowVersionsDialog
          open={openAiFlowVersionDialog.value}
          onClose={() => {
            openAiFlowVersionDialog.onFalse();
          }}
          aiFlow={selectedAiFlow as AiFlowLogic}
        />
      </FormProvider>
    </>
  );
}
