import { Divider, Link, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { updateTempSelectedAiFlowItem, updateSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';
import { ActionInfo, InputDataLogic, AiFlowItemLogic } from 'src/api';
import _ from 'lodash';
import PromptEditor from 'src/components/prompt-editor/PromptEditor';
import AddUpdatePromptDialog from 'src/pages/prompts/AddUpdatePromptDialog';
import { useBoolean } from 'src/hooks/use-boolean';
import PreviewLoadPromptDialog from 'src/pages/prompts/PreviewLoadPromptDialog';

interface Params {
  onPromptInputChange: (promptInput: string) => void;
}

export default function AiFlowGenerateActionSetup({ onPromptInputChange }: Params) {
  const fullscreen = useSelector((state) => state.aiFlows.fullscreen);
  const [promptInput, setPromptInput] = useState<string>('');

  const [selectedAction, setSelectedAction] = useState<ActionInfo>();
  const [lastSelectedItem, setLastSelectedItem] = useState<AiFlowItemLogic>();
  const actionGroups = useSelector((state) => state.aiFlows.actionGroups);
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const [selectedItemLabel, setSelectedItemLabel] = useState<string>('');
  const dispatch = useDispatch();
  const openAddUpdatePromptDialog = useBoolean(false);
  const previewLoadPromptDialog = useBoolean(false);
  // TODO: Add this method to common utils!!
  const updateSelectedItemUseLastActionOutput = (value: boolean) => {
    const updatedItem = {
      ...selectedItem,
      inputData: {
        ...selectedItem?.inputData,
        actionInputData: {
          ...selectedItem?.inputData?.actionInputData,
          usePriorActionOutput: value,
        },
      },
    };
    dispatch(updateTempSelectedAiFlowItem(updatedItem));
  };
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.header !== lastSelectedItem?.header) {
        setLastSelectedItem(_.cloneDeep(selectedItem));
        const selectedItemHeader = selectedItem.header;

        const tmpActionGroup = actionGroups.find(
          (group) =>
            group.actions &&
            group.actions?.find(
              (groupAction) => groupAction && groupAction.header === selectedItemHeader
            ) !== undefined
        );
        const tmpAction = tmpActionGroup?.actions?.find((a) => a.header === selectedItemHeader);
        setSelectedAction(tmpAction);
        setPromptInput(tmpAction?.prompt as string);
        setSelectedItemLabel(`${tmpActionGroup?.label} ${tmpAction?.actionLabel}`);
      }
      if (selectedItem.inputData) {
        const inputData = selectedItem.inputData as InputDataLogic;
        setPromptInput(
          inputData?.actionInputData?.promptInput ?? (selectedAction?.prompt as string)
        );
      }
    }
  }, [selectedItem, actionGroups, lastSelectedItem, selectedAction]);

  return (
    <Stack spacing={2}>
      <Paper
        key=""
        variant="outlined"
        sx={{
          py: 1.5,
          mx: 1,
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          flexGrow: 1,
          height: '100%',
        }}
      >
        <Stack direction="row" justifyContent="space-between" sx={{ px: 1 }}>
          <div> </div>
          <Typography variant="subtitle2" sx={{ textAlign: 'center', paddingBottom: 1, ml: 9 }}>
            User Prompt
          </Typography>
          <Stack direction="row" spacing={2} sx={{ mr: 1 }}>
            <Link
              onClick={previewLoadPromptDialog.onTrue}
              sx={{ color: runsMode ? 'grey' : null }}
              href="#"
              underline="hover"
            >
              <Typography variant="subtitle2">Load</Typography>
            </Link>
            <Link
              onClick={openAddUpdatePromptDialog.onTrue}
              sx={{ color: runsMode ? 'grey' : null }}
              href="#"
              underline="hover"
            >
              <Typography variant="subtitle2">Save</Typography>
            </Link>
          </Stack>
        </Stack>
        <Divider sx={{ mb: 1 }} />
        <PromptEditor
          height={180}
          promptInput={promptInput}
          readonly={runsMode}
          setPromptInput={(prompt: string) => {
            onPromptInputChange(prompt);
            setPromptInput(prompt);
            dispatch(
              updateTempSelectedAiFlowItem({
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    promptInput: prompt,
                  },
                },
              })
            );
          }}
        />
      </Paper>
      <AddUpdatePromptDialog
        open={openAddUpdatePromptDialog.value}
        onClose={openAddUpdatePromptDialog.onFalse}
      />
      <PreviewLoadPromptDialog
        open={previewLoadPromptDialog.value}
        onClose={previewLoadPromptDialog.onFalse}
        onLoadPrompt={(prompt: string) => {
          setPromptInput(prompt);
          onPromptInputChange(prompt);
          dispatch(
            updateSelectedAiFlowItem({
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  promptInput: prompt,
                },
              },
            })
          );
        }}
      />
    </Stack>
  );
}
