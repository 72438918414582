import { Button, Stack } from '@mui/material';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PreviousActionsSelect from 'src/components/previous-actions/PreviousActionsSelect';
import { useDispatch, useSelector } from 'src/redux/store';
import FormProvider from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import { updateSelectedAiFlowItem, updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useEffect, useState } from 'react';
import { TinyStyledIcon } from 'src/components/nav-section/mini/styles';
import { ACTION_ICONS } from 'src/common/constants/icons.constants';
import ProviderSelect from 'src/components/provider-select/ProviderSelect';
import ConnectionSelect from 'src/components/connection/ConnectionSelect';
import TranscribeConfigDialog from 'src/components/configurations/TranscribeConfigDialog';
import { useBoolean } from 'src/hooks/use-boolean';

export default function AiFlowSpeechToTextActionSetup() {
  const dispatch = useDispatch();
  const openTranscribeConfiguration = useBoolean(false);
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const defaultValues = {
    //   selectedSource: '',
  };
  const [audioSource, setAudioSource] = useState<string>('');
  const [provider, setProvider] = useState<string>('');
  const [connection, setConnection] = useState<number>(0);

  const AiFlowReconstructSchema: any = Yup.object().shape({
    //  selectedSource: Yup.string().required('Required'),
  });

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowReconstructSchema),
    mode: 'all',
    defaultValues,
  });

  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  useEffect(() => {
    if (selectedItem) {
      setAudioSource(
        selectedItem.inputData?.actionInputData?.configuration?.speechToTextAudioConfigurations
          ?.audioSource ?? ''
      );
      setProvider(selectedItem.inputData?.actionInputData?.configuration?.modelProvider ?? '');
      setConnection(selectedItem.inputData?.actionInputData?.configuration?.connectionId ?? 0);
    }
  }, [selectedItem, methods]);

  return (
    <FormProvider methods={methods}>
      <Stack spacing={3}>
        <ProviderSelect
          px={1}
          specificGroups={['large language models']}
          disabled={runsMode}
          selectedProviderInput={provider || ''}
          handleSelectProvider={(sp: any) => {
            setProvider(sp);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  configuration: {
                    ...selectedItem?.inputData?.actionInputData?.configuration,
                    modelProvider: sp,
                  },
                },
              },
            };
            dispatch(updateTempSelectedAiFlowItem(updatedItem));
          }}
        />
        <ConnectionSelect
          px={1}
          disabled={runsMode}
          selectedIntegration={provider}
          selectedConnectionInput={connection}
          onConnectionSelect={(connectionId: number) => {
            setConnection(connectionId);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  configuration: {
                    ...selectedItem?.inputData?.actionInputData?.configuration,
                    connectionId,
                  },
                },
              },
            };
            dispatch(updateTempSelectedAiFlowItem(updatedItem));
          }}
        />
        <PreviousActionsSelect
          flexGrow={1}
          disabled={runsMode}
          label="Audio Source"
          selectedSourceInput={audioSource}
          onPreviousActionsSelect={(source: string) => {
            setAudioSource(source);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  configuration: {
                    ...selectedItem?.inputData?.actionInputData?.configuration,
                    speechToTextAudioConfigurations: {
                      ...selectedItem?.inputData?.actionInputData?.configuration
                        ?.speechToTextAudioConfigurations,
                      audioSource: source,
                    },
                  },
                },
              },
            };
            dispatch(updateTempSelectedAiFlowItem(updatedItem));
          }}
        />
        <Button
          fullWidth
          variant="outlined"
          startIcon={<TinyStyledIcon>{ACTION_ICONS.configurations}</TinyStyledIcon>}
          size="large"
          color="inherit"
          sx={{
            maxWidth: { md: 180 },
            maxHeight: { md: 40 },
          }}
          onClick={() => {
            openTranscribeConfiguration.onTrue();
          }}
        >
          Configurations
        </Button>
      </Stack>
      <TranscribeConfigDialog
        open={openTranscribeConfiguration.value}
        onClose={(lang: any) => {
          if (lang) {
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  configuration: {
                    ...selectedItem?.inputData?.actionInputData?.configuration,
                    speechToTextAudioConfigurations: {
                      ...selectedItem?.inputData?.actionInputData?.configuration
                        ?.speechToTextAudioConfigurations,
                      language: lang,
                    },
                  },
                },
              },
            };
            dispatch(updateTempSelectedAiFlowItem(updatedItem));
          }
          openTranscribeConfiguration.onFalse();
        }}
      />
    </FormProvider>
  );
}
