import { yupResolver } from '@hookform/resolvers/yup';
import { MenuItem, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import FormProvider, { RHSelect } from 'src/components/hook-form';
import PreviousActionsSelect from 'src/components/previous-actions/PreviousActionsSelect';
import { setActionValidated, updateSelectedAiFlowItem, updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';
import { useForm } from 'react-hook-form';
import EmbeddingModelSelect from 'src/components/model/EmbeddingModelSelect';
import ConnectionSelect from 'src/components/connection/ConnectionSelect';

export default function AiFlowEmbeddingActionSetup() {
  const dispatch = useDispatch();
  const [selectedSource, setSelectedSource] = useState<string>();
  const [selectedModel, setSelectedModel] = useState<string>();
  const [selectedConnection, setSelectedConnection] = useState<number>();
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const defaultValues = {
    selectedSource: '',
  };

  const AiFlowEmbeddingSchema: any = Yup.object().shape({
    selectedSource: Yup.string().required('Required'),
  });

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowEmbeddingSchema),
    mode: 'all',
    defaultValues,
  });

  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.inputData?.actionInputData?.llmInputData?.embeddingInputData) {
        setSelectedSource(
          selectedItem.inputData?.actionInputData?.llmInputData?.embeddingInputData?.source as string
        );
        methods.setValue(
          'selectedSource',
          selectedItem.inputData?.actionInputData?.llmInputData?.embeddingInputData?.source as string
        );
        methods.trigger();
        setSelectedModel(
          selectedItem.inputData?.actionInputData?.llmInputData?.embeddingInputData?.model as string
        );
        setSelectedConnection(
          selectedItem.inputData?.actionInputData?.llmInputData?.embeddingInputData
            ?.connectionId as number
        );
      }
    }
  }, [selectedItem, methods]);

  const {
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
    trigger,
  } = methods;

  useEffect(() => {
    dispatch(setActionValidated(isValid));
  }, [isValid, dispatch]);

  return (
    <>
      <FormProvider methods={methods}>
        <Stack spacing={3} sx={{ mt: 2, mx: 1 }}>
          <PreviousActionsSelect
            label="Embedding Source"
            selectedSourceInput={selectedSource}
            onPreviousActionsSelect={(source: string) => {
              setSelectedSource(source);
              setValue('selectedSource', source);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    llmInputData: {
                      ...selectedItem?.inputData?.actionInputData?.llmInputData,
                      embeddingInputData: {
                        ...selectedItem?.inputData?.actionInputData?.llmInputData
                          ?.embeddingInputData,
                        source,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
          />
          <EmbeddingModelSelect 
          onModeSelect={(model) => {
            setSelectedModel(model);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  llmInputData: {
                    ...selectedItem?.inputData?.actionInputData?.llmInputData,
                    embeddingInputData: {
                      ...selectedItem?.inputData?.actionInputData?.llmInputData
                        ?.embeddingInputData,
                      model,
                    },
                  },
                },
              },
            };
            dispatch(updateTempSelectedAiFlowItem(updatedItem));
          }}
          selectedModelInput={selectedModel}
          />
           <ConnectionSelect
            disabled={runsMode}
            px={1}
            selectedIntegration="OpenAI"
            selectedConnectionInput={selectedConnection}
            onConnectionSelect={(connectionId: number) => {
              setSelectedConnection(connectionId);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    llmInputData: {
                      ...selectedItem?.inputData?.actionInputData?.llmInputData,
                      embeddingInputData: {
                        ...selectedItem?.inputData?.actionInputData?.llmInputData
                          ?.embeddingInputData,
                        connectionId,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
          />
        </Stack>
      </FormProvider>
    </>
  );
}
