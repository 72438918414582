import { useCallback, useEffect, useRef, useState } from 'react';
// import MonacoEditor from 'react-monaco-editor';
import Editor from '@monaco-editor/react';
import { useSelector } from 'src/redux/store';
import _ from 'lodash';
import { usePubSub } from '../pub-sub/PubSubProvider';

interface Params {
  promptInput: string;
  id?: string;
  readonly?: boolean;
  minimap?: boolean;
  lineNumbers?: boolean;
  language?: string;
  htmlCodeEditor?: boolean;
  width?: number;
  setPromptInput?: (value: string) => void;
  height?: number;
  reference?: boolean;
}

export default function PromptEditor({
  promptInput,
  id,
  htmlCodeEditor,
  lineNumbers,
  setPromptInput,
  minimap,
  language,
  width,
  readonly,
  height,
  reference,
}: Params) {
  const monacoEditorRef = useRef<any>(null);
  const editorRef = useRef<any>(null);
  const [completionProviderDisposable, setCompletionProviderDisposable] = useState<any>(null);
  const [previousActions, setPreviousActions] = useState<string[]>([]);
  const previousActionsRef = useRef(previousActions);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const [referenceValue, setReferenceValue] = useState<string>(promptInput ?? '{item}');

  const pubSub = usePubSub();

  const focusEditorLastPosition = useCallback(() => {
    if (editorRef.current && !readonly) {
      setTimeout(() => {
        editorRef.current.focus();
        const model = editorRef.current.getModel(); // Get the model (document) of the editor
        const lastLine = model.getLineCount(); // Get the count of lines in the model
        const lastColumn = model.getLineLength(lastLine); // Get the length of the last line

        // Set the position of the cursor to the end of the editor content
        editorRef.current.setPosition({ lineNumber: lastLine, column: lastColumn + 1 });
      }, 200);
    }
  }, [readonly]);

  useEffect(() => {
    const unsubscribe = pubSub.subscribe(
      id ? `editor_focus${id}` : 'editor_focus',
      (data: string) => {
        if (editorRef.current) {
          const editor = editorRef.current;
          const position = editor.getPosition(); // Get the current cursor position
          if (!position) {
            return;
          }
          const range = new monacoEditorRef.current.Range(
            position.lineNumber,
            position.column,
            position.lineNumber,
            position.column
          );
//          const id = { major: 1, minor: 1 }; // Identifier for this operation
          const text = data; // The text you want to insert

          editor.executeEdits('', [{ range, text, forceMoveMarkers: true }]);

          // Optionally, if you also want to set this data to some state or handle it differently
          if (setPromptInput) {
            // This could be a function to update the state with the new editor content
            setPromptInput(editor.getValue());
            setTimeout(() => {
              editorRef.current.focus();
              const tmpPosition = editorRef.current.getPosition(); // Get the current cursor position

              // Set the position of the cursor to the end of the editor content
              editorRef.current.setPosition({
                lineNumber: tmpPosition.lineNumber,
                column: tmpPosition.column + 1,
              });
            }, 200);
          }
        }
      }
    );

    // Cleanup subscription on component unmount
    return () => unsubscribe();
  }, [pubSub, setPromptInput, id]);

  // useEffect(() => {
  //   const unsubscribe = pubSub.subscribe('editor_focus', (data: string) => {
  //     if (setPromptInput) {
  //       setPromptInput(data);
  //     }
  //     focusEditorLastPosition();
  //   });

  //   // Cleanup subscription on component unmount
  //   return () => unsubscribe();
  // }, [pubSub, focusEditorLastPosition, setPromptInput]);

  useEffect(() => {
    previousActionsRef.current = previousActions;
  }, [previousActions]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (monacoEditorRef.current && !readonly) {
  //       monacoEditorRef.current.focus();
  //     }
  //   }, 1000);

  //   return () => clearInterval(interval); // Cleanup on component unmount
  // }, [readonly]);

  const registerLanguage = useCallback(
    (monacoEditor: any) => {
      monacoEditor.languages.register({ id: 'kuvertoMarkup' });
      let customVariables = previousActions.map((item) => `\\{${item}\\}`).join('|');
      if (selectedItem?.inputData?.actionInputData?.configuration?.loopSettings) {
        customVariables += `|\\{${selectedItem?.inputData?.actionInputData?.configuration?.loopSettings?.referenceKeyword}\\}`;
      }
      // The final regex will be something like /\{(test_122|loma|baga)\}/
      const pattern = new RegExp(`(${customVariables})`, 'g');
      monacoEditor.languages.setMonarchTokensProvider('kuvertoMarkup', {
        tokenizer: {
          root: [[pattern, 'custom-variable']],
        },
      });
      if (!htmlCodeEditor) {
        monacoEditor.editor.defineTheme('kuvertoTheme', {
          base: 'vs',
          inherit: false,
          rules: [{ token: 'custom-variable', foreground: 'FFA500', fontStyle: 'bold' }],
          colors: { 'editor.foreground': '#000000' },
        });

        monacoEditor.editor.setTheme('kuvertoTheme');
      }
    },
    [
      previousActions,
      htmlCodeEditor,
      selectedItem?.inputData?.actionInputData?.configuration?.loopSettings,
    ]
  );

  useEffect(() => {
    if (monacoEditorRef && monacoEditorRef.current) {
      registerLanguage(monacoEditorRef.current);
    }
    return () => {
      // Clean up the completion provider when the component unmounts or before it's re-created
      completionProviderDisposable?.dispose();
    };
  }, [completionProviderDisposable, monacoEditorRef, registerLanguage]);

  const registerCompletionProvider = useCallback(() => {
    const monaco = monacoEditorRef.current as any; // Use ref here
    if (!readonly && monaco) {
      const disposable = monaco.languages.registerCompletionItemProvider('kuvertoMarkup', {
        triggerCharacters: ['{'],
        provideCompletionItems: (model: any, position: any) => {
          const word = model.getWordUntilPosition(position);
          const range = {
            startLineNumber: position.lineNumber,
            startColumn: word.startColumn,
            endLineNumber: position.lineNumber,
            endColumn: word.endColumn,
          };
          const currentPreviousActions = previousActionsRef.current; // Use ref here
          const suggestions = currentPreviousActions.map((item) => ({
            label: `${item}`,
            kind: monaco.languages.CompletionItemKind.Text,
            insertText: `{${item}}`,
            range,
          }));
          if (
            selectedItem?.inputData?.actionInputData?.configuration?.loopSettings &&
            selectedItem?.inputData?.actionInputData?.configuration?.loopSettings?.referenceKeyword
          ) {
            suggestions.push({
              label: `${selectedItem?.inputData?.actionInputData?.configuration?.loopSettings?.referenceKeyword}`,
              kind: monaco.languages.CompletionItemKind.Text,
              insertText: `{${selectedItem?.inputData?.actionInputData?.configuration?.loopSettings?.referenceKeyword}}`,
              range,
            });
          }
          return {
            suggestions,
          };
        },
      });
      // Save the disposable so it can be cleaned up later
      setCompletionProviderDisposable(disposable);
    }
  }, [
    readonly,
    monacoEditorRef,
    previousActionsRef,
    selectedItem?.inputData?.actionInputData?.configuration?.loopSettings,
  ]);

  useEffect(() => {
    const tmpPreviousActions = selectedAiFlow?.items?.filter(
      (item: any) => item && (item.sequence as number) < (selectedItem?.sequence as number)
    );
    const previousActionsStrArr = tmpPreviousActions?.map((item: any) => item.name as string) ?? [];
    if (!_.isEqual(previousActionsStrArr, previousActions)) {
      setPreviousActions(previousActionsStrArr);
      registerCompletionProvider();
    }
  }, [selectedAiFlow, selectedItem, previousActions, registerCompletionProvider]);

  const editorDidMount = (editor: any, monacoEditor: any) => {
    registerLanguage(monacoEditor);
    editorRef.current = editor; // Set the ref here
    monacoEditorRef.current = monacoEditor; // Set the ref here
    registerCompletionProvider();
    editor.getAction('editor.action.formatDocument').run();
  };

  const getEdibleRange = () => {
    const editor = editorRef.current;
    const model = editor.getModel();

    const text = model.getValue();
    const regex = /\{\{\{(.+?)\}\}\}/g; // Use the 'g' flag for global matching
    const zzz = [...text.matchAll(regex)];
    const monacoRanges = zzz.map((match) => {
      const start = model.getPositionAt(match.index);
      const end = model.getPositionAt(match.index + match[0].length - 1);
      return new monacoEditorRef.current.Range(
        start.lineNumber,
        start.column,
        end.lineNumber,
        end.column
      );
    });
    return monacoRanges?.length ? monacoRanges[0] : null;
  };

  const isStringInTripleCurlyBraces = (str: string) => {
    // Regex pattern to check if a string is enclosed within { }
    const pattern = /^\{(.+?)\}$/;
    return pattern.test(str);
  };

  const onChange = (newValue: any, e: any) => {
    // if (reference && setPromptInput) {
    //   // i want implementation that will check if the value is within the range and only if it is then apply the change
    //   if (isStringInTripleCurlyBraces(newValue)) {
    //     setReferenceValue(newValue);
    //     setPromptInput(newValue);
    //     return;
    //   }

    //   const editor = editorRef.current;
    //   const model = editor.getModel();
    //
    //   let isChangeWithinEditableRange = true;
    //   const editableRange = getEdibleRange();
    //   const changes = e.changes;
    //   changes.forEach((change: any) => {
    //     if (!editableRange.containsRange(change.range)) {
    //       isChangeWithinEditableRange = false;
    //     }
    //   });

    //   if (isChangeWithinEditableRange) {
    //     setReferenceValue(newValue);
    //     setPromptInput(newValue);
    //   } else {
    //     // Revert to the previous value if the change was outside the editable range
    //     // const newVal = `{{{item}}}`
    //     // setReferenceValue(_.cloneDeep(newVal));
    //     editor.setValue(referenceValue);
    //   }
    // }
    if (setPromptInput) {
      setPromptInput(newValue);
    }
  };

  const options = {
    readOnly: !!readonly,
    selectOnLineNumbers: true,
    renderLineHighlight: 'none',
    suggestOnTriggerCharacters: true,
    quickSuggestions: true,
    scrollBeyondLastLine: true,
    automaticLayout: true,
    lineNumbers: lineNumbers ? 'on' : 'off',
    wordBasedSuggestions: 'allDocuments' as any,
    minimap: { enabled: !!minimap },
    formatOnPaste: true,
    // minimap: { enabled: false },
    scrollbar: {
      // You can play with these options to further customize the appearance
      //  vertical: 'hidden',
      //    horizontal: 'hidden',
      //   verticalScrollbarSize: 0,
      //    horizontalScrollbarSize: 0,
    },
    wordWrap: 'on' as any, // This line enables word wrap
    // ... other options
  };

  return (
    <Editor
      //   width="600"
      height={`${height ?? 200}px`}
      width={width}
      language={language ?? 'kuvertoMarkup'}
      // theme="kuvertoTheme"
      theme={!htmlCodeEditor ? 'kuvertoTheme' : 'light'}
      value={reference ? referenceValue : promptInput}
      options={options as any}
      onChange={onChange}
      onMount={editorDidMount}
    />
  );
}
