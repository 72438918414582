import { yupResolver } from '@hookform/resolvers/yup';
import { debounce, MenuItem, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import FormProvider, { RHSelect } from 'src/components/hook-form';
import PreviousActionsSelect from 'src/components/previous-actions/PreviousActionsSelect';
import { setActionValidated, updateSelectedAiFlowItem, updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';
import { useForm } from 'react-hook-form';
import { RHRadioGroup } from 'src/components/hook-form/RHFRadioGroup';
import { useBoolean } from 'src/hooks/use-boolean';
import { validateURL } from 'src/utils/urlUtils';

export default function AiFlowScrapperActionSetup() {
  const dispatch = useDispatch();
  const [selectedSource, setSelectedSource] = useState<string>('');
  const [scrapperSourceType, setScrapperSourceType] = useState<string>('URL');
  const [url, setUrl] = useState<string>('');
  const [scrapperSourceOptions, setScrapperSourceOptions] = useState([
    { label: 'URL', value: 'URL' },
    { label: 'Source', value: 'Source' },
  ]);

  const urlError = useBoolean(false);

  const defaultValues = {
    selectedSource: '',
  };

  const AiFlowScrapperSchema: any = Yup.object().shape({
    selectedSource: Yup.string().when([], (schema) =>
      scrapperSourceType === 'URL' ? schema : schema.required('Required')
    ),
  });

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowScrapperSchema),
    mode: 'all',
    defaultValues,
  });

  // Update the form validation schema when scrapperSourceType changes
  useEffect(() => {
    methods.reset({}, { keepValues: true }); // Keep form values but reset validation
    methods.trigger(); // Re-validate form with new schema
  }, [scrapperSourceType, methods]);

  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.inputData?.actionInputData?.functionalInputData?.scrapperInput) {
        setSelectedSource(
          selectedItem.inputData?.actionInputData?.functionalInputData?.scrapperInput
            ?.source as string
        );
        setUrl(
          selectedItem.inputData?.actionInputData?.functionalInputData?.scrapperInput?.url as string
        );
        setScrapperSourceType(
          (selectedItem.inputData?.actionInputData?.functionalInputData?.scrapperInput
            ?.sourceType as string) ?? 'URL'
        );
        methods.setValue(
          'selectedSource',
          selectedItem.inputData?.actionInputData?.functionalInputData?.scrapperInput?.source ?? ''
        );
        methods.trigger();
      }
    }
  }, [selectedItem, methods]);

  const {
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
    trigger,
  } = methods;

  useEffect(() => {
    dispatch(setActionValidated(isValid));
  }, [isValid, dispatch]);

   // Debounced function to handle validation
   const handleValidation = debounce((value) => {
    if (!validateURL(value)) {
      urlError.onTrue();
    } else {
      urlError.onFalse();
    }
  }, 500); // 500ms debounce delay

  useEffect(() => {
    // Call the debounced validation function whenever `url` changes
    if (url) {
      handleValidation(url);
    }

    // Cleanup function to cancel debounce on unmount
    return () => {
      handleValidation.clear();
    };
  }, [url, handleValidation]);

  return (
    <>
      <FormProvider methods={methods}>
        <Stack spacing={3} sx={{ mt: 2, mx: 1 }}>
          <RHRadioGroup
            value={scrapperSourceType}
            onChange={(event) => {
              setScrapperSourceType(event.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    functionalInputData: {
                      ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                      scrapperInput: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData
                          ?.scrapperInput,
                        sourceType: event.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
            row
            spacing={4}
            name="scrapperSources"
            options={scrapperSourceOptions}
          />

          {scrapperSourceType === 'URL' && (
            <TextField
              size="small"
              error={urlError.value}
              required
              helperText={urlError.value ? 'Invalid URL' : 'Please enter a valid URL'}
              //    helperText="Please enter a valid URL"
              fullWidth
              onChange={(event) => {
                setUrl(event.target.value);
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      functionalInputData: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                        scrapperInput: {
                          ...selectedItem?.inputData?.actionInputData?.functionalInputData
                            ?.scrapperInput,
                          url: event.target.value,
                        },
                      },
                    },
                  },
                };
                dispatch(updateTempSelectedAiFlowItem(updatedItem));
              }}
              value={url}
              placeholder="Enter URL to scrape"
              sx={{
                minWidth: 350,
              }}
              label="URL"
            />
          )}
          {scrapperSourceType === 'Source' && (
            <PreviousActionsSelect
              selectedSourceInput={selectedSource}
              onPreviousActionsSelect={(source: string) => {
                setSelectedSource(source);
                methods.setValue('selectedSource', source);
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      functionalInputData: {
                        ...selectedItem?.inputData?.actionInputData?.functionalInputData,
                        scrapperInput: {
                          ...selectedItem?.inputData?.actionInputData?.functionalInputData
                            ?.scrapperInput,
                          source,
                        },
                      },
                    },
                  },
                };
                dispatch(updateTempSelectedAiFlowItem(updatedItem));
              }}
            />
          )}
        </Stack>
      </FormProvider>
    </>
  );
}
