import { yupResolver } from '@hookform/resolvers/yup';
import { MenuItem, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFTextField, RHSelect } from 'src/components/hook-form';
import PreviousActionsSelect from 'src/components/previous-actions/PreviousActionsSelect';
import { useDispatch, useSelector } from 'src/redux/store';
import { setActionValidated, updateSelectedAiFlowItem, updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';

interface Params {
  item?: any;
}

export default function AiFlowExportActionSetup({ item }: Params) {
  const [selectedFileType, setSelectedFileType] = useState<string>('Html');
  const [fileName, setFileName] = useState<string>('');
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const [selectedExportProvider, setSelectedExportProvider] = useState<string>('Google Drive');
  const [downloadbleOptions, setDownloadbleOptions] = useState([
    { label: 'Csv', value: 'Csv' },
    { label: 'Html', value: 'Html' },
    { label: 'Word', value: 'Word' },
    { label: 'PDF', value: 'PDF' },
    { label: 'Text', value: 'Text' },
  ]);
  const dispatch = useDispatch();
  const [exportProviderOptions, setExportProviderOptions] = useState([
    { label: 'Google Drive', value: 'Google Drive' },
    { label: 'Dropbox', value: 'Dropbox' },
  ]);

  const defaultValues = {
    fileName: '',
    selectedSource: '',
  };

  const AiFlowExportSchema: any = Yup.object().shape({
    fileName: Yup.string().required('Required'),
    selectedSource: Yup.string().required('Required'),
  });

  const [selectedSource, setSelectedSource] = useState<string>();
  const methods = useForm<any>({
    resolver: yupResolver(AiFlowExportSchema),
    mode: 'all',
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
    trigger,
  } = methods;

  useEffect(() => {
    dispatch(setActionValidated(isValid));
  }, [isValid, dispatch]);

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.inputData?.actionInputData?.exportData) {
        setSelectedSource(selectedItem.inputData?.actionInputData?.exportData?.source as string);
        setFileName(selectedItem.inputData?.actionInputData?.exportData?.fileName as string);
        setSelectedFileType(
          selectedItem.inputData?.actionInputData?.exportData?.fileType as string
        );
        methods.setValue('fileName', selectedItem.inputData?.actionInputData?.exportData?.fileName);
        methods.setValue('selectedSource', selectedItem.inputData?.actionInputData?.exportData?.source);
        methods.trigger();

      }
    }
  }, [selectedItem, methods]);

  const [selectedExportOption, setSelectedExportOption] = useState<string>('File');

  return (
    <>
      <FormProvider methods={methods}>
        <Stack spacing={3}>
          <>
            <Stack spacing={3}>
              <PreviousActionsSelect
                selectedSourceInput={selectedSource}
                onPreviousActionsSelect={(source) => {
                  setSelectedSource(source);
                  setValue('selectedSource', source);
                  const updatedItem = {
                    ...selectedItem,
                    inputData: {
                      ...selectedItem?.inputData,
                      actionInputData: {
                        ...selectedItem?.inputData?.actionInputData,
                        exportData: {
                          ...selectedItem?.inputData?.actionInputData?.exportData,
                          source,
                          fileName,
                          fileType: selectedFileType,
                        },
                      },
                    },
                  };
                  dispatch(updateTempSelectedAiFlowItem(updatedItem));
                }}
              />
              <RHSelect
                key="selectFileType"
                name="selectFileType"
                size="small"
                label="Select File Type"
                zIndex={3400}
                value={selectedFileType}
                sx={{ px: 1 }}
                onChange={(event) => {
                  setSelectedFileType(event.target.value);
                  const updatedItem = {
                    ...selectedItem,
                    inputData: {
                      ...selectedItem?.inputData,
                      actionInputData: {
                        ...selectedItem?.inputData?.actionInputData,
                        exportData: {
                          ...selectedItem?.inputData?.actionInputData?.exportData,
                          source: selectedSource,
                          fileName,
                          fileType: event.target.value,
                        },
                      },
                    },
                  };
                  dispatch(updateTempSelectedAiFlowItem(updatedItem));
                }}
                InputLabelProps={{ shrink: true }}
              >
                {downloadbleOptions.map((downloadble) => (
                  <MenuItem value={downloadble.value}>
                    <span>{downloadble.label}</span>
                  </MenuItem>
                ))}
              </RHSelect>
              <RHFTextField
                onChange={(event) => {
                  setFileName(event.target.value);
                  setValue('fileName', event.target.value);
                  const updatedItem = {
                    ...selectedItem,
                    inputData: {
                      ...selectedItem?.inputData,
                      actionInputData: {
                        ...selectedItem?.inputData?.actionInputData,
                        exportData: {
                          ...selectedItem?.inputData?.actionInputData?.exportData,
                          source: selectedSource,
                          fileName: event.target.value,
                          fileType: selectedFileType,
                        },
                      },
                    },
                  };
                  dispatch(updateTempSelectedAiFlowItem(updatedItem));
                }}
                name="fileName"
                value={fileName}
                size="small"
                label="File Name"
                sx={{ px: 1 }}
              />
            </Stack>
          </>
        </Stack>
      </FormProvider>
    </>
  );
}
