import { Box, MenuItem } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { RHFSelect, RHSelect } from 'src/components/hook-form';
import { useSelector } from 'src/redux/store';

export interface Props {
  maxWidth?: number;
  name?: string;
  disabled?: boolean;
  flexGrow?: number;
  label?: string;
  disabledSources?: string[];
  selectedSourceInput?: string | null | undefined;
  onPreviousActionsSelect: (previousActionName: string) => void;
}

export default function PreviousActionsSelect({
  maxWidth,
  label,
  disabled,
  flexGrow,
  disabledSources,
  name,
  selectedSourceInput,
  onPreviousActionsSelect,
}: Props) {
  const [previousActions, setPreviousActions] = useState<any[]>([]);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const [selectedSource, setSelectedSource] = useState<any>();
  useEffect(() => {
    const tmpPreviousActions = selectedAiFlow?.items?.filter(
      (item: any) => item && (item.sequence as number) < (selectedItem?.sequence as number)
    );
    const previousActionsStrArr =
      tmpPreviousActions
        ?.filter((item: any) => item.header !== 'Branch' && item.header !== 'Branches')
        .map((item: any) => ({ label: `${item.sequence}. ${item.name}`, value: item.name })) ?? [];
    if (!_.isEqual(previousActionsStrArr, previousActions)) {
      setPreviousActions(previousActionsStrArr);
    }
  }, [selectedAiFlow, selectedItem, previousActions]);

  return (
    <>
      <Box sx={{ maxWidth, marginTop: '16px !important', px: 1, flexGrow }}>
        <RHFSelect
          required
          disabled={disabled}
          variant="standard"
          key="selectSource"
          zIndex={3400}
          name={name ?? 'selectedSource'}
          size="small"
          label={label ?? 'Select Source'}
          value={selectedSource ?? selectedSourceInput ?? ''}
          onChange={(event) => {
            console.log('event.target.value', event.target.value);
            setSelectedSource(event.target.value);
            onPreviousActionsSelect(event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
        >
          {previousActions.map((action) => (
            <MenuItem value={action.value} disabled={disabledSources?.includes(action.value) ?? false}>
              <span>{action.label}</span>
            </MenuItem>
          ))}
        </RHFSelect>
      </Box>
    </>
  );
}
