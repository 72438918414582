import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InputCondition } from 'src/api';
import FormProvider from 'src/components/hook-form';
import ConditionForm from 'src/components/validation/ConditionForm';
import { setActionValidated, updateSelectedAiFlowItem, updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';
import * as Yup from 'yup';

export default function AiFlowConditionActionSetup() {
  const dispatch = useDispatch();
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const [conditions, setConditions] = useState<InputCondition[]>([{ condition: 'contains' }]);

  const defaultValues = {
    //   branchName: branchName ?? '',
  };

  const AiFlowSchema: any = Yup.object().shape({
    // branchName: Yup.string()
    //   .required('Required')
    //   .test(
    //     'uniqueName',
    //     'Name already exists',
    //     (value: any) => !branchNameAlreadyExist(value as string)
    //   ),
  });

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowSchema),
    mode: 'all',
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    trigger,
    setValue,
  } = methods;

  useEffect(() => {
    if (selectedItem) {
      console.log('selectedItem', selectedItem);
      setConditions(
        selectedItem.inputData?.actionInputData?.conditionInputData?.conditions ?? [
          { condition: 'contains' },
        ]
      );
    }
  }, [selectedItem, setValue, methods]);

  useEffect(() => {
    dispatch(setActionValidated(isValid));
  }, [isValid, dispatch]);

  const updateConditionsItem = (newConditions: InputCondition[]) => {
    console.log('newConditions', newConditions);
    const updatedItem = {
      ...selectedItem,
      inputData: {
        ...selectedItem?.inputData,
        actionInputData: {
          ...selectedItem?.inputData?.actionInputData,
          conditionInputData: {
            ...selectedItem?.inputData?.actionInputData?.conditionInputData,
            conditions: newConditions,
          },
        },
      },
    };
    dispatch(updateTempSelectedAiFlowItem(updatedItem));
  };

  return (
    <>
      <FormProvider methods={methods}>
        <Stack spacing={3} sx={{ mx: 1 }}>
          <>
            <ConditionForm
              inputConditions={conditions}
              onUpdateConditions={updateConditionsItem}
            />
          </>
        </Stack>
      </FormProvider>
    </>
  );
}
