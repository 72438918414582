import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import FormProvider from 'src/components/hook-form';
import PreviousActionsSelect from 'src/components/previous-actions/PreviousActionsSelect';
import { updateTempSelectedAiFlowItem, setActionValidated, updateSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';
import { useForm } from 'react-hook-form';
import FinetuneModelSelect from 'src/components/model/FinetuneModelSelect';
import ConnectionSelect from 'src/components/connection/ConnectionSelect';
import { ACTION_ICONS } from 'src/common/constants/icons.constants';
import { TinyStyledIcon } from 'src/components/nav-section/mini/styles';

export default function AiFlowFinetuneActionSetup() {
  const dispatch = useDispatch();
  const [selectedSource, setSelectedSource] = useState<string>();
  const [selectedConnection, setSelectedConnection] = useState<number>();
  const [selectedModel, setSelectedModel] = useState<string>();
  const [suffix, setSuffix] = useState<string>('');
  const runsMode = useSelector((state) => state.aiFlows.runsMode);

  const defaultValues = {
    selectedSource: '',
  };

  const AiFlowFineTuneSchema: any = Yup.object().shape({
    selectedSource: Yup.string().required('Required'),
  });

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowFineTuneSchema),
    mode: 'all',
    defaultValues,
  });

  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.inputData?.actionInputData?.llmInputData?.fineTuneInputData) {
        setSelectedSource(
          selectedItem.inputData?.actionInputData?.llmInputData?.fineTuneInputData?.source as string
        );
        setSelectedModel(
          selectedItem.inputData?.actionInputData?.llmInputData?.fineTuneInputData?.model as string
        );
        setSelectedConnection(
          selectedItem.inputData?.actionInputData?.llmInputData?.fineTuneInputData
            ?.connectionId as number
        );
        setSuffix(
          selectedItem.inputData?.actionInputData?.llmInputData?.fineTuneInputData?.suffix as string ?? ''
        );
        methods.setValue(
          'selectedSource',
          selectedItem.inputData?.actionInputData?.llmInputData?.fineTuneInputData?.source as string
        );
        methods.trigger();
      }
    }
  }, [selectedItem, methods]);

  const {
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
    trigger,
  } = methods;

  useEffect(() => {
    dispatch(setActionValidated(isValid));
  }, [isValid, dispatch]);

  return (
    <>
      <FormProvider methods={methods}>
        <Stack spacing={4}>
          <ConnectionSelect
            disabled={runsMode}
            withoutKuverto
            px={1}
            selectedIntegration="OpenAI"
            selectedConnectionInput={selectedConnection}
            onConnectionSelect={(connectionId: number) => {
              setSelectedConnection(connectionId);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    llmInputData: {
                      ...selectedItem?.inputData?.actionInputData?.llmInputData,
                      fineTuneInputData: {
                        ...selectedItem?.inputData?.actionInputData?.llmInputData
                          ?.fineTuneInputData,
                        connectionId,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
          />
          <PreviousActionsSelect
            label="Fine-tune Training Data Source"
            name="selectedSource"
            key="selectedSource"
            selectedSourceInput={selectedSource}
            onPreviousActionsSelect={(source: string) => {
              setSelectedSource(source);
              setValue('selectedSource', source);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    llmInputData: {
                      ...selectedItem?.inputData?.actionInputData?.llmInputData,
                      fineTuneInputData: {
                        ...selectedItem?.inputData?.actionInputData?.llmInputData
                          ?.fineTuneInputData,
                        source,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
          />
          <FinetuneModelSelect
            onModeSelect={(model) => {
              setSelectedModel(model);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    llmInputData: {
                      ...selectedItem?.inputData?.actionInputData?.llmInputData,
                      fineTuneInputData: {
                        ...selectedItem?.inputData?.actionInputData?.llmInputData
                          ?.fineTuneInputData,
                        model,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
            selectedModelInput={selectedModel}
          />
          <TextField
            disabled={runsMode}
            required
            fullWidth
            value = {suffix}
            label="Suffix"
            name="suffix"
            helperText="The suffix to add to the model name."
            size="small"
            sx={{ px: 1 }}
            variant="standard"
            onChange={(e) => {
              setSuffix(e.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    llmInputData: {
                      ...selectedItem?.inputData?.actionInputData?.llmInputData,
                      fineTuneInputData: {
                        ...selectedItem?.inputData?.actionInputData?.llmInputData
                          ?.fineTuneInputData,
                        suffix: e.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
          />

          <Button
            fullWidth
            variant="outlined"
            startIcon={<TinyStyledIcon>{ACTION_ICONS.configurations}</TinyStyledIcon>}
            size="large"
            color="inherit"
            sx={{
              maxWidth: { md: 180 },
              maxHeight: { md: 40 },
            }}
            onClick={() => {
              //   textToSpeechConfigurations.onTrue();
              //      openEmailConfiguration.onTrue();
            }}
          >
            Configurations
          </Button>
        </Stack>
      </FormProvider>
    </>
  );
}
