import { useState } from 'react';
import MenuPopover from 'src/components/menu-popover';
import AiFlowSideBar from '../../sidebar/AiFlowSideBar';
import { useDispatch, useSelector } from 'src/redux/store';
import { setSidebarPopoverOpen } from 'src/redux/slices/aiflows';

export default function AiFlowSidebarPopover() {
  const sidebarPopoverOpen = useSelector((state) => state.aiFlows.sidebarPopoverOpen);
  const sideBarPosition = useSelector((state) => state.aiFlows.sidebarPosition);
  const dispatch = useDispatch();
  return (
    <>
      <MenuPopover
      zIndex={2400}
        open={sidebarPopoverOpen}
        onClose={() => dispatch(setSidebarPopoverOpen({element:null}))}
        anchorOrigin={{
          horizontal: sideBarPosition as any,
          vertical: 'bottom',
        }}
        //   transformOrigin={{
        //     horizontal: 300, // Shift the Popover 50px to the left
        //     vertical: 'bottom',
        //   }}
      >
        <AiFlowSideBar />
      </MenuPopover>
    </>
  );
}
