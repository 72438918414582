import { Chip, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { FolderLogic } from 'src/api';
import { RHFAutocomplete, RHSelect } from 'src/components/hook-form';
import { RHAutocomplete } from 'src/components/hook-form/RHFAutocomplete';
import { useSelector } from 'src/redux/store';

export interface Props {
  maxWidth?: number;
  minWidth?: number;
  disabled?: boolean;
  onMetricsSelect: (metrics: string[]) => void;
  metricsInput?: string[];
}

export default function GoogleAnalyticsMetricsSelect({
  maxWidth,
  disabled,
  metricsInput,
  minWidth,
  onMetricsSelect,
}: Props) {
  const [selectedMetrics, setSelectedMetrics] = useState<string[]>([]);
  const [allMetrics, setAllMetrics] = useState<any[]>([
    { name: 'sessions', description: 'Total number of sessions.' },
    { name: 'activeUsers', description: 'Total number of active users.' },
    { name: 'newUsers', description: 'Number of new users.' },
    { name: 'screenPageViewsPerSession', description: 'The average number of screen or page views per session.' },
    { name: 'screenPageViews', description: 'Total number of screen or page views.' },
    { name: 'engagedSessions', description: 'Number of engaged sessions.' },
    { name: 'engagementRate', description: 'Percentage of engaged sessions.' },
    { name: 'bounceRate', description: 'The percentage of sessions that consisted of only one screen or page view.' },
    { name: 'averageSessionDuration', description: 'Average session duration in seconds.' },
    { name: 'userEngagementDuration', description: 'Total engagement time in seconds.' },
    { name: 'totalUsers', description: 'Total number of users.' },
    { name: 'conversions', description: 'The total number of conversions recorded, including all goals.' },
    { name: 'eventCount', description: 'Total number of events.' },
    { name: 'revenue', description: 'Total revenue from all sources.' },
    { name: 'ecommercePurchases', description: 'Number of e-commerce purchases.' },
    { name: 'averageRevenuePerUser', description: 'Average revenue per user.' },
    { name: 'inAppPurchases', description: 'Number of in-app purchases.' },
  ]);

  useEffect(() => {
    if (metricsInput) {
      // dimensionsInput includes only the name, so we need to find the whole object
      const metrics = allMetrics.filter((metric) => metricsInput.includes(metric.name));
      setSelectedMetrics(metrics);
    }
  }, [metricsInput, allMetrics]);
  return (
    <>
      <RHAutocomplete
        name="workingSchedule"
        placeholder="+ Metrics"
        multiple
        label="Metrics"
        value={selectedMetrics}
        size="small"
        zIndex={5000}
        onChange={(event, value) => {
          //  const names = value.map((val) => val.name);
          setSelectedMetrics(value);
          onMetricsSelect(value);
        }}
        disableCloseOnSelect
        options={allMetrics.map((option) => option)}
        getOptionLabel={(option) => option.description + option.name}
        renderOption={(props, option) => (
          <li {...props} key={option}>
            {option.description} ({option.name})
          </li>
        )}
        renderTags={(selected, getTagProps) =>
          selected.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option.description}
              label={option.description}
              size="small"
              color="primary"
              variant="soft"
            />
          ))
        }
      />
      {/* <RHAutocomplete
        disabled={disabled}
        name="metrics"
        size="small"
        label="Metrics"
        onChange={(event, value) => {
          setSelectedMetrics(value);
          onMetricsSelect(value);
        }}
        placeholder="+ Metrics"
        multiple
        value={selectedMetrics}
        freeSolo
        options={JOB_WORKING_SCHEDULE_OPTIONS.map((option) => option)}
        getOptionLabel={(option) => option}
        renderOption={(props, option) => (
          <li {...props} key={option}>
            {option}
          </li>
        )}
        renderTags={(selected, getTagProps) =>
          selected.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option}
              label={option}
              size="small"
              color="info"
              variant="soft"
            />
          ))
        }
      /> */}
    </>
  );
}
