import { MenuItem, Avatar } from '@mui/material';
import { Stack } from '@mui/system';
import { RHFSelect, RHSelect } from '../hook-form';
import Iconify from '../iconify';
import { useEffect, useState } from 'react';
import { useSelector } from 'src/redux/store';
import { UserIntegration } from 'src/common/models/user-integration';
import { Integration } from 'src/api';

export interface Props {
  handleSelectProvider: (provider: string) => void;
  selectedProviderInput?: string;
  canOnlyBeUsedIn?: string[];
  specificGroups?: string[];
  notInGroups?: string[];
  maxWidth?: number;
  disabled?: boolean;
  px?: any;
}

export default function ProviderSelect({
  handleSelectProvider,
  maxWidth,
  specificGroups,
  notInGroups,
  disabled,
  canOnlyBeUsedIn,
  selectedProviderInput,
  px,
}: Props) {
  const [selectedProvider, setSelectedProvider] = useState<string>();
  const [filteredIntegrations, setFilteredIntegrations] = useState<Integration[]>([]);
  const integrationGroups = useSelector((state) => state.integration.integrationGroups);
  const hasCommonElement = (arr1: string[], arr2: string[]): boolean =>
    arr2.some((element) => arr1.includes(element));

  const integrations =
    integrationGroups &&
    integrationGroups
      // filter specific groups if exist
      .filter(
        (group) =>
          !specificGroups || specificGroups.includes(group.label?.toLocaleLowerCase() as string)
      )
      // filter notInGroups if exist
      .filter(
        (group) => !notInGroups || !notInGroups.includes(group.label?.toLocaleLowerCase() as string)
      )

      .flatMap((group) => group.integrations);
  const uniqueIntegrationsByName = integrations.filter(
    (v, i, a) =>
      a.findIndex((t) => t?.integrationName === v?.integrationName && v?.enabled) === i &&
      (canOnlyBeUsedIn ? hasCommonElement(canOnlyBeUsedIn, v?.canOnlyBeUsedIn ?? []) : true) &&
      // integrationGroups
      //   ?.find((group) =>
      //     group?.integrations?.find(
      //       (integration) => integration?.integrationName === v?.integrationName
      //     )
      //   )
      //   ?.label?.toLowerCase() !== 'large language models'

      // &&
      v?.integrationType
  );

  return (
    <>
      <RHSelect
        disabled={disabled}
        name="selectProvider"
        label="Select Provider"
        zIndex={2500}
        value={(selectedProvider ?? selectedProviderInput) || ''}
        onChange={(e) => {
          setSelectedProvider(e.target.value);
          handleSelectProvider(e.target.value as string);
        }}
        size="small"
        InputLabelProps={{ shrink: true }}
        sx={{ maxWidth: { md: maxWidth ?? 300 }, px }}
      >
        {uniqueIntegrationsByName.map((integration, index) => (
          <MenuItem key={index} value={integration?.integrationName ?? ''}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Avatar
                src={`/assets/images/integrations/${integration?.integrationName}.svg`}
                sx={{ width: 24, height: 24 }}
              />
              <span>{integration?.integrationLabel}</span>
            </Stack>
          </MenuItem>
        ))}
        {/* {syncedEmailMarketingProviderIntegrations.map((integration, index) => (
          <MenuItem key={index} value={integration?.integrationName}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Avatar
                src={`/assets/images/integrations/${integration.integrationName}.png`}
                sx={{ width: 24, height: 24 }}
              />
              <span>{integration?.integrationName}</span>
            </Stack>
          </MenuItem>
        ))} */}
        {/* <MenuItem value="addIntegration">
          <Iconify sx={{ mr: 1 }} icon="material-symbols:add" />
          <span>Add Integration</span>
        </MenuItem> */}
      </RHSelect>
    </>
  );
}
