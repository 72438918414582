import _ from 'lodash';
import { useEffect, useState } from 'react';
import { RHAutocomplete } from 'src/components/hook-form/RHFAutocomplete';
import { useDispatch, useSelector } from 'src/redux/store';

export interface Props {
  handleSelectMethod: (provider: string) => void;
  selectedMethodInput?: string;
  operations?: boolean;
  maxWidth?: number;
  disabled?: boolean;
  px?: number;
}

export default function OpenAiInputMethodSelect({
  handleSelectMethod,
  maxWidth,
  disabled,
  operations,
  selectedMethodInput,
  px,
}: Props) {
  const [selectedMethod, setSelectedMethod] = useState<any>(selectedMethodInput);
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const [allMethods, setAllMethods] = useState<any[]>(['Get Fine-tuned models']);

  const [inputMethods, setInputMethods] = useState<any[]>(['Get Fine-tuned models']);

  return (
    <>
      <RHAutocomplete
        disabled={runsMode}
        zIndex={5000}
        name="methods"
        size="small"
        label="Methods"
        options={allMethods}
        sx={{ minWidth: { md: 200 }, px }}
        value={selectedMethodInput ?? selectedMethod}
        onChange={async (event: any, newValue: any) => {
          setSelectedMethod(newValue);
          handleSelectMethod(newValue);
        }}
        getOptionLabel={(option: any) => option}
      />
    </>
  );
}
