import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import CreditsDialog from 'src/components/credits/CreditsDialog';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { useBoolean } from 'src/hooks/use-boolean';
import { useSelector } from 'src/redux/store';

export default function CreditStatus() {
  const creditsOpen = useBoolean(false);
  const subscriptionTiers = useSelector((state) => state.subscription.tiers);
  const basicBillingInfo = useSelector((state) => state.subscription.basicBillingInfo);
  const [totalUnits, setTotalUnits] = useState<number>();
  const [formattedTotalUnits, setFormattedTotalUnits] = useState<string>();
  const creditStatus = useSelector((state) => state.creditStatus.creditStatus);
  useEffect(() => {
    const subscriptionTier = subscriptionTiers.find(
      (tier) => tier.name === basicBillingInfo?.productName
    );
    if (subscriptionTier && subscriptionTier.monthlyCreditUnits) {
      setTotalUnits(subscriptionTier.monthlyCreditUnits as number);
      setFormattedTotalUnits(subscriptionTier.monthlyCreditUnits.toLocaleString());
    } else {
      setTotalUnits(200);
      setFormattedTotalUnits('200');
    }
  }, [basicBillingInfo, subscriptionTiers]);
  return (
    <>
      <Label color={(creditStatus?.monthlyUnitsUsed as number) < (totalUnits as number) ? 'primary' : 'error'}>
        {creditStatus?.monthlyUnitsUsed} / {formattedTotalUnits}
        {creditStatus &&
          !!creditStatus.additionalUnits &&
          ` (${creditStatus?.additionalUnits})`}{' '}
        Used Tasks
        {/* <IconButton onClick={creditsOpen.onTrue} sx={{ ml: 1 }}>
          <Iconify icon="grommet-icons:upgrade" />
        </IconButton> */}
      </Label>
      <CreditsDialog open={creditsOpen.value} onClose={creditsOpen.onFalse} />
    </>
  );
}
