import { Grid, GridProps, Skeleton } from '@mui/material';

export default function AiFlowDialogsSkeleton({ ...other }: GridProps) {
  return (
    <Grid container spacing={3} {...other}>
      <Grid item xs={12}>
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
      </Grid>
    </Grid>
  );
}
