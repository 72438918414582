// @mui
import { Box, Skeleton, Grid, GridProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function SkeletonWorkflowDesigner({ height, ...other }: GridProps) {
  return (
    <Grid item xs={12} sx = {{marginTop:2}} {...other}>
      <Box sx={{ display: 'flex', mb: 1.5 }}>
        <Skeleton variant="text" sx={{ mx: 3, flexGrow: 1 }} />
      </Box>
      <Box sx={{ display: 'flex', mb: 1.5 }}>
        <Skeleton variant="text" sx={{ mx: 3, flexGrow: 1 }} />
      </Box>
      <Box sx={{ display: 'flex', mb: 1.5 }}>
        <Skeleton variant="text" sx={{  mx: 3, flexGrow: 1 }} />
      </Box>
      <Skeleton
        variant="rectangular"
        sx={{ height: height ?? 480, flexGrow:1,  mx: 3, borderRadius: 2 }}
      />
    </Grid>
  );
}
