import { yupResolver } from '@hookform/resolvers/yup';
import { Chip, MenuItem, Paper, Stack, TextField } from '@mui/material';
import _, { set } from 'lodash';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFAutocomplete, RHSelect } from 'src/components/hook-form';
import { setActionValidated, updateSelectedAiFlowItem, updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';
import PreviousActionsChips from 'src/components/previous-actions/PreviousActionsChips';
import { SmartEditor } from 'src/widgets/smart-editor/SmartEditor';
import ConnectionSelect from 'src/components/connection/ConnectionSelect';
import PromptEditor from 'src/components/prompt-editor/PromptEditor';
import { RHRadioGroup } from 'src/components/hook-form/RHFRadioGroup';
import ProviderSelect from 'src/components/provider-select/ProviderSelect';

export default function AiFlowEmailActionSetup() {
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const [subjectLine, setSubjectLine] = useState<string>();
  const [to, setTo] = useState<string[]>([]);
  const [cc, setCc] = useState<string[]>([]);
  const [bcc, setBcc] = useState<string[]>([]);
  const [selectedConnection, setSelectedConnection] = useState<number>(0);
  const [fromName, setFromName] = useState<string>();
  const [fromEmail, setFromEmail] = useState<string>('system@kuverto.com');
  const [selectedProvider, setProvider] = useState<string>('TwilioSendgrid');
  const [bodyPlainText, setBodyPlainText] = useState<string>();
  const [bodyType, setBodyType] = useState<string>('PlainText');
  const [sendType, setSendType] = useState<string>('Single');
  const [bodyHtml, setBodyHtml] = useState<string>();
  const [initialbody, setInitialBody] = useState<string>();
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const [bodyTypes, setBodyTypes] = useState([
    { label: 'Plain Text', value: 'PlainText', disabled: runsMode },
    { label: 'Html', value: 'Html', disabled: runsMode },
  ]);
  const defaultValues = {
    // reciepients: '',
    // subjectLine: '',
    // body: '',
  };
  const [sendTypes, setSendTypes] = useState([
    { label: 'Single Send', value: 'Single' },
    { label: 'Multi Send', value: 'Multi' },
  ]);

  const AiFlowEmailActionSetupSchema: any = Yup.object().shape({
    // reciepients: Yup.string().required('Required'),
    // subjectLine: Yup.string().required('Required'),
    // body: Yup.string().required('Required'),
  });

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowEmailActionSetupSchema),
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    if (selectedItem) {
      setSubjectLine(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.emailInput?.subjectLine ??
          ''
      );
      setProvider(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.emailInput?.provider ??
          'TwilioSendgrid'
      );
      setSelectedConnection(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.emailInput
          ?.connectionId ?? 0
      );
      setTo(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.emailInput?.toList ?? []
      );
      setCc(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.emailInput?.ccList ?? []
      );
      setBcc(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.emailInput?.bccList ?? []
      );
      setBodyType(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.emailInput?.bodyType ??
          'PlainText'
      );
      setFromName(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.emailInput?.fromName ??
          'Kuverto'
      );
      setFromEmail(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.emailInput?.fromEmail ??
          'system@kuverto.com'
      );
      setBodyPlainText(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.emailInput
          ?.bodyPlainText ?? ''
      );
      setSendType(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.emailInput?.sendType ??
          'Single'
      );
      if (!initialbody) {
        setInitialBody(
          selectedItem?.inputData?.actionInputData?.communicationInputData?.emailInput?.bodyHtml ??
            ''
        );
      }
    }
  }, [selectedItem, initialbody]);

  useEffect(() => {
    if (selectedItem) {
      // d
    }
  }, [selectedItem, methods]);

  const dispatch = useDispatch();
  const {
    formState: { isValid },
  } = methods;

  // useEffect(() => {
  //   dispatch(setActionValidated(isValid));
  // }, [isValid, dispatch]);
  return (
    <>
      <FormProvider methods={methods}>
        <Stack spacing={3} sx={{ mt: 2, mx: 1 }}>
          {/* <PreviousActionsChips /> */}
          {/* <ConfigurationConnectionSelect
            selectedIntegration="SendGrid Twilio"
            onConnectionSelect={(connectionId: number) => {}}
          /> */}
          {/* <Stack direction="row" spacing={2}>
            <ProviderSelect
              disabled={runsMode}
              canOnlyBeUsedIn={['Email']}
              selectedProviderInput={selectedProvider}
              maxWidth={260}
              handleSelectProvider={(sp) => {
                setProvider(sp);
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      communicationInputData: {
                        ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                        emailInput: {
                          ...selectedItem?.inputData?.actionInputData?.communicationInputData
                            ?.emailInput,
                          provider: sp,
                        },
                      },
                    },
                  },
                };
                dispatch(updateTempSelectedAiFlowItem(updatedItem));
              }}
            />
            <ConnectionSelect
              disabled={runsMode}
              selectedConnectionInput={selectedConnection}
              selectedIntegration={selectedProvider}
              onConnectionSelect={(conId: number) => {
                setSelectedConnection(conId);
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      communicationInputData: {
                        ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                        emailInput: {
                          ...selectedItem?.inputData?.actionInputData?.communicationInputData
                            ?.emailInput,
                          connectionId: conId,
                        },
                      },
                    },
                  },
                };
                dispatch(updateTempSelectedAiFlowItem(updatedItem));
              }}
            />
          </Stack> */}
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              fullWidth
              disabled={
                (selectedConnection === 0 && selectedProvider === 'TwilioSendgrid') || runsMode
              }
              onChange={(event) => {
                setFromEmail(event.target.value);
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      communicationInputData: {
                        ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                        emailInput: {
                          ...selectedItem?.inputData?.actionInputData?.communicationInputData
                            ?.emailInput,
                          fromEmail: event.target.value,
                        },
                      },
                    },
                  },
                };
                dispatch(updateTempSelectedAiFlowItem(updatedItem));
              }}
              value={fromEmail}
              placeholder="From Email"
              label="From Email"
            />

            <TextField
              fullWidth
              disabled={runsMode}
              size="small"
              onChange={(event) => {
                setFromName(event.target.value);
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      communicationInputData: {
                        ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                        emailInput: {
                          ...selectedItem?.inputData?.actionInputData?.communicationInputData
                            ?.emailInput,
                          fromName: event.target.value,
                        },
                      },
                    },
                  },
                };
                dispatch(updateTempSelectedAiFlowItem(updatedItem));
              }}
              value={fromName}
              placeholder="From Name"
              label="From Name"
            />
          </Stack>
          <RHRadioGroup
            value={sendType}
            onChange={(event) => {
              setSendType(event.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    communicationInputData: {
                      ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                      emailInput: {
                        ...selectedItem?.inputData?.actionInputData?.communicationInputData
                          ?.emailInput,
                        sendType: event.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
            row
            spacing={4}
            name="sendTypes"
            options={sendTypes}
          />
          <RHFAutocomplete
            disabled={runsMode}
            name="to"
            size="small"
            label="To"
            helperText="Press Enter to add email"
            onChange={(event, value) => {
              setTo(value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    communicationInputData: {
                      ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                      emailInput: {
                        ...selectedItem?.inputData?.actionInputData?.communicationInputData
                          ?.emailInput,
                        toList: value,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
            placeholder="+ To"
            multiple
            value={to}
            freeSolo
            options={to.map((option) => option)}
            getOptionLabel={(option) => option}
            renderOption={(props, option) => (
              <li {...props} key={option}>
                {option}
              </li>
            )}
            renderTags={(selected, getTagProps) =>
              selected.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option}
                  label={option}
                  size="small"
                  color="info"
                  variant="soft"
                />
              ))
            }
          />
          <Stack direction="row" spacing={2} sx={{ flexGrow: 1, minWidth: '100%', width: '100%' }}>
            <RHFAutocomplete
              disabled={runsMode}
              name="cc"
              sx={{ minWidth: 260 }}
              size="small"
              label="Cc(Optional)"
              placeholder="+ CC"
              multiple
              freeSolo
              options={cc.map((option) => option)}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
              renderTags={(selected, getTagProps) =>
                selected.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={option}
                    label={option}
                    size="small"
                    color="info"
                    variant="soft"
                  />
                ))
              }
            />

            <RHFAutocomplete
              name="bcc"
              disabled={runsMode}
              sx={{ minWidth: 260 }}
              size="small"
              label="Bcc(Optional)"
              placeholder="+ Bcc"
              multiple
              freeSolo
              options={bcc.map((option) => option)}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
              renderTags={(selected, getTagProps) =>
                selected.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={option}
                    label={option}
                    size="small"
                    color="info"
                    variant="soft"
                  />
                ))
              }
            />
          </Stack>
          <TextField
            size="small"
            disabled={runsMode}
            fullWidth
            onChange={(event) => {
              setSubjectLine(event.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    communicationInputData: {
                      ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                      emailInput: {
                        ...selectedItem?.inputData?.actionInputData?.communicationInputData
                          ?.emailInput,
                        subjectLine: event.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
            value={subjectLine}
            placeholder="Subject Line"
            label="Subject Line"
          />
          <RHRadioGroup
            value={bodyType}
            onChange={(event) => {
              setBodyType(event.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    communicationInputData: {
                      ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                      emailInput: {
                        ...selectedItem?.inputData?.actionInputData?.communicationInputData
                          ?.emailInput,
                        bodyType: event.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
            row
            spacing={4}
            name="bodyTypes"
            options={bodyTypes}
          />
          {bodyType === 'PlainText' && (
            <>
              <Paper
                key=""
                variant="outlined"
                sx={{
                  py: 1.5,
                  mx: 1,
                  display: 'flex',
                  position: 'relative',
                  flexDirection: 'column',
                  flexGrow: 1,
                  height: '100%',
                }}
              >
                <PromptEditor
                  readonly={runsMode}
                  height={190}
                  promptInput={bodyPlainText ?? ''}
                  setPromptInput={(value) => {
                    setBodyPlainText(value);
                    const updatedItem = {
                      ...selectedItem,
                      inputData: {
                        ...selectedItem?.inputData,
                        actionInputData: {
                          ...selectedItem?.inputData?.actionInputData,
                          communicationInputData: {
                            ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                            emailInput: {
                              ...selectedItem?.inputData?.actionInputData?.communicationInputData
                                ?.emailInput,
                              bodyPlainText: value,
                            },
                          },
                        },
                      },
                    };
                    dispatch(updateTempSelectedAiFlowItem(updatedItem));
                  }}
                />
              </Paper>
            </>
          )}

          {bodyType === 'Html' && (
            <SmartEditor
              height={300}
              inputContent={initialbody}
              onEditorChange={(editorContent) => {
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      communicationInputData: {
                        ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                        emailInput: {
                          ...selectedItem?.inputData?.actionInputData?.communicationInputData
                            ?.emailInput,
                          bodyHtml: editorContent,
                        },
                      },
                    },
                  },
                };
                dispatch(updateTempSelectedAiFlowItem(updatedItem));
              }}
              inputTemplateId={undefined}
              provider={null}
              toolbarType="minimal"
            />
          )}
        </Stack>
      </FormProvider>
    </>
  );
}
