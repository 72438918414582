import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'src/redux/store';
import { useSnackbar } from 'src/components/snackbar';
import { Paper, Stack, Typography } from '@mui/material';
import { ConfigurationLogic, CustomActionLogic } from 'src/api';
import { addCustomAction, setSelectedCustomAction, updateCustomAction } from 'src/redux/slices/aiflows';
import PromptEditor from 'src/components/prompt-editor/PromptEditor';
import ConfigurationSelect from 'src/components/configurations/ConfigurationSelect';
import { useState } from 'react';
import _ from 'lodash';

interface Props {
  onClose: () => void;
  open: boolean;
}

export default function GenAiActionDialog({ onClose, open }: Props) {
  const dispatch = useDispatch();
  const [selectedConfiguration, setSelectedConfiguration] = useState<ConfigurationLogic>(
    {} as ConfigurationLogic
  );
  const { enqueueSnackbar } = useSnackbar();
  const configurations = useSelector((state) => state.configurations.configurations);
  const selectedCustomAction = useSelector((state) => state.aiFlows.selectedCustomAction);
  const loading = useSelector((state) => state.aiFlows.loading);
  const [customAction, setCustomAction] = useState<CustomActionLogic>(
    selectedCustomAction || ({} as CustomActionLogic)
  );

  const closeDialog = () => {
    dispatch(setSelectedCustomAction(null));
    onClose();
  };

  React.useEffect(() => {
    setCustomAction(selectedCustomAction || ({} as CustomActionLogic));
  }, [open, selectedCustomAction]);

  const handleUpsertCustomAction = async () => {
    if (customAction.id) {
      dispatch(updateCustomAction(customAction, enqueueSnackbar));
    } else {
      dispatch(addCustomAction(customAction, enqueueSnackbar));
    }
    onClose();
  };

  return (
    <Dialog sx = {{zIndex: 4500}} open={open} onClose={closeDialog}>
      <DialogTitle>Add New Action</DialogTitle>
      <DialogContent>
        <Stack sx = {{zIndex: 4500}} spacing={3}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={customAction?.actionName}
            onChange={(e) => setCustomAction({ ...customAction, actionName: e.target.value })}
            label="Action Name"
            type="email"
            fullWidth
            variant="standard"
          />
          <TextField
            fullWidth
            id="description"
            name="description"
            value={customAction?.description}
            onChange={(e) => setCustomAction({ ...customAction, description: e.target.value })}
            label="Description(Optional)"
            helperText="Short description about the action.This field is optional."
            multiline
            rows={1}
          />
          <ConfigurationSelect
          label="Configuration(Optional)"
            inputConfiguration={selectedConfiguration}
            onConfigurationSelect={(configId) => {
              const config = configurations.find((c) => c.id === configId);
              setSelectedConfiguration(_.cloneDeep(config) as ConfigurationLogic);
            }}
          />
          <Paper
            key=""
            variant="outlined"
            sx={{
              py: 1.5,
              mx: 1,
              display: 'flex',
              position: 'relative',
              flexDirection: 'column',
              flexGrow: 1,
              height: '100%',
            }}
          >
            <Typography variant="subtitle2" sx={{ textAlign: 'center', paddingBottom: 1 }}>
              User Prompt
            </Typography>
            <PromptEditor
              height={150}
              promptInput={customAction?.prompt as string}
              setPromptInput={(prompt: string) => {
                setCustomAction({ ...customAction, prompt });
              }}
            />
          </Paper>
          {/* <TextField
            sx={{ px: 1 }}
            fullWidth
            id="userPrompt"
            value={customAction?.prompt}
            onChange={(e) => setCustomAction({ ...customAction, prompt: e.target.value })}
            name="userPrompt"
            label="User Prompt"
            helperText="This field guide the AI's response. This field is optional."
            multiline
            rows={2}
          /> */}
          <Paper
            key=""
            variant="outlined"
            sx={{
              py: 1.5,
              mx: 1,
              display: 'flex',
              position: 'relative',
              flexDirection: 'column',
              flexGrow: 1,
              height: '100%',
            }}
          >
            <Typography variant="subtitle2" sx={{ textAlign: 'center', paddingBottom: 1 }}>
              System Prompt
            </Typography>
            <PromptEditor
              height={150}
              promptInput={customAction?.systemPrompt as string}
              setPromptInput={(prompt: string) => {
                setCustomAction({ ...customAction, systemPrompt: prompt });
              }}
            />
          </Paper>
          {/* <TextField
            sx={{ px: 1 }}
            fullWidth
            id="system"
            value={customAction?.systemPrompt}
            onChange={(e) => setCustomAction({ ...customAction, systemPrompt: e.target.value })}
            name="system"
            label="System"
            helperText="This field influences how the AI interprets and responds to your input. This field is required."
            multiline
            rows={2}
          /> */}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          disabled={
            loading ||
            !customAction.actionName ||
            !customAction.systemPrompt ||
            !customAction.prompt
          }
          onClick={handleUpsertCustomAction}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
