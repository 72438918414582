import { forwardRef } from 'react';
// icons
// @mui
import { Box, BoxProps } from '@mui/material';
//
import { IconifyProps } from './types';
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  icon: IconifyProps;
}

const Iconify = forwardRef<SVGElement, Props>(({ icon, width = 20, sx = {}, ...other }, ref) => (
  <Box ref={ref} sx={{ width, height: width, display: 'inline-flex', ...sx }} {...other}>
    <Icon icon={icon} width={width as number} />
  </Box>
));

export default Iconify;
