import _ from 'lodash';
import { useEffect, useState } from 'react';
import { RHAutocomplete } from 'src/components/hook-form/RHFAutocomplete';
import { useDispatch, useSelector } from 'src/redux/store';

export interface Props {
  handleSelectMethod: (provider: string) => void;
  selectedMethodInput?: string;
  operations?: boolean;
  maxWidth?: number;
  disabled?: boolean;
  px? : number;
}

export default function MailchimpMethodSelect({
  handleSelectMethod,
  maxWidth,
  disabled,
  operations,
  selectedMethodInput,
  px
}: Props) {
  const [selectedMethod, setSelectedMethod] = useState<any>(selectedMethodInput);
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const [allMethods, setAllMethods] = useState<any[]>([
    {
      category: 'Campaigns',
      methods: [
        {
          name: 'List all campaigns',
          description: 'Retrieve information about all campaigns.',
        },
        {
          name: 'List A/B testing campaigns',
          description: 'Retrieve information about all campaigns.',
        },
        {
          name: 'Get campaign info',
          description: 'Retrieve detailed information about a specific campaign.',
        },
        {
          name: 'Get campaign content',
          description: 'Retrieve content of specific campaign.',
        },
        // {
        //   name: 'Create campaign',
        //   description: 'Create a new campaign.',
        //   endpoint: 'POST /campaigns',
        // },
        // {
        //   name: 'Update campaign',
        //   description: 'Update settings for an existing campaign.',
        //   endpoint: 'PATCH /campaigns/{campaign_id}',
        // },
        // {
        //   name: 'Delete campaign',
        //   description: 'Delete a specific campaign.',
        //   endpoint: 'DELETE /campaigns/{campaign_id}',
        // },
      ],
    },
    {
      category: 'Contacts',
      methods: [
        {
          name: 'List all contacts',
          description: 'Get information about members in a list.',
        },
        {
          name: 'Activity',
          description: 'Get information about contacts activity.',
        },
        {
          name: 'Contact Info',
          description: 'Get detailed information about a list member.',
        },
        // TODO: Add to operations
        // {
        //   name: 'Add contact',
        //   description: 'Add a new member to a list.',
        //   endpoint: 'POST /lists/{list_id}/members',
        // },
        // {
        //   name: 'Update contact',
        //   description: 'Update a list member’s information.',
        //   endpoint: 'PATCH /lists/{list_id}/members/{subscriber_hash}',
        // },
        // {
        //   name: 'Remove contact',
        //   description: 'Remove a member from a list.',
        //   endpoint: 'DELETE /lists/{list_id}/members/{subscriber_hash}',
        // },
      ],
    },
    {
      category: 'Segments',
      methods: [
        {
          name: 'List segments',
          description: 'Get information about all segments.',
        },
        {
          name: 'Get segment info',
          description: 'Get detailed information about a specific segment.',
        },
        // {
        //   name: 'Create segment',
        //   description: 'Create a new segment.',
        //   endpoint: 'POST /lists/{list_id}/segments',
        // },
        // {
        //   name: 'Update segment',
        //   description: 'Update settings for an existing segment.',
        //   endpoint: 'PATCH /lists/{list_id}/segments/{segment_id}',
        // },
        // {
        //   name: 'Delete segment',
        //   description: 'Delete a specific segment.',
        //   endpoint: 'DELETE /lists/{list_id}/segments/{segment_id}',
        // },
      ],
    },
    {
      category: 'Reports',
      methods: [
        {
          name: 'List reports',
          description: 'Get report summaries for all campaigns.',
        },
        {
          name: 'Get report',
          description: 'Get detailed report information for a specific campaign.',
        },
        {
          name: 'Email activity',
          description:
            'Get a detailed report of email activity for a specific campaign, including opens, clicks, and bounces.',
        },
      ],
    },
  ]);

  const [operationsMethods, setOperationsMethods] = useState<any[]>([
    {
      category: 'Campaigns',
      methods: [
        {
          name: 'Create campaign',
          description: 'Create a new campaign.',
          endpoint: 'POST /campaigns',
        },
        {
          name: 'Update campaign',
          description: 'Update settings for an existing campaign.',
          endpoint: 'PATCH /campaigns/{campaign_id}',
        },
        {
          name: 'Delete campaign',
          description: 'Delete a specific campaign.',
          endpoint: 'DELETE /campaigns/{campaign_id}',
        },
      ],
    },
    {
      category: 'Contacts',
      methods: [
        {
          name: 'Add contact',
          description: 'Add a new member to a list.',
          endpoint: 'POST /lists/{list_id}/members',
        },
        {
          name: 'Update contact',
          description: 'Update a list member’s information.',
          endpoint: 'PATCH /lists/{list_id}/members/{subscriber_hash}',
        },
        {
          name: 'Remove contact',
          description: 'Remove a member from a list.',
          endpoint: 'DELETE /lists/{list_id}/members/{subscriber_hash}',
        },
      ],
    },
    {
      category: 'Segments',
      methods: [
        {
          name: 'Create segments',
          description: 'Create new segments.',
          endpoint: 'POST /lists/{list_id}/segments',
        },
        {
          name: 'Update segment',
          description: 'Update settings for an existing segment.',
          endpoint: 'PATCH /lists/{list_id}/segments/{segment_id}',
        },
        {
          name: 'Delete segment',
          description: 'Delete a specific segment.',
          endpoint: 'DELETE /lists/{list_id}/segments/{segment_id}',
        },
      ],
    },
  ]);

  // Flatten the methods into a single array for the autocomplete
  const methodOptions = (operations? operationsMethods : allMethods).flatMap((category) =>
    category.methods.map((method: any) => ({
      label: `${category.category} - ${method.name}`,
      value: method.name,
      category: category.category,
    }))
  );

  useEffect(() => {
    if (selectedMethodInput && methodOptions) {
      // dimensionsInput includes only the name, so we need to find the whole object
      const method = methodOptions.find((mo: any) => selectedMethodInput.includes(mo.value));
      if (!_.isEqual(method, selectedMethod)) {
        setSelectedMethod(method);
      }
    }
  }, [selectedMethodInput, methodOptions, selectedMethod]);

  return (
    <>
      <RHAutocomplete
        disabled = {runsMode}
        zIndex={5000}
        name="methods"
        size="small"
        label="Methods"
        groupBy={(option) => option.category}
        options={methodOptions}
        sx={{ minWidth: { md: 200 }, px }}
        value={selectedMethod}
        onChange={async (event: any, newValue: any) => {
          setSelectedMethod(newValue);
          handleSelectMethod(newValue.value);
        }}
        getOptionLabel={(option: any) => option.label}
      />
    </>
  );
}
