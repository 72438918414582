import { Dialog, DialogTitle, DialogContent, MenuItem, DialogActions, Button } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { RHSelect } from '../hook-form';
import { useEffect, useState } from 'react';
import { useSelector } from 'src/redux/store';

interface Props {
  onClose: (responseFormat?: any, speedRatio?: any) => void;
  open: boolean;
}

export default function TextToSpeechConfigDialog({ onClose, open }: Props) {
  const [responseFormat, setResponseFormat] = useState<string>('mp3');

  const [responseFormats, setResponseFormats] = useState<string[]>([
    'mp3',
    'wav',
    'opus',
    'pcm',
    'flac',
    'aac',
  ]);
  const [speedRatios, setSpeedRatios] = useState<number[]>([0.5, 1, 1.5, 2]);

  const [speedRatio, setSpeedRatio] = useState<number>(1);

  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);

  useEffect(() => {
    if (selectedItem) {
      setResponseFormat(
        selectedItem.inputData?.actionInputData?.configuration?.textToSpeechAudioConfigurations
          ?.responseFormat ?? 'mp3'
      );
      setSpeedRatio(
        selectedItem.inputData?.actionInputData?.configuration?.textToSpeechAudioConfigurations
          ?.speedRatio ?? 1
      );
    }
  }, [selectedItem]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        onClose();
      }}
      sx={{ zIndex: 2400 }}
    >
      <DialogTitle>Text To Speech Configurations</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 0.55, p: 3 }}>
          <Stack spacing={3.5}>
            <RHSelect
              zIndex={2500}
              name="responseFormat"
              label="Response Format"
              value={responseFormat}
              onChange={(e) => setResponseFormat(e.target.value)}
            >
              {responseFormats.map((rf) => (
                <MenuItem key={rf} value={rf}>
                  {rf}
                </MenuItem>
              ))}
            </RHSelect>

            <RHSelect
              zIndex={2500}
              name="speedRatio"
              label="Speed Ratio"
              sx={{ marginTop: '18px !important' }}
              value={speedRatio}
              onChange={(e) => {
                // speedRatio is a number but a float
                setSpeedRatio(parseFloat(e.target.value));
              }}
            >
              {speedRatios.map((sr) => (
                <MenuItem key={sr} value={sr}>
                  {sr}
                </MenuItem>
              ))}
            </RHSelect>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" color="inherit" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={() => onClose(responseFormat, speedRatio)}>
            Confirm
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
