import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { GeneratedContentFormProps, VoteState } from 'src/@types/content';
import FormProvider from 'src/components/hook-form/FormProvider';
import {useDispatch, useSelector } from 'src/redux/store';
import Iconify from 'src/components/iconify';
import { useNavigate } from 'react-router';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { DialogComponentMode } from 'src/common/constants/general.constants';
import { MiniStyledIcon, StyledIcon } from 'src/components/nav-section/mini/styles';
import { ACTION_ICONS } from 'src/common/constants/icons.constants';
import { ContentType } from 'src/common/enums/content-type.enum';

export type FormValuesProps = GeneratedContentFormProps;

type Params = {
  id: string;
  onSetDialogMode?: (mode: DialogComponentMode) => void;
};

export default function EditGeneratedContentForm({ id, onSetDialogMode }: Params) {
  const schema: any = Yup.object().shape({});
  const dispatch = useDispatch();

  const generatedContents = useSelector((state) => state.craft.crafted);
  const [generatedContent, setGeneratedContent] = useState<string>('');
  const [updatingFeedback, setUpdatingFeedback] = useState<boolean>(false);
  const [generatedAdditionalData, setGeneratedAdditionalData] = useState<any>({});
  const [regeneratingContent, setRegeneratingContent] = useState<boolean>(false);
  const [userGeneratedContent, setUserGeneratedContent] = useState<any | undefined>(
    generatedContents[ContentType.Generated].find((c) => c.id === parseInt(id, 10)) || undefined
  );
  const [generatedTextVoteState, setGeneratedTextVoteState] = useState<VoteState>(VoteState.NoVote);
  const [feedback, setFeedback] = useState<string>('');
  useEffect(() => {
    const userGeneratedContent1 = generatedContents[ContentType.Generated].find(
      (c) => c.id === parseInt(id, 10)
    );
    setUserGeneratedContent(userGeneratedContent1);
    if (userGeneratedContent && userGeneratedContent.generatedContent) {
      const generatedContent1 = (userGeneratedContent.generatedContent as string).replace(
        /<br\s*\/?>/gi,
        '\n'
      );
      setGeneratedContent(generatedContent1);
    }

    // setFeedback(userGeneratedContent1?.feedback || '');
    // setGeneratedAdditionalData(JSON.parse(userGeneratedContent1?.additionalData as string) || {});
  }, [generatedContents, id, userGeneratedContent]);
  const { enqueueSnackbar } = useSnackbar();
  const defaultValues = {
    emailTypeGroupOptions: generatedAdditionalData?.emailTypeGroupOption || '',
    emailType: generatedAdditionalData?.emailType || '',
    emailTypeSection: generatedAdditionalData?.emailTypeSection || '',
    generatedContent: userGeneratedContent?.generatedContent || '',
  };
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const handleRegenerateContent = async () => {
    setRegeneratingContent(true);

    try {
      setRegeneratingContent(false);
      //   setGeneratedContent(regeneratedContentResponse.data.content as string);
      enqueueSnackbar('Content was regenerated succesfully', { variant: 'success' });
      // const result = await craftApi.apiContentGeneratedContentFeedbackPut({
      //   id: parseInt(id, 10),
      //   feedback,
      //   voteState: generatedTextVoteState,
      // });
    } catch (e) {
      setRegeneratingContent(false);
    }
  };

  const nav = useNavigate();
  const onBackStep = () => {
    if (onSetDialogMode) {
      onSetDialogMode(DialogComponentMode.View);
    } else {
      nav(-1);
    }
  };

  const onSubmit = async (data: FormValuesProps) => {
    setUpdatingFeedback(true);
    // const result = await craftApi.apiContentGeneratedContentFeedbackPut({
    //   id: parseInt(id, 10),
    //   feedback,
    //   voteState: generatedTextVoteState,
    // });
    enqueueSnackbar('Feedback was updated succesfully', { variant: 'success' });
    setUpdatingFeedback(false);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ overflow: 'hidden' }} spacing={3}>
        <Grid item xs={12} md={8} xl={9}>
          <Card sx={{ p: 3, minHeight: 200 }}>
            <Stack spacing={3}>
              <Button
                size="small"
                color="inherit"
                sx={{ maxWidth: 100, justifyContent: 'flex-start' }}
                onClick={onBackStep}
                startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
              >
                Back
              </Button>
              <Stack spacing={2} justifyContent="start" alignItems="center" direction="row">
                {/* <Stack spacing={1} justifyContent="start" direction="row">
                  <IconButton
                    onClick={() => {
                      if (generatedTextVoteState === VoteState.ThumbsUp) {
                        setGeneratedTextVoteState(VoteState.NoVote);
                      } else {
                        setGeneratedTextVoteState(VoteState.ThumbsUp);
                      }
                    }}
                    size="small"
                    color="inherit"
                    sx={{ color: 'text.secondary', height: 40 }}
                  >
                    <Iconify
                      icon={
                        generatedTextVoteState === VoteState.ThumbsUp
                          ? 'mdi:like'
                          : 'mdi:like-outline'
                      }
                      width={24}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      if (generatedTextVoteState === VoteState.ThumbsDown) {
                        setGeneratedTextVoteState(VoteState.NoVote);
                      } else {
                        setGeneratedTextVoteState(VoteState.ThumbsDown);
                      }
                    }}
                    size="small"
                    color="inherit"
                    sx={{ color: 'text.secondary', height: 40 }}
                  >
                    <Iconify
                      icon={
                        generatedTextVoteState === VoteState.ThumbsDown
                          ? 'mdi:dislike'
                          : 'mdi:dislike-outline'
                      }
                      width={24}
                    />
                  </IconButton>
                </Stack> */}
                <TextField
                  sx={{ flexGrow: 1 }}
                  id="feedback"
                  name="feedback"
                  value={feedback || ''}
                  onChange={(e) => setFeedback(e.target.value)}
                  label="Provide feedback prompt(for better results)"
                  multiline
                  minRows={3}
                  maxRows={3}
                />
              </Stack>
              <Stack spacing={3} justifyContent="start" direction="row">
                {/* <Button
                  fullWidth
                  type="submit"
                  disabled={
                    isSubmitting ||
                    updatingFeedback ||
                    (userGeneratedContent &&
                      userGeneratedContent.feedback === feedback &&
                      userGeneratedContent.vote === generatedTextVoteState)
                  }
                  variant="outlined"
                  size="large"
                  color="inherit"
                  sx={{ maxWidth: { md: 200 }, maxHeight: { md: 40 } }}
                >
                  Save Feedback
                </Button> */}
                <Button
                  fullWidth
                  startIcon={<Iconify icon="ri:ai-generate" />}
                  onClick={handleRegenerateContent}
                  disabled={regeneratingContent}
                  variant="outlined"
                  size="large"
                  sx={{ maxWidth: { md: 200 }, maxHeight: { md: 40 } }}
                >
                  Regenerate
                </Button>
                <Button
                  fullWidth
                  startIcon={<StyledIcon>{ACTION_ICONS.configurations}</StyledIcon>}
                  variant="outlined"
                  size="large"
                  color="inherit"
                  onClick={() => {}}
                  sx={{ maxWidth: { md: 200 }, maxHeight: { md: 40 } }}
                >
                  Configurations
                </Button>
                {/* <Button
                  fullWidth
                  type="submit"
                  disabled
                  variant="outlined"
                  size="large"
                  color="inherit"
                  sx={{ maxWidth: { md: 200 }, maxHeight: { md: 40 } }}
                >
                  Save Content
                </Button> */}
                <Button
                  fullWidth
                  startIcon={<Iconify icon="ph:copy" />}
                  variant="outlined"
                  color="inherit"
                  size="large"
                  onClick={() => {
                    enqueueSnackbar('Generated content was copied to clipboard', {
                      variant: 'success',
                    });
                    navigator.clipboard.writeText(generatedContent);
                  }}
                  sx={{ maxWidth: { md: 200 }, maxHeight: { md: 40 } }}
                >
                  Copy
                </Button>
                <Button
                  fullWidth
                  startIcon={<Iconify icon="mdi:view-day" />}
                  variant="outlined"
                  size="large"
                  color="inherit"
                  onClick={() => {}}
                  sx={{ maxWidth: { md: 200 }, maxHeight: { md: 40 } }}
                >
                  View History
                </Button>
              </Stack>
              {regeneratingContent ? (
                <Box sx={{ mx: 3, width: '100%', overflow: 'auto', height: 300 }}>
                  <Skeleton variant="rectangular" width="100%" height="100%" />
                </Box>
              ) : (
                <TextField
                  sx={{ flexGrow: 1 }}
                  id="generated"
                  name="generated"
                  label="Generated"
                  value={generatedContent || ''}
                  multiline
                  minRows="14"
                  maxRows="16"
                />
              )}
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} xl={3}>
          <Card sx={{ p: 3, minHeight: 160 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Industry
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary', fontWeight: 'bold' }}
                    >
                      {generatedAdditionalData?.emailTypeGroupOption || ''}
                    </Typography>
                  </Grid>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Email Type
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary', fontWeight: 'bold' }}
                    >
                      {generatedAdditionalData?.emailType || ''}
                    </Typography>
                  </Grid>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Section
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary', fontWeight: 'bold' }}
                    >
                      {generatedAdditionalData?.emailTypeSection || ''}
                    </Typography>
                  </Grid>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Date Generated
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary', fontWeight: 'bold' }}
                    >
                      {(userGeneratedContent &&
                        format(
                          new Date(userGeneratedContent?.generatedDate as Date),
                          'dd/MM/yyyy HH:mm:ss'
                        )) ||
                        ''}
                    </Typography>
                  </Grid>
                </Stack>
                {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Email Type
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Section
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Date Generated
                  </Typography> */}
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <Stack spacing={3}>
                  <Typography variant="body2" sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                    {userGeneratedContent?.emailTypeGroupOption || ''}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                    {userGeneratedContent?.emailType || ''}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                    {userGeneratedContent?.emailTypeSection || ''}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                    {(userGeneratedContent &&
                      format(
                        new Date(userGeneratedContent?.generatedDate as Date),
                        'dd/MM/yyyy HH:mm:ss'
                      )) ||
                      ''}
                  </Typography>
                </Stack>
              </Grid> */}
            </Grid>

            {/* <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Industry
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary', fontWeight: 'bold' }}
                    >
                      {userGeneratedContent?.emailTypeGroupOption || ''}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Email Type
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary', fontWeight: 'bold' }}
                    >
                      {userGeneratedContent?.emailType || ''}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Section
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary', fontWeight: 'bold' }}
                    >
                      {userGeneratedContent?.emailTypeSection || ''}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Date Generated
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary', fontWeight: 'bold' }}
                    >
                      {(userGeneratedContent &&
                        format(
                          new Date(userGeneratedContent?.generatedDate as Date),
                          'dd/MM/yyyy HH:mm:ss'
                        )) ||
                        ''}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid> */}
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
