import { Button, Divider, Link, MenuItem, Paper, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PreviousActionsSelect from 'src/components/previous-actions/PreviousActionsSelect';
import { useDispatch, useSelector } from 'src/redux/store';
import FormProvider, { RHSelect } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import { updateSelectedAiFlowItem, updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useEffect, useState } from 'react';
import { TinyStyledIcon } from 'src/components/nav-section/mini/styles';
import { ACTION_ICONS } from 'src/common/constants/icons.constants';
import PromptEditor from 'src/components/prompt-editor/PromptEditor';
import ProviderSelect from 'src/components/provider-select/ProviderSelect';
import ConnectionSelect from 'src/components/connection/ConnectionSelect';
import { useBoolean } from 'src/hooks/use-boolean';
import TextToSpeechConfigDialog from 'src/components/configurations/TextToSpeechConfigDialog';

export default function AiFlowTextToSpeechActionSetup() {
  const dispatch = useDispatch();
  const defaultValues = {
    //   selectedSource: '',
  };
  const [text, setText] = useState<string>('');
  const [provider, setProvider] = useState<string>('');
  const [connection, setConnection] = useState<number>(0);

  const AiFlowReconstructSchema: any = Yup.object().shape({
    //  selectedSource: Yup.string().required('Required'),
  });

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowReconstructSchema),
    mode: 'all',
    defaultValues,
  });

  const runsMode = useSelector((state) => state.aiFlows.runsMode);

  const [selectedVoice, setSelectedVoice] = useState<string>();
  // todo: backend method to get all of the properties for a given source and put it in the store
  const openAiVoices = [
    { voice: 'alloy', description: 'Neutral, professional, and clear' },
    { voice: 'echo', description: 'Warm, friendly, and engaging' },
    { voice: 'fable', description: 'Energetic, expressive, and engaging' },
    { voice: 'onyx', description: 'Older, mature, and experienced' },
    { voice: 'nova', description: 'Young, energetic, and engaging' },
    { voice: 'shimmer', description: 'Lively, vibrant, and dynamic' },
  ];
  const textToSpeechConfigurations = useBoolean(false);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  useEffect(() => {
    if (selectedItem) {
      setText(
        selectedItem.inputData?.actionInputData?.configuration?.textToSpeechAudioConfigurations
          ?.text ?? ''
      );
      setProvider(selectedItem.inputData?.actionInputData?.configuration?.modelProvider ?? '');
      setSelectedVoice(
        selectedItem.inputData?.actionInputData?.configuration?.textToSpeechAudioConfigurations
          ?.voice ?? ''
      );
      setConnection(selectedItem.inputData?.actionInputData?.configuration?.connectionId ?? 0);
    }
  }, [selectedItem, methods]);

  return (
    <FormProvider methods={methods}>
      <Stack spacing={3}>
        <ProviderSelect
          px={1}
          specificGroups={['large language models']}
          disabled={runsMode}
          selectedProviderInput={provider || ''}
          handleSelectProvider={(sp: any) => {
            setProvider(sp);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  configuration: {
                    ...selectedItem?.inputData?.actionInputData?.configuration,
                    modelProvider: sp,
                  },
                },
              },
            };
            dispatch(updateTempSelectedAiFlowItem(updatedItem));
          }}
        />
        <ConnectionSelect
          px={1}
          disabled={runsMode}
          selectedIntegration={provider}
          selectedConnectionInput={connection}
          onConnectionSelect={(connectionId: number) => {
            setConnection(connectionId);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  configuration: {
                    ...selectedItem?.inputData?.actionInputData?.configuration,
                    connectionId,
                  },
                },
              },
            };
            dispatch(updateTempSelectedAiFlowItem(updatedItem));
          }}
        />
        <RHSelect
          disabled={runsMode}
          name="selectProvider"
          sx={{ px: 1 }}
          label="Select Voice"
          zIndex={2500}
          value={selectedVoice || ''}
          onChange={(e) => {
            setSelectedVoice(e.target.value);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  configuration: {
                    ...selectedItem?.inputData?.actionInputData?.configuration,
                    textToSpeechAudioConfigurations: {
                      ...selectedItem?.inputData?.actionInputData?.configuration
                        ?.textToSpeechAudioConfigurations,
                      voice: e.target.value,
                    },
                  },
                },
              },
            };
            dispatch(updateTempSelectedAiFlowItem(updatedItem));
          }}
          size="small"
          InputLabelProps={{ shrink: true }}
        >
          {openAiVoices.map((voice, index) => (
            <MenuItem key={index} value={voice?.voice ?? ''}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <span>
                  {voice?.voice} ({voice?.description})
                </span>
              </Stack>
            </MenuItem>
          ))}
        </RHSelect>
        <Paper
          key=""
          variant="outlined"
          sx={{
            py: 1.5,
            mx: 1,
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            flexGrow: 1,
            height: '100%',
          }}
        >
          <Stack direction="row" justifyContent="center">
            <Typography variant="subtitle2" sx={{ textAlign: 'start', paddingBottom: 1 }}>
              Text
            </Typography>
          </Stack>
          <Divider sx={{ mb: 1 }} />
          <PromptEditor
            height={180}
            promptInput={text}
            readonly={runsMode}
            setPromptInput={(prompt: string) => {
              setText(prompt);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    configuration: {
                      ...selectedItem?.inputData?.actionInputData?.configuration,
                      textToSpeechAudioConfigurations: {
                        ...selectedItem?.inputData?.actionInputData?.configuration
                          ?.textToSpeechAudioConfigurations,
                        text: prompt,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
          />
        </Paper>
        <Button
          fullWidth
          variant="outlined"
          startIcon={<TinyStyledIcon>{ACTION_ICONS.configurations}</TinyStyledIcon>}
          size="large"
          color="inherit"
          sx={{
            maxWidth: { md: 180 },
            maxHeight: { md: 40 },
          }}
          onClick={() => {
            textToSpeechConfigurations.onTrue();
            //      openEmailConfiguration.onTrue();
          }}
        >
          Configurations
        </Button>
      </Stack>
      <TextToSpeechConfigDialog
        open={textToSpeechConfigurations.value}
        onClose={(responseFmt: any, sr: any) => {
          if (responseFmt && sr) {
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  configuration: {
                    ...selectedItem?.inputData?.actionInputData?.configuration,
                    textToSpeechAudioConfigurations: {
                      ...selectedItem?.inputData?.actionInputData?.configuration
                        ?.textToSpeechAudioConfigurations,
                      responseFormat: responseFmt ?? 'mp3',
                      speedRatio: sr ?? 1,
                    },
                  },
                },
              },
            };
            dispatch(updateTempSelectedAiFlowItem(updatedItem));
          }

          textToSpeechConfigurations.onFalse();
        }}
      />
    </FormProvider>
  );
}
