import {
  MutableRefObject,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { store, useDispatch, useSelector } from 'src/redux/store';
import { Editor } from '@tinymce/tinymce-react';
import { setSelectedTemplate } from 'src/redux/slices/campaignForms';
import ContentGenerationSettingsDialog from './settings/ContentGenerationSettingsDialog';
import { SkeletonPostItem } from 'src/components/skeleton';
import { useSnackbar } from 'src/components/snackbar';
import SaveNewTemplateDialog from 'src/pages/marketing/campaign/steps/SaveNewTemplateDialog';
import { EmailTemplateApi, TemplateApi, UserContentSnippet } from 'src/api';
import {
  addEmailTemplate,
  fetchEmailProvidersTemplates,
  getEmailProviderTemplateHtml,
  updateEmailTemplate,
} from 'src/redux/slices/emailTemplates';
import RenameTemplateDialog from 'src/pages/marketing/campaign/steps/RenameTemplateDialog';
import { EmailTemplate } from 'src/@types/email-template';
import _ from 'lodash';
import { getGeneratedContentStream, pauseGeneratedContentStream } from 'src/redux/slices/craft';
import TranslationDialog from '../TranslationDialog';
import { setFullScreen } from 'src/redux/slices/editor';
import ContentManagementDialog from './ContentManagementDialog';
import SaveSnippetDialog from './SaveSnippetDialog';
import RefineDialog from './RefineDialog';
import { ContentTag } from 'src/@types/content';
import zIndex from '@mui/material/styles/zIndex';
import { Box } from '@mui/material';
import { useBoolean } from 'src/hooks/use-boolean';
import CodeEditorDialog from './CodeEditorDialog';
import pubSub from 'src/components/pub-sub/PubSubService';

export interface SmartEditorRef {
  generate: (contentTag: any, feedback?: string, prompt?: string, type?: string) => void; // replace 'any' with a more specific type if you know it
  pauseGenerate: () => void;
}

interface Props {
  inputTemplateId?: number;
  inputContent?: string;
  height?: number;
  provider?: string | null;
  toolbarType?: string;
  onEditorChange?: (content: string) => void;
}
export const SmartEditor = forwardRef((props: Props, ref) => {
  // const iconPack = {
  //   'baba': 'M512 832v64h-256v-64h64v-64h-64v-64h64v-64h-64v-64h64v-64h-128v384h192zM576 832h192v-128h-128v64h64v-64h64v-64h-64v64h-64v-64h-64v192z',
  //   'gaga': 'M576 768h64v64h-192v-64h64v256h-64v-64h192v64h-64v256z',
  //   // more icons...
  // }

  const { inputTemplateId, height, provider, toolbarType, onEditorChange, inputContent } = props;
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [templateContent, setTemplateContent] = useState<string>();
  const open = Boolean(anchorEl);

  const plugins = [
    'advlist',
    'autolink',
    'lists',
    'link',
    'image',
    'charmap',
    'preview',
    'anchor',
    'searchreplace',
    'visualblocks',
    'code',
    'fullscreen',
    'insertdatetime',
    'media',
    'table',
    'code',
    'help',
    'wordcount',
  ];

  const toolbarFullButtons = [
    'fullscreen formatpainter saveTemplate undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | code removeformat resetTemplate help',
    'generateContent | contentSnippets saveSnippet | translate rephrase shorten expand summarize | qualityControl |  snippetManagement generated |  downloadTemplate publishTemplate',
  ];

  const toolbarBasicButtons = [
    'fullscreen undo redo | formatselect bold italic backcolor alignleft aligncenter alignright alignjustify bullist numlist outdent indent',
  ];

  const toolbarMinimalButtons = [
    'fullscreen saveSnippet bold italic backcolor alignleft aligncenter alignright alignjustify bullist numlist outdent indent',
  ];

  const ideasToolbar = [
    'fullscreen saveIdea prompt formatpainter undo redo | formatselect bold italic backcolor alignleft aligncenter alignright alignjustify bullist numlist outdent indent',
  ];

  const toolbarButtons: { [type: string]: string[] } = {
    full: toolbarFullButtons,
    basic: toolbarBasicButtons,
    minimal: toolbarMinimalButtons,
    ideas: ideasToolbar,
  };

  const [toolbarButtonsType, setToolbarButtonsType] = useState<string[]>(
    toolbarButtons[toolbarType as string] ?? toolbarButtons.full
  );

  const selectedTemplateHtml = useSelector(
    (state) => state.campaignForms.selectedTemplate?.content
  );
  const contentTags = useSelector((state) => state.editor.contentTags);
  const snippetTags = useSelector((state) => state.editor.contentSnippets);
  const snippetTagsRef = useRef(snippetTags);
  const fetchingTemplateHtml = useSelector((state) => state.emailTemplates.fetching);
  const languagesLoaded = useSelector((state) => state.language.loaded);
  const [realEditor, setRealEditor] = useState<any>();
  const [contentBuffer, setContentBuffer] = useState('');
  const [editorDisabled, setEditorDisabled] = useState(false);
  const [openContentGenerationSettings, setOpenContentGenerationSettings] = useState(false);
  const [openContentManagement, setOpenContentManagement] = useState(false);
  const [openSaveTemplateDialog, setOpenSaveTemplateDialog] = useState(false);
  const [openRenameTemplateDialog, setOpenRenameTemplateDialog] = useState(false);
  const [selectedRefineAction, setSelectedRefineAction] = useState<string>('Rephrase');
  const [insertedContentTags, setInsertedContentTags] = useState<ContentTag[]>([]);
  const [selectedContentTagLabel, setSelectedContentTagLabel] = useState<any>();
  const [lastGeneratedContentTag, setLastGeneratedContentTag] = useState<any>();
  const [translationSourceText, setTranslationSourceText] = useState<string>('');
  const [selectedSnippetText, setSelectedSnippetText] = useState<string>('');
  const [additionalData, setAdditionalData] = useState<any>();
  const [editorContent, setEditorContent] = useState<string>(inputContent ?? '');
  const [openTranslation, setOpenTranslation] = useState(false);
  const [lastFeedback, setLastFeedback] = useState<string>();
  const [openSaveSnippet, setOpenSaveSnippet] = useState(false);
  const [openRefineDialog, setOpenRefineDialog] = useState(false);
  const [generatedId, setGeneratedId] = useState<number>(0);
  const [lastEditorContent, setLastEditorContent] = useState<string>();
  const [selectedEditorContent, setSelectedEditorContent] = useState<string>();
  const [generationType, setGenerationType] = useState<string>();
  const [contentSnippetsMenuItems, setContentSnippetsMenuItems] = useState<any[]>([]);
  const [editorKey, setEditorKey] = useState(1);
  const crafted = useSelector((state) => state.craft.lastCrafted);
  const templates = useSelector((state) => state.emailTemplates.templates);
  const storeSelectedTemplateId = useSelector((state) => state.campaignForms.selectedTemplate?.id);
  const insertedContentTagsRef = useRef(insertedContentTags);
  const generatedIdRef = useRef(generatedId);
  const selectedContentTagLabelRef = useRef(selectedContentTagLabel);
  const openCodeEditor = useBoolean();
  const dispatch = useDispatch();
  const editorRef = useRef<any>(null);
  const { enqueueSnackbar } = useSnackbar();

  const generateNow = async (
    contentTag?: any,
    feedbackPrompt?: string,
    prompt?: string,
    type?: string
  ) => {
    const editor = editorRef.current;
    if (contentTag) {
      const latestInsertedContentTags = insertedContentTagsRef.current;
      const latestSelectedContentTagLabelRef = selectedContentTagLabelRef.current;
      const selectedContentTag = contentTag ?? null; // latestInsertedContentTags.find((t) => t.contentTagLabel === contentTag);
      setLastGeneratedContentTag(selectedContentTag);
    }
    if (!editor) {
      return;
    }
    editor.setContent('');
    if (prompt) {
      editor.setContent('');
    }
    editor.insertContent(
      `<p id = "additional-generated-content" class = "generated-content" style="line-height: 2">`
    );

    if (feedbackPrompt) {
      setLastFeedback(_.cloneDeep(feedbackPrompt));
    }

    setGenerationType(type);

    dispatch(
      getGeneratedContentStream({
        contentTag,
        generatedIdRootParent: generatedId,
        type,
        prompt: feedbackPrompt ?? prompt,
      })
    );
  };

  useEffect(() => {
    if (inputContent) {
      setEditorContent(inputContent);
    }
  }, [inputContent]);

  useImperativeHandle(ref, () => ({
    generate: async (contentTag?: any, feedback?: string, prompt?: string, type?: string) => {
      generateNow(contentTag, feedback, prompt, type);
    },
    pauseGenerate: async (type: string) => {
      dispatch(pauseGeneratedContentStream({ generatedId }, type));
    },
  }));

  const handleCloseTranslation = () => {
    setOpenTranslation(false);
  };

  const handleOpenTranslation = () => {
    setOpenTranslation(true);
  };

  const handleOpenSaveSnippet = () => {
    setOpenSaveSnippet(true);
  };

  const handleFeedbackPopoverClose = async () => {
    setAnchorEl(null);
    const editorDocument = editorRef.current?.getDoc();
    const generatedContentEndElemenets =
      editorDocument.getElementsByClassName('generated-content-end');
    if (generatedContentEndElemenets && generatedContentEndElemenets.length > 0) {
      for (let i = 0; i < generatedContentEndElemenets.length; i++) {
        generatedContentEndElemenets[i].remove();
      }
    }
  };

  useEffect(() => {
    const unsubscribe = pubSub.subscribe('editor_focus', (data: string) => {
      if (editorRef.current) {
        const editor = editorRef.current;

        //          const id = { major: 1, minor: 1 }; // Identifier for this operation
        const text = data; // The text you want to insert

        // editor.executeEdits('', [{ range, text, forceMoveMarkers: true }]);

        // Optionally, if you also want to set this data to some state or handle it differently
        // This could be a function to update the state with the new editor content
        realEditor.focus();
        realEditor.execCommand('mceInsertContent', false, text);
      }
    });

    // Cleanup subscription on component unmount
    return () => unsubscribe();
  }, [editorRef, realEditor]);

  const handleRegenerate = async (feedback?: string) => {
    handleFeedbackPopoverClose();
    setTimeout(() => {
      generateNow(lastGeneratedContentTag, feedback, undefined, generationType);
      // generateContent(lastGeneratedContentTag, feedback);
    }, 0);
  };

  const handleDeleteContent = () => {
    handleFeedbackPopoverClose();
    const editorDocument = editorRef.current?.getDoc();
    const generatedContentElements = editorDocument.getElementsByClassName('generated-content');
    const lastGeneratedContentElement =
      generatedContentElements[generatedContentElements.length - 1];
    if (lastGeneratedContentElement) {
      lastGeneratedContentElement.remove();
    }
  };

  const handleOnReplaceTranslation = (text: string) => {
    const editorDocument = editorRef.current;
    editorDocument.selection.setContent('');
    editorDocument.insertContent(text);
  };

  const handleOpenContentGenerationSettings = () => {
    setOpenContentGenerationSettings(true);
  };

  const handleOpenContentManagement = () => {
    setOpenContentManagement(true);
  };

  const handleOpenSaveTemplate = () => {
    setOpenSaveTemplateDialog(true);
  };

  const handleOpenRenameTemplateDialog = () => {
    setOpenRenameTemplateDialog(true);
  };

  const handleCloseSaveTemplate = () => {
    setOpenSaveTemplateDialog(false);
  };

  const handleCloseSaveSnippet = () => {
    setOpenSaveSnippet(false);
  };

  const handleCloseRenameTemplate = () => {
    setOpenRenameTemplateDialog(false);
  };

  useEffect(() => {
    insertedContentTagsRef.current = insertedContentTags;
  }, [insertedContentTags]);

  useEffect(() => {
    selectedContentTagLabelRef.current = selectedContentTagLabel;
  }, [selectedContentTagLabel]);

  useEffect(() => {
    generatedIdRef.current = generatedId;
  }, [generatedId]);

  const handleCloseContentGenerationSettings = () => {
    setOpenContentGenerationSettings(false);
    setTimeout(() => {
      realEditor.focus();
    });
  };

  const handleCloseContentManagement = () => {
    setOpenContentManagement(false);
    setTimeout(() => {
      realEditor.focus();
    });
  };

  const resetEditorTemplate = (editor: any) => {
    editor.editor.setContent(selectedTemplateHtml);
    enqueueSnackbar('Template reset to original content', { variant: 'success' });
  };

  const findContentTags = (editor: any) => {
    let result = null;
    const searchRegex = /(\[\*.*?\*\])/g;
    const content = editor.getContent({ format: 'html' }); // Changed to 'html' format
    const words = [];
    let match;

    for (match = searchRegex.exec(content); match !== null; match = searchRegex.exec(content)) {
      words.push(match[1]);
    }

    if (words.length === 0) {
      return false;
    }

    const findWord = (node: any, searchTerm: any) => {
      if (node.nodeName === 'SPAN' && node.getAttribute('contenteditable') === 'false') {
        // Check for the specific span
        const textNode = node.firstChild; // Assuming the span only contains a text node
        if (textNode && textNode.nodeType === Node.TEXT_NODE) {
          const startIndex = textNode.nodeValue.toLowerCase().indexOf(searchTerm.toLowerCase());
          if (startIndex !== -1) {
            const rng = editor.dom.createRng();
            rng.setStartBefore(node); // set start before span node
            rng.setEndAfter(node); // set end after span node

            editor.selection.setRng(rng);
            editor.focus();

            return true;
          }
        }
      } else {
        for (const child of node.childNodes) {
          if (findWord(child, searchTerm)) {
            return true;
          }
        }
      }
      return false;
    };
    const wordFound = words.some((word) => {
      const found = findWord(editor.getBody(), word);
      if (found) {
        setSelectedContentTagLabel(word);
        result = word;
        return true;
      } else {
        return false;
      }
    });
    return result;
  };

  const downloadTemplateHtml = (editor: any) => {
    const content = editor.getContent();
    const blob = new Blob([content], { type: 'text/html;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const selectedTemplate = templates.find((template) => template.id === storeSelectedTemplateId);
    if (selectedTemplate) {
      link.download = `${selectedTemplate.name}.html`;
    } else {
      link.download = `template.html`;
    }
    link.click();
    URL.revokeObjectURL(url);
  };

  // useEffect(() => {
  //   if (!realEditor || !editorRef.current) {
  //     console.log('!realEditor');
  //     return;
  //   }
  //   if (generated) {
  //     console.log('generating!!!!', generated);
  //     if (generated?.contentId) {
  //       console.log('set generated id!!!', generated?.contentId);
  //       setGeneratedId(
  //         generated?.contentId
  //           ? generated?.contentId[generated?.actionType as string]
  //           : 0
  //       );
  //     }
  //     if (generated?.finishedGenerating) {
  //       setEditorDisabled(false);
  //       //   console.log("editorRef.current.ui.registry",editorRef.current.ui.registry)
  //       //   console.log("realEditor.ui.registry",realEditor.ui.registry)
  //       //  // const button = editorRef.current.ui.registry.buttons.prompt;
  //       //   console.log("button",button)

  //       //   if (button) {
  //       //     button.settings.enabled = true;
  //       //     // Refresh the UI to reflect the change
  //       //     editorRef.current.ui.registry.buttons.prompt?.active(true);
  //       // }
  //       // if (generated?.additionalData) {
  //       //   setAdditionalData(generated?.additionalData);
  //       // }
  //       // if (!generatedContents.SmartEditor?.additionalData?.feedbackToolbar) {
  //       //   return;
  //       // }
  //       console.log('generating got here start');
  //       const currentContet = editorRef.current.getContent();
  //       console.log('currentContent', currentContet);
  //       editorRef.current?.insertContent('</p>');
  //       editorRef.current?.insertContent('<div class="generated-content-end"></div>');
  //       const editorDocument = editorRef.current?.getDoc();
  //       if (editorDocument) {
  //         const extraParagraph = editorDocument
  //           .getElementById('additional-generated-content')
  //           ?.remove();
  //         const targetElements = editorDocument.getElementsByClassName(
  //           'generated-content-end'
  //         ) as any[];
  //         const generatedContentElemenets = editorDocument.getElementsByClassName(
  //           'generated-content'
  //         ) as Element[];
  //         const lastGeneratedContentElement =
  //           generatedContentElemenets[generatedContentElemenets.length - 1];
  //         const lastTargetElemenet = targetElements[targetElements.length - 1];
  //         if (lastTargetElemenet) {
  //           const iframeElement = editorRef.current?.iframeElement;
  //           if (iframeElement) {
  //             const rect = lastTargetElemenet.getBoundingClientRect();
  //             const iframeRect = iframeElement.getBoundingClientRect();
  //             const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  //             const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

  //             // Create a dummy button at the exact position of the target element
  //             const dummyButton = document.createElement('button');
  //             dummyButton.style.position = 'absolute';
  //             dummyButton.style.top = `${iframeRect.top + rect.top + scrollTop - 10}px`;
  //             dummyButton.style.left = `${iframeRect.left + rect.left + scrollLeft + 150}px`;
  //             dummyButton.style.opacity = '0';
  //             document.body.appendChild(dummyButton);
  //             setAnchorEl(dummyButton);
  //           }
  //         }
  //       }
  //       // setAnchorEl(element);
  //     } else if (generated?.lastWord) {
  //       setContentBuffer((prevContentBuffer) => prevContentBuffer + generated.lastWord);
  //     }
  //   }
  // }, [generated, realEditor, lastGeneratedContentTag]);

  useEffect(() => {
    if (!realEditor) {
      return;
    }
    const regex = /<[^>]+>/g;
    let match;
    let lastMatchIndex = 0;
    let completeTagFound = false;

    if (contentBuffer && !contentBuffer.includes('<')) {
      // realEditor.insertContent(contentBuffer);
      // i want to replace also .\n and '\"\n' with <br> how?
      // console.log('contentBuffer', {contentBuffer});
      // i want also to replace \"\n to <br><br>

      const htmlContent = contentBuffer.replace(/\n/g, '<br> ').replace(/.\n/g, '<br> ');
      //    console.log("htmlContent",{htmlContent})
      if (htmlContent.includes('<br>')) {
        // lalal
      }
      realEditor.execCommand('mceInsertContent', false, htmlContent);
      // realEditor.execCommand('mceInsertContent', false, contentBuffer);
      setContentBuffer('');
      return;
    }

    for (match = regex.exec(contentBuffer); match !== null; match = regex.exec(contentBuffer)) {
      const isClosingTag = match[0].startsWith('</');
      if (isClosingTag) {
        completeTagFound = true;
      }
      lastMatchIndex = match.index + match[0].length;
    }

    if (completeTagFound && lastMatchIndex > 0) {
      const contentToInsert = contentBuffer.slice(0, lastMatchIndex);
      // realEditor.execCommand('mceInsertContent', false, contentToInsert);
      realEditor.insertContent(contentToInsert);
      setContentBuffer(contentBuffer.slice(lastMatchIndex));
    }
  }, [contentBuffer, realEditor]);

  useEffect(() => {
    snippetTagsRef.current = snippetTags;
  }, [snippetTags]);

  useEffect(() => {
    if (provider) {
      if (templates && templates.length > 0) {
        const providerTemplatesExist = templates.filter(
          (template) => template.providerName === 'Mailchimp'
        );
        if (providerTemplatesExist.length > 0) {
          const providerTemplateHtmlExist = providerTemplatesExist.find(
            (template) => template.id === inputTemplateId
          );
          if (providerTemplateHtmlExist && !providerTemplateHtmlExist.content) {
            dispatch(getEmailProviderTemplateHtml(provider as string, inputTemplateId as number));
          }
        }
      } else {
        dispatch(fetchEmailProvidersTemplates('Mailchimp'));
      }
    }
  }, [inputTemplateId, provider, dispatch, templates]);

  useEffect(() => {
    setTemplateContent('');
    if (selectedTemplateHtml) {
      setTemplateContent(selectedTemplateHtml);
    }
    if (storeSelectedTemplateId) {
      setTemplateContent(templates.find((t) => t.id === storeSelectedTemplateId)?.content);
    }
    if (inputTemplateId) {
      setTemplateContent(templates.find((t) => t.id === inputTemplateId)?.content);
    }
  }, [selectedTemplateHtml, inputTemplateId, templates, storeSelectedTemplateId, dispatch]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(setSelectedTemplate(null));
    };
  }, [dispatch]);

  const generateContent = async (contentTag?: any, feedback?: string, type?: string) => {
    const editor = editorRef.current;
    const foundContetTagLabel = contentTag ? contentTag.contentTagLabel : findContentTags(editor);
    if (!foundContetTagLabel) {
      return;
    }
    if (foundContetTagLabel) {
      editor.selection.setContent('');
      editor.insertContent(
        `<p id = "additional-generated-content" class = "generated-content" style="line-height: 2">`
      );
    }

    const latestInsertedContentTags = insertedContentTagsRef.current;
    const latestSelectedContentTagLabelRef = selectedContentTagLabelRef.current;
    const selectedContentTag = null;
    // contentTag ??
    // latestInsertedContentTags.find((t) => t.contentTagLabel === foundContetTagLabel);
    setLastGeneratedContentTag(selectedContentTag);
    if (feedback) {
      setLastFeedback(_.cloneDeep(feedback));
    }
    dispatch(
      getGeneratedContentStream({
        type,
        contentTag: selectedContentTag,
      })
      // getGeneratedContentStream(
      //   'SmartEditor',
      //   selectedContentTag,
      //   feedback ? { feedbacks: [{ feedbackContent: feedback }] } : {}
      // )
    );
  };

  const handleSaveToMyTemplates = async () => {
    if (!editorRef.current) {
      enqueueSnackbar('Error saving template(!editorRef)', { variant: 'error' });
      return;
    }
    const editor = editorRef.current;
    setEditorDisabled(true);
    const templateApi = new EmailTemplateApi();
    const templateId = inputTemplateId ?? storeSelectedTemplateId;

    const latestTemplates = store.getState().templates.templates as EmailTemplate[];
    const findTemplate = latestTemplates.find((template) => template.id === templateId);
    if (!findTemplate) {
      enqueueSnackbar('Error saving template(find template)', { variant: 'error' });
      setEditorDisabled(false);
      return;
    }
    const toSaveTemplate = {
      ...findTemplate,
    };

    const toSaveTemplateType = toSaveTemplate.type;
    toSaveTemplate.content = editor.getContent();
    let savedTemplateResponse;
    try {
      savedTemplateResponse = await templateApi.apiEmailTemplateSaveTemplatePut({
        template: toSaveTemplate,
      });
    } catch (e) {
      enqueueSnackbar('Error saving template', { variant: 'error' });
      setEditorDisabled(false);
      return;
    }

    setEditorDisabled(false);
    if (savedTemplateResponse) {
      enqueueSnackbar('Template saved', { variant: 'success' });
      if (toSaveTemplateType === 'Imported') {
        dispatch(addEmailTemplate(savedTemplateResponse.data));
      } else {
        dispatch(updateEmailTemplate(savedTemplateResponse.data));
        //    dispatch(setSelectedTemplate({...savedTemplateResponse}))
      }
    } else {
      enqueueSnackbar('Error saving template', { variant: 'error' });
    }
  };

  const [nestedItems, setNestedItems] = useState([
    {
      text: 'Nested item 1',
    },
    {
      text: 'Nested item 2',
    },
  ]);

  const buildNestedItems = () => {
    const newItems = [];
    for (let i = 0; i < 2; i++) {
      newItems.push({
        text: `New nested item ${i + 1}`,
      });
    }

    return [...nestedItems, ...newItems];
  };

  const handleButtonClick = () => {
    // setNestedItems(buildNestedItems());
    editorRef.current.save();
    const con = editorRef.current.getContent();
    console.log('con', con);
  };

  const handleEditorChange = (content: any, editor: any) => {
    console.log('Content was updated:', content);
    setEditorContent(content);
    // const txtContent1 = content
    //   .replace(/&nbsp;/g, ' ')
    //   .replace(/<br\s*\/?>/gi, '\n')
    //   .replace(/<[^>]+>/g, ''); // last 1 to replace any tags;

    // console.log('txtContent1', txtContent1);
    if (onEditorChange) {
      onEditorChange(content);
    }
    // Your logic here, such as updating the state or props
  };

  const handleFullScreenChange = (event: any) => {
    // This is where you would handle the changes to your UI based on the fullscreen state.
    dispatch(setFullScreen(event.state));
  };

  return (templateContent !== undefined && !fetchingTemplateHtml) || toolbarType === 'minimal' ? (
    <>
      {/* <meta name="viewport" content="width=device-width, initial-scale=1"/> */}
      {/* <div style={{width: '390px', maxWidth: '100%'}}> */}
      <Box sx={{ zIndex: 3600 }}>
        <Editor
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          // apiKey="n63wnnk8ckw93ycx4iwtmmeilm7bz74aev5sjcy5ae8x8nn0"
          onInit={(evt, editor) => {
            editorRef.current = editor;
          }}
          // initialValue={initialInputContent ?? lastEditorContent ?? templateContent}
          value={editorContent}
          key={editorKey}
          onEditorChange={handleEditorChange}
          disabled={editorDisabled}
          init={{
            height: height ?? 550,
            mobile: {
              toolbar_mode: 'scrolling',
            },
            // icons: iconPack,
            menu: {
              file: { title: 'File', items: 'new rename restoredraft | preview | print' },
            },
            setup: (editor) => {
              if (editor) {
                setRealEditor(editor as any);
              }
              if (lastEditorContent) {
                editor.setContent(lastEditorContent);
              }

              editor.on('FullscreenStateChanged', handleFullScreenChange);
              const editorContentTags = _.cloneDeep(contentTags) as any[];
              editorContentTags.forEach((emailTypeGroupOption) => {
                emailTypeGroupOption.emailTypes?.forEach((emailType: any) => {
                  if (emailType.sections?.length === 0) {
                    return;
                  }
                  emailType.sections?.unshift({
                    name: 'Full Email Composition',
                  });
                });
              });
              const snippetsMenuItems: any[] = snippetTags?.map((snippetTag) => ({
                type: 'menuitem',
                text: snippetTag.name,
                onAction: () => {
                  editor.insertContent(snippetTag.content as string);
                },
              })) as any[];
              setContentSnippetsMenuItems(snippetsMenuItems);
              // const emailTypeMenuItems: any[] = editorContentTags?.map((emailTypeGroupOption) => ({
              //   type: 'nestedmenuitem',
              //   text: emailTypeGroupOption.name,
              //   getSubmenuItems: () =>
              //     emailTypeGroupOption.emailTypes?.map((emailType: any) => ({
              //       type: 'nestedmenuitem',
              //       text: emailType.type,
              //       getSubmenuItems: () =>
              //         emailType.sections?.map((emailSection: any) => ({
              //           type: 'menuitem',
              //           text: emailSection.name,
              //           onAction: () => {
              //             console.log(`${emailSection.name} clicked`);
              //             const contentTagLabel = `[*${emailTypeGroupOption.name}:${emailType.type}:${emailSection.name}*]`;
              //             const contentTag = {
              //               contentTagGroup: emailTypeGroupOption.name,
              //               contentTagSubGroup: emailType.type,
              //               contentTagItem: emailSection.name,
              //               contentTagLabel,
              //             };
              //             setInsertedContentTags([...insertedContentTags, contentTag]);
              //             editor.insertContent(
              //               `<span contenteditable="false">${contentTagLabel}</span>`
              //             );
              //             // Here you can handle the action when this menu item is clicked
              //             // For instance, you can insert some predefined content to the editor related to this email section
              //             // editor.insertContent(`<p>${emailSection.name}</p>`);
              //           },
              //         })),
              //     })),
              // }));
              //   callback(emailTypeMenuItems as any[]);
              // Creating the menu button with static items
              // editor.ui.registry.addMenuButton('contentTags', {
              //   text: 'Content Tags',
              //   fetch: (callback) => callback(emailTypeMenuItems as any[]),
              // });
              editor.ui.registry.addButton('saveSnippet', {
                icon: 'saveSnippet',
                // text: 'Save As Snippet',
                tooltip: 'Save selected content as snippet',
                onAction: () => {
                  openCodeEditor.onTrue();
                  // const selectedContent = editor.selection.getContent();
                  // // .replace(/&nbsp;/g, ' ')
                  // // .replace(/<br\s*\/?>/gi, '\n')
                  // // .replace(/<[^>]+>/g, ''); // last 1 to replace any tags;
                  // console.log('selectedContent', selectedContent);
                  // if (selectedContent) {
                  //   setSelectedSnippetText(selectedContent);
                  //   handleOpenSaveSnippet();
                  // } else {
                  //   enqueueSnackbar('Please select text to save as a snippet', {
                  //     variant: 'error',
                  //   });
                  // }
                },
              });
              editor.ui.registry.addButton('saveIdea', {
                icon: 'saveIdea',
                // text: 'Save As Snippet',
                tooltip: 'Save generated idea',
                onAction: () => {},
              });
              editor.ui.registry.addButton('prompt', {
                icon: 'prompt',
                // text: 'Save As Snippet',
                tooltip: 'Iterate generated content by providing feedback prompt',
                enabled: false,
                onSetup: (buttonApi) => {
                  // Here, we'll listen to events to decide when to enable or disable the button
                  editor.on('NodeChange', (e) => {
                    if (generatedIdRef.current > 0) {
                      buttonApi.setEnabled(true);
                    }
                  });

                  // This is crucial to clean up event listeners when the button is no longer needed
                  return (x) => {
                    editor.off('NodeChange');
                  };
                },
                onAction: () => {},
              });
              editor.ui.registry.addMenuButton('contentSnippets', {
                icon: 'snippets',
                fetch: (callback) => {
                  const snippetTagItems = (
                    snippetTagsRef as MutableRefObject<UserContentSnippet[]>
                  ).current.map((snippetTag) => ({
                    type: 'menuitem',
                    text: snippetTag.name,
                    onAction: () => {
                      editor.insertContent(snippetTag.content as string);
                    },
                  })) as any[];
                  callback(snippetTagItems);
                },
              });
              editor.ui.registry.addMenuItem('new', {
                text: 'New',
                onAction: () => {
                  // Do something when the custom button is clicked
                  editor.save();
                  editor.setContent('');
                  editor.save();
                },
              });
              editor.ui.registry.addMenuItem('rename', {
                text: 'Rename',
                onAction: () => {
                  handleOpenRenameTemplateDialog();
                },
              });

              editor.ui.registry.addButton('generationSettings', {
                icon: 'preferences',
                tooltip: 'Content Generation Settings(ctrl+d)', // Add the tooltip text here
                onAction: () => {
                  handleOpenContentGenerationSettings();

                  //
                },
              });
              editor.addShortcut('ctrl+d', 'My Custom Button', () => {
                // Your custom action
                handleOpenContentGenerationSettings();
              });
              editor.ui.registry.addButton('generateContent', {
                icon: 'generateContent',
                tooltip: 'Generate Email Content', // Add the tooltip text here
                onAction: () => {
                  // initGenerateContentPopover();

                  generateContent();
                },
                // const nonEditableContent = `<span contenteditable="false">[*industries:oil*]</span>`;
                // editor.execCommand('mceInsertContent', false, nonEditableContent);
                //   handleOpenConfirm();

                //
              });
              // editor.ui.registry.addButton('generateImage', {
              //   text: 'Generate Image',
              //   tooltip: 'Generate Image', // Add the tooltip text here
              //   onAction: () => {},
              // });
              editor.ui.registry.addButton('translate', {
                icon: 'Translate',
                tooltip: 'Translate Text', // Add the tooltip text here
                onAction: () => {
                  //     const selection = editor.selection.getSel() as any;

                  const selectedContent = editor.selection
                    .getContent()
                    .replace(/&nbsp;/g, ' ')
                    .replace(/<br\s*\/?>/gi, '\n')
                    .replace(/<[^>]+>/g, ''); // last 1 to replace any tags;
                  if (selectedContent) {
                    setTranslationSourceText(selectedContent);
                    handleOpenTranslation();
                  } else {
                    enqueueSnackbar('Please select text to translate', {
                      variant: 'error',
                    });
                    // Show dialog error message if no text is selected
                  }
                  // Show dialog error message if no text is selected and if no language settings are defined
                },
              });
              editor.ui.registry.addButton('promptContent', {
                text: 'Prompt Content',
                tooltip: 'Prompt Content', // Add the tooltip text here
                onAction: () => {
                  handleOpenContentGenerationSettings();
                },
              });
              editor.ui.registry.addButton('enhanceImage', {
                text: 'Enhance Image',
                tooltip: 'Enhance Image', // Add the tooltip text here
                onAction: () => {},
              });
              editor.ui.registry.addButton('rephrase', {
                icon: 'rephrase', // You can use 'icon' instead of 'text' to display an icon
                tooltip:
                  'Rephrase: Crafts clear, engaging, and professional sentences, eliminating redundancy and elevating clarity in a snap.', // Add the tooltip text here
                onAction: () => {
                  const selectedText = editor.selection
                    .getContent()
                    .replace(/&nbsp;/g, ' ')
                    .replace(/<br\s*\/?>/gi, '\n')
                    .replace(/<[^>]+>/g, ''); // last 1 to replace any tags;
                  // if (!selectedText) {
                  //   enqueueSnackbar('Please select text to rephrase', {
                  //     variant: 'error',
                  //   });
                  //   return;
                  // }
                  // editor.selection.setContent('');
                  setSelectedRefineAction('Rephrase');
                  setSelectedEditorContent(selectedText);
                  setOpenRefineDialog(true);
                  // dispatch(
                  //   getGeneratedContentStream({
                  //     contentType: 'SmartEditor',
                  //     generationAction: {
                  //       actionType: 'Rephrase',
                  //       content: selectedText,
                  //     },
                  //   })
                  //   //        getGeneratedContentStream('SmartEditor', undefined, { })
                  // );

                  //
                },
              });
              editor.ui.registry.addButton('summarize', {
                icon: 'summarize',
                tooltip:
                  'Summarize: Simplify and streamline complex or lengthy material, making it easier to understand and recall', // Add the tooltip text here
                onAction: () => {
                  // get text only of selection
                  const selectedText = editor.selection
                    .getContent()
                    .replace(/&nbsp;/g, ' ')
                    .replace(/<br\s*\/?>/gi, '\n')
                    .replace(/<[^>]+>/g, ''); // last 1 to replace any tags;
                  setSelectedRefineAction('Summarize');
                  setSelectedEditorContent(selectedText);
                  setOpenRefineDialog(true);
                },
              });
              editor.ui.registry.addButton('expand', {
                icon: 'expand',
                tooltip:
                  'Expand: Broadening the scope or amount of information, making a text longer or more comprehensive', // Add the tooltip text here
                onAction: () => {
                  // get text only of selection
                  const selectedText = editor.selection
                    .getContent()
                    .replace(/&nbsp;/g, ' ')
                    .replace(/<br\s*\/?>/gi, '\n')
                    .replace(/<[^>]+>/g, ''); // last 1 to replace any tags;
                  setSelectedRefineAction('Expand');
                  setSelectedEditorContent(selectedText);
                  setOpenRefineDialog(true);
                },
              });
              editor.ui.registry.addButton('shorten', {
                icon: 'shorten',
                tooltip: 'shorten: ', // Add the tooltip text here
                onAction: () => {
                  // get text only of selection
                  const selectedText = editor.selection
                    .getContent()
                    .replace(/&nbsp;/g, ' ')
                    .replace(/<br\s*\/?>/gi, '\n')
                    .replace(/<[^>]+>/g, ''); // last 1 to replace any tags;
                  setSelectedRefineAction('Shorten');
                  setSelectedEditorContent(selectedText);
                  setOpenRefineDialog(true);
                },
              });
              editor.ui.registry.addButton('spamAnalysis', {
                tooltip: 'Spam Analysis', // Add the tooltip text here
                text: 'Spam Analysis',
                onAction: async () => {
                  // await handleSaveToMyTemplates();
                },
              });
              editor.ui.registry.addButton('qualityControl', {
                icon: 'qualityControl',
                tooltip: 'Quality Control',
                onAction: async () => {
                  // await handleSaveToMyTemplates();
                },
              });
              editor.ui.registry.addButton('snippetManagement', {
                tooltip: 'Snippet Management', // Add the tooltip text here
                icon: 'snippetManagement',
                onAction: async () => {
                  handleOpenContentManagement();
                },
              });
              editor.ui.registry.addButton('generated', {
                tooltip: 'Generated Content', // Add the tooltip text here
                icon: 'generated',
                onAction: async () => {
                  handleOpenContentManagement();
                },
              });
              editor.ui.registry.addButton('sentimentAnalysis', {
                tooltip: 'Sentiment Analysis', // Add the tooltip text here
                text: 'Sentiment Analysis',
                onAction: async () => {
                  // await handleSaveToMyTemplates();
                },
              });
              editor.ui.registry.addButton('saveTemplate', {
                icon: 'saveTemplate',
                tooltip: 'Save to my templates', // Add the tooltip text here
                onAction: async () => {
                  //  await handleSaveToMyTemplates();
                  handleOpenSaveTemplate();
                },
              });
              // editor.ui.registry.addButton('saveNewTemplate', {
              //   icon: 'saveNewTemplate',
              //   tooltip: 'Save new to my templates', // Add the tooltip text here
              //   onAction: () => {
              //     //            handleOpenConfirm();
              //     handleOpenSaveTemplate();
              //   },
              // });
              editor.ui.registry.addButton('downloadTemplate', {
                icon: 'download',
                tooltip: 'Download Template', // Add the tooltip text here
                onAction: () => {
                  downloadTemplateHtml(editor);
                },
              });
              editor.ui.registry.addButton('publishTemplate', {
                icon: 'publish',
                tooltip: 'Publish Template', // Add the tooltip text here
                onAction: async () => {
                  const templateApi = new EmailTemplateApi();
                  enqueueSnackbar('Publishing template', { variant: 'info' });
                  const toPublishTemplate = {
                    ...(storeSelectedTemplateId
                      ? templates.find((template) => template.id === storeSelectedTemplateId)
                      : undefined),
                  };
                  if (!toPublishTemplate) {
                    enqueueSnackbar('Error publishing template', { variant: 'error' });
                    return;
                  }
                  toPublishTemplate.content = editor.getContent();
                  // await templateApi.apiEmailTemplatePublishTemplatePost({
                  //   template: toPublishTemplate,
                  //   providerName: 'MailChimp',
                  // });
                  enqueueSnackbar('Template was published!', { variant: 'success' });
                },
              });
              editor.ui.registry.addButton('evaluateTemplate', {
                icon: 'sharpen',
                tooltip: 'Evaluate & Optimize Template', // Add the tooltip text here
                onAction: () => {
                  handleOpenContentGenerationSettings();
                },
              });
              editor.ui.registry.addButton('resetTemplate', {
                icon: 'reset',
                tooltip: 'Reset Template', // Add the tooltip text here
                onAction: () => {
                  resetEditorTemplate(editor);
                },
              });
            },
            menubar:
              toolbarType === 'minimal'
                ? false
                : 'file edit view insert format tools table tc help',
            plugins,
            toolbar: toolbarButtonsType,
            toolbar_mode: 'sliding',
            visual: false,
            mergetags_prefix: '{*',
            mergetags_suffix: '*}',
            mergetags_list: [
              {
                title: 'Client',
                menu: [
                  {
                    value: 'Client.LastCallDate',
                    title: 'Call date',
                  },
                  {
                    value: 'Client.Name',
                    title: 'Client name',
                  },
                ],
              },
              {
                title: 'Proposal',
                menu: [
                  {
                    value: 'Proposal.SubmissionDate',
                    title: 'Submission date',
                  },
                ],
              },
              {
                value: 'Consultant',
                title: 'Consultant',
              },
              {
                value: 'Salutation',
                title: 'Salutation',
              },
            ],
            content_style:
              // '.tox .tox-dialog-wrap { z-index: 4050; } ' +
              // '.tox-dialog { z-index: 4050; } ' +
              // '..tox-tinymce-aux { z-index: 4050; } ' +
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;z-index: 3500; }',
          }}
        />
      </Box>
      <ContentGenerationSettingsDialog
        onClose={handleCloseContentGenerationSettings}
        open={openContentGenerationSettings}
      />
      <ContentManagementDialog
        onClose={handleCloseContentManagement}
        open={openContentManagement}
      />
      <SaveNewTemplateDialog onClose={handleCloseSaveTemplate} open={openSaveTemplateDialog} />
      <RenameTemplateDialog onClose={handleCloseRenameTemplate} open={openRenameTemplateDialog} />
      <TranslationDialog
        sourceText={translationSourceText}
        onReplace={handleOnReplaceTranslation}
        open={openTranslation}
        onClose={handleCloseTranslation}
      />
      <SaveSnippetDialog
        snippetHtml={selectedSnippetText}
        open={openSaveSnippet}
        onClose={handleCloseSaveSnippet}
      />
      <CodeEditorDialog
        onConfirm={(content: string) => {
          editorRef.current.setContent(content);
          //   editorRef.current.insertContent(content);
        }}
        content={editorContent as string}
        open={openCodeEditor.value}
        onClose={openCodeEditor.onFalse}
      />
      <RefineDialog
        selectedRefineAction={selectedRefineAction}
        selectedEditorContent={selectedEditorContent}
        open={openRefineDialog}
        onClose={() => setOpenRefineDialog(false)}
      />
      {/* </div> */}
    </>
  ) : (
    <SkeletonPostItem height={height} />
  );
});
