import { Drawer, Typography, Divider, Tab, Tabs, Box, Button, Badge } from '@mui/material';
import { Stack } from '@mui/system';
import {
  updateTempSelectedAiFlowItem,
  saveAiFlow,
  saveAiFlowItem,
  selectAiFlowItemBySequence,
  setTestActionResult,
  testAction,
  updateSelectedAiFlowItem,
} from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';
import AiFlowItemOutputView from '../sidebar/item-output-view/AiFlowItemOutputView';
import AiFlowItemSetup from '../sidebar/item-setup/AiFlowItemSetup';
import { AiFlowItemType } from '../enums/AiFlowItemType.enum';
import { useEffect, useRef, useState } from 'react';
import TabPanel from 'src/components/tabs/TabsPanel';
import { useSnackbar } from 'src/components/snackbar';
import { AiFlowItemLogic, AiFlowLogic, TestActionRequest } from 'src/api';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import OutputCard from 'src/components/output/OutputCard';
import Iconify from 'src/components/iconify';
import { containsVariables } from 'src/utils/generatedUtils';
import {
  checkAiFlowItemHasSource,
  updateAiFlowItemWithUpdatedSource,
} from 'src/utils/aiFlowsUtils';
import { IconButtonAnimate } from 'src/components/animate';
import AiFlowItemNotesPopover from 'src/components/popovers/AiFlowItemNotesPopover';

export default function AiFlowSidebarDrawer() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const tempSelectedAiFlowItem = useSelector((state) => state.aiFlows.tempAiFlowItem);
  const lastSavedAiFlow = useSelector((state) => state.aiFlows.lastSavedSelectedAiFlow);
  const isAiFlowDirty = useSelector((state) => state.aiFlows.isDirty);
  const [sidebarRunTabIndex, setSidebarRunTabIndex] = useState(1);
  const [lastSelectedItem, setLastSelectedItem] = useState<AiFlowItemLogic>();
  const [selectedItemLabel, setSelectedItemLabel] = useState<string>('');
  const actionGroups = useSelector((state) => state.aiFlows.actionGroups);
  const testingAction = useSelector((state) => state.aiFlows.testingAction);
  const testActionResult = useSelector((state) => state.aiFlows.testActionResult);
  const actionValidated = useSelector((state) => state.aiFlows.actionValidated);
  const SPACING = 2.5;
  const testRef = useRef<any>();
  useEffect(() => {
    if (selectedItem) {
      dispatch(updateTempSelectedAiFlowItem(selectedItem));
      console.log('selectedItem', selectedItem);
      if (
        selectedItem.header !== lastSelectedItem?.header ||
        (selectedItem.header === lastSelectedItem?.header &&
          selectedItem.sequence !== lastSelectedItem?.sequence)
      ) {
        setLastSelectedItem(_.cloneDeep(selectedItem));
        const selectedItemHeader = selectedItem.header;

        const tmpActionGroup = actionGroups.find(
          (group) =>
            group.actions &&
            group.actions?.find(
              (groupAction) => groupAction && groupAction.header === selectedItemHeader
            ) !== undefined
        );
        const tmpAction = tmpActionGroup?.actions?.find((a) => a.header === selectedItemHeader);
        setSelectedItemLabel(
          `${selectedItem.sequence}. ${tmpActionGroup?.label} ${tmpAction?.actionLabel}`
        );
      }
    }
  }, [selectedItem, actionGroups, lastSelectedItem, dispatch]);

  const itemNameAlreadyExist = (name: string) => {
    if (!selectedItem?.id) {
      return false;
    }
    const existingItem = selectedAiFlow?.items
      ?.filter((i) => i.aiFlowVersionId === selectedItem?.aiFlowVersionId)
      .find(
        (item) =>
          item.name === name &&
          item.id !== selectedItem?.id &&
          item.sequence !== selectedItem?.sequence
      );
    return !!existingItem;
  };
  return (
    <>
      {/* {!open && <ToggleButton open={open} notDefault={notDefault} onToggle={handleToggle} />} */}

      <Drawer
        anchor="right"
        sx={{ zIndex: 2400 }}
        open={!!selectedItem}
        onClose={(event: any) => {
          if (
            !runsMode &&
            (!selectedItem?.name || itemNameAlreadyExist(selectedItem?.name) || !actionValidated)
          ) {
            event.stopPropagation();
            return;
          }
          if (
            tempSelectedAiFlowItem?.id === selectedItem?.id &&
            !_.isEqual(tempSelectedAiFlowItem, selectedItem)
          ) {
            debugger;
            dispatch(saveAiFlowItem(tempSelectedAiFlowItem as AiFlowItemLogic));
            dispatch(updateSelectedAiFlowItem(tempSelectedAiFlowItem));
          }
          // console.log('selectedItem', selectedItem);
          // console.log('lastSavedAiFlow', lastSavedAiFlow);
          // console.log('isAiFlowDirty', isAiFlowDirty);
          // console.log('actionValidated', actionValidated);
          else if (
            selectedAiFlow?.id &&
            !runsMode &&
            (isAiFlowDirty || (!_.isEqual(selectedAiFlow, lastSavedAiFlow) && actionValidated))
          ) {
            debugger;
            const lastSavedSelectedItemName = lastSavedAiFlow?.items?.find(
              (item) => item.id === selectedItem?.id
            )?.name;

            dispatch(saveAiFlowItem(selectedItem as AiFlowItemLogic));
            if (lastSavedSelectedItemName !== selectedItem?.name) {
              const nextItemsThatReferenceSelectedItem = selectedAiFlow?.items?.filter(
                (item) =>
                  item &&
                  selectedItem &&
                  (item.sequence as number) > (selectedItem.sequence as number) &&
                  checkAiFlowItemHasSource(item, lastSavedSelectedItemName as string)
              );
              if (
                nextItemsThatReferenceSelectedItem &&
                nextItemsThatReferenceSelectedItem.length > 0
              ) {
                nextItemsThatReferenceSelectedItem?.forEach((item) => {
                  updateAiFlowItemWithUpdatedSource(
                    lastSavedSelectedItemName as string,
                    selectedItem?.name as string,
                    item
                  );
                });
                dispatch(saveAiFlow(selectedAiFlow as AiFlowLogic, enqueueSnackbar));
              }
            }

            // dispatch(saveAiFlow(selectedAiFlow as AiFlowLogic, enqueueSnackbar));
          }
          dispatch(selectAiFlowItemBySequence(null));
          dispatch(setTestActionResult(null));
          setSidebarRunTabIndex(1);
        }}
        BackdropProps={{ invisible: true }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ py: 2, pr: 1, pl: SPACING, minWidth: 600, maxWidth: 600 }}
        >
          {runsMode && <Typography variant="h6">{selectedItemLabel}</Typography>}
          {!runsMode && (
            <>
              {testActionResult?.results && testActionResult?.results[0] !== undefined ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: '100%' }}
                >
                  <Button
                    size="small"
                    color="inherit"
                    disableRipple
                    sx={{ minWidth: 100, justifyContent: 'flex-start' }}
                    onClick={() => dispatch(setTestActionResult(null))}
                    startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
                  >
                    Back
                  </Button>
                  <Iconify
                    icon="mdi:success-circle-outline"
                    sx={{ color: 'primary.main', height: 24, width: 24, mr: 2 }}
                  />
                </Stack>
              ) : (
                <>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                      Action Setup
                    </Typography>
                    <AiFlowItemNotesPopover />
                    {/* <IconButtonAnimate sx={{ width: 40, height: 40 }}>
                      <Badge badgeContent={0} color="primary">
                        <Iconify icon="uil:notes" />
                      </Badge>
                    </IconButtonAnimate> */}
                  </Stack>
                  <LoadingButton
                    sx={{
                      maxHeight: { md: 40 },
                      mr: 2,
                    }}
                    loading={testingAction}
                    disabled
                    // disabled={testActionResult?.results !== undefined}
                    variant="outlined"
                    color="inherit"
                    onClick={(event) => {
                      const prompt = selectedItem?.inputData?.actionInputData
                        ?.promptInput as string;
                      const containsVars = containsVariables(prompt);
                      if (containsVars) {
                        enqueueSnackbar(
                          'You cannot test an action that has a reference to another action',
                          {
                            variant: 'warning',
                            anchorOrigin: {
                              vertical: 'top',
                              horizontal: 'center',
                            },
                            autoHideDuration: 5000,
                          }
                        );
                        return;
                      }
                      const testActionRequest: TestActionRequest = {
                        header: selectedItem?.header as string,
                        prompt: selectedItem?.inputData?.actionInputData?.promptInput as string,
                        configurations: selectedItem?.inputData?.actionInputData
                          ?.configuration as any,
                      };
                      dispatch(testAction(testActionRequest));
                    }}
                  >
                    Test Action
                  </LoadingButton>
                </>
              )}
            </>
          )}
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack sx={{ p: 3, minWidth: 600, maxWidth: 600 }}>
          {selectedItem && (
            <>
              {runsMode ? (
                <>
                  <Tabs
                    variant="fullWidth"
                    value={sidebarRunTabIndex}
                    onChange={(event: React.SyntheticEvent, newValue: number) => {
                      setSidebarRunTabIndex(newValue);
                      //
                    }}
                    sx={{ mb: 2 }}
                  >
                    <Tab label="Input" />
                    <Tab label="Output" />
                  </Tabs>
                  <TabPanel value={sidebarRunTabIndex} index={0}>
                    <AiFlowItemSetup />
                  </TabPanel>
                  <TabPanel value={sidebarRunTabIndex} index={1}>
                    <AiFlowItemOutputView />
                  </TabPanel>
                </>
              ) : (
                <>
                  {testActionResult ? (
                    <OutputCard
                      content={
                        testActionResult?.results && testActionResult?.results[0]
                          ? (testActionResult?.results[0] as string)
                          : ''
                      }
                    />
                  ) : (
                    <AiFlowItemSetup />
                  )}
                </>
              )}
            </>
          )}
        </Stack>
      </Drawer>
    </>
  );
}
