import { Box, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { ConnectionLogic, LlmApi } from 'src/api';
import { ModelType, llmModels } from 'src/common/constants/llm-models.constants';
import { RHSelect } from 'src/components/hook-form';
import { useSelector } from 'src/redux/store';

export interface Props {
  maxWidth?: number;
  marginTop?: number;
  onModeSelect: (mode: string) => void;
  selectedModelInput?: string | null | undefined;
  selectedModelProviderInput?: string | null | undefined;
  selectedConnectionInput?: number | null | undefined;
}

export default function ModelSelect({
  maxWidth,
  marginTop,
  onModeSelect,
  selectedModelInput,
  selectedConnectionInput,
  selectedModelProviderInput,
}: Props) {
  const [modelCategory, setModelCategory] = useState<string>('Generate');
  const [selectedConnection, setSelectedConnection] = useState<ConnectionLogic>();
  const connections = useSelector((state) => state.connection.connections);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const [models, setModels] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState<string>(ModelType.gpt_4_o_mini);
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  // const models = [
  //   { label: 'OpenAI gpt-3.5-turbo-16k', value: ModelType.gpt_3_5_turbo_16k },
  //   { label: 'OpenAI gpt-4-1106-preview', value: ModelType.gpt_4_1106_preview },
  //   { label: 'OpenAI gpt-4-0613', value: ModelType.gpt_4_0613 },
  //   { label: 'OpenAI gpt-4', value: ModelType.gpt_4 },
  //   { label: 'Google', value: ModelType.AIStudio },
  //   { label: 'Anthropic Claude', value: ModelType.Claude, disabled: true },
  // ];
  useEffect(() => {
    if (selectedConnectionInput) {
      const con =  connections.find((connection) => connection.id === selectedConnectionInput)
      setSelectedConnection(
        con
      );
    }
  }, [selectedConnectionInput, connections]);
  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.header === 'VisionaryAnalysis') {
        setModelCategory('Vision');
      } else if (selectedItem.header === 'GenerateImage') {
        setModelCategory('VisionGenerate');
        if (!selectedModelInput) {
          setSelectedModel(ModelType.dall_e_3);
        }
      }
    }
    const initialModels = Object.entries(llmModels)
      .filter(([company]) => company === selectedModelProviderInput)
      .flatMap(([company, modelTypes]) =>
        (modelTypes[modelCategory as keyof typeof modelTypes] as ModelType[]).map((modelType) => {
          const label = `${modelType}`;
          const modelObject: { label: string; value: ModelType; disabled?: boolean } = {
            label,
            value: modelType,
          };

          // Add specific conditions if needed, e.g., disable the 'Anthropic Claude' model
          // if (company === 'Anthropic' && modelType === ModelType.Claude) {
          //   modelObject.disabled = true;
          // }

          return modelObject;
        })
      );

    setModels(initialModels);
    if (modelCategory === 'Generate' && selectedItem?.header !== 'GenerateImage') {
      const fetchFineTunedModels = async () => {
        if (selectedItem) {
          if (selectedConnectionInput) {
            const llmApi = new LlmApi();
            try {
              setLoading(true);
              const fineTunedModels = await llmApi.apiLlmFineTuneModelsConnectionIdGet(
                selectedConnectionInput
              );
              if (fineTunedModels && fineTunedModels.data.length > 0) {
                setModels((prevModels) => [
                  ...prevModels,
                  ...fineTunedModels.data.map((model) => ({
                    label: model,
                    value: model as any,
                  })),
                ]);
              }
              setLoading(false);
            } catch (error) {
              setLoading(false);
              console.error('Error fetching fine-tuned models:', error);
            }
          }
        }
      };

      fetchFineTunedModels();
    }
  }, [
    selectedItem,
    selectedModelProviderInput,
    modelCategory,
    selectedConnectionInput,
    selectedModelInput,
  ]);

  return (
    <>
      <RHSelect
        loading={loading}
        disabled={runsMode}
        key="selectModel"
        name="selectModel"
        zIndex={3400}
        size="small"
        sx={{ maxWidth: maxWidth as number, marginTop: marginTop ? `${marginTop}px !important` : undefined }}
        label="Select Model"
        helperText="Select the model you want to use."
        value={selectedModelInput ?? selectedModel}
        onChange={(event) => {
          setSelectedModel(event.target.value);
          onModeSelect(event.target.value);
        }}
        InputLabelProps={{ shrink: true }}
        // sx={{ maxWidth: { md: 180 } }}
      >
        {models.map((model) => (
          <MenuItem
            disabled={
              (selectedConnection?.connectionName === "Kuverto" &&
                (model.value !== ModelType.gpt_4_o_mini)) ||
              model.disabled
            }
            value={model.value}
          >
            <span>{model.label}</span>
          </MenuItem>
        ))}
      </RHSelect>
    </>
  );
}
