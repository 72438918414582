import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InputCondition } from 'src/api';
import FormProvider from 'src/components/hook-form';
import { setActionValidated } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';
import * as Yup from 'yup';
import Condition from './Condition';

interface Params {
  inputConditions: InputCondition[];
  onUpdateConditions: (conditions: InputCondition[]) => void;
}

export default function ConditionForm({ inputConditions, onUpdateConditions }: Params) {
  const dispatch = useDispatch();
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const [conditions, setConditions] = useState<InputCondition[]>(
    inputConditions ?? [{ condition: 'contains' }]
  );

  useEffect(() => {
    if (inputConditions) {
      setConditions(inputConditions);
    }
  }, [inputConditions]);

  const defaultValues = {
    // branchName: branchName ?? '',
  };

  const AiFlowSchema: any = Yup.object().shape({
    // branchName: Yup.string()
    //   .required('Required')
    //   .test(
    //     'uniqueName',
    //     'Name already exists',
    //     (value: any) => !branchNameAlreadyExist(value as string)
    //   ),
  });

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowSchema),
    mode: 'all',
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    trigger,
    setValue,
  } = methods;

  const branchNameAlreadyExist = (name: string) => {
    if (!selectedItem?.id) {
      return false;
    }
    const existingItem = selectedAiFlow?.items?.find(
      (item) =>
        item.inputData?.actionInputData?.branchingInputData?.branchInputData?.branchName?.toLocaleLowerCase() ===
          name.toLocaleLowerCase() && item.id !== selectedItem?.id
    );
    return !!existingItem;
  };

  useEffect(() => {
    dispatch(setActionValidated(isValid));
  }, [isValid, dispatch]);

  return (
    <>
      <FormProvider methods={methods}>
        <Stack spacing={3} sx={{ mx: 1 }}>
          <>
            {conditions
              .filter((condition) => condition.operator === 'AND' || !condition.operator)
              .map((cond, index) => (
                <Condition
                  index={index}
                  key={index}
                  condition={cond}
                  allConditions={conditions}
                  conditions={conditions.filter(
                    (condition) => condition.operator === 'AND' || !condition.operator
                  )}
                  setConditions={(updatedContions) => {
                    // replace all the conditions with operator AND to the updatedContions
                    const newConditions = conditions.filter(
                      (condition) => condition.operator === 'OR'
                    );
                    newConditions.unshift(...updatedContions);
                    console.log('newConditions', newConditions);
                    setConditions(newConditions);
                    onUpdateConditions(newConditions);
                  }}
                />
              ))}
            <Button
              onClick={() => {
                const newConditions = [...conditions, { condition: 'contains', operator: 'AND' }];
                setConditions(newConditions);
                onUpdateConditions(newConditions);
              }}
              sx={{ marginTop: 4 }}
              variant="outlined"
            >
              + AND
            </Button>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Divider sx={{ flexGrow: 1, mb: 1, borderBottomWidth: '4px' }} />
              <Typography sx={{ mx: 2 }}>OR</Typography>
              <Divider sx={{ flexGrow: 1, mb: 1, borderBottomWidth: '4px' }} />
            </Box>
            {conditions
              .filter((condition) => condition.operator === 'OR')
              .map((cond, index) => (
                <Condition
                  index={index}
                  key={index}
                  allConditions={conditions}
                  condition={cond}
                  conditions={conditions.filter((condition) => condition.operator === 'OR')}
                  setConditions={(updatedContions) => {
                    // replace all the conditions with operator OR to the updatedContions
                    const newConditions = conditions.filter(
                      (condition) => !condition.operator || condition.operator === 'AND'
                    );
                    newConditions.push(...updatedContions);
                    setConditions(newConditions);
                    onUpdateConditions(newConditions);
                  }}
                />
              ))}

            <Button
              onClick={() => {
                const newConditions = [...conditions, { condition: 'contains', operator: 'OR' }];
                setConditions(newConditions);
                onUpdateConditions(newConditions);
              }}
              sx={{ marginTop: 4 }}
              variant="outlined"
            >
              + OR
            </Button>
          </>
        </Stack>
      </FormProvider>
    </>
  );
}
