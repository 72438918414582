import { Paper, IconButton, Typography, Divider } from '@mui/material';
import { Stack, Box } from '@mui/system';
import { InputCondition } from 'src/api';
import ConditionSelect from '../condition/ConditionSelect';
import Iconify from '../iconify';
import PreviousActionsSelect from '../previous-actions/PreviousActionsSelect';
import PromptEditor from '../prompt-editor/PromptEditor';
import PropertySelect from '../property-select/PropertySelect';

interface Params {
  condition: InputCondition;
  conditions: InputCondition[];
  allConditions: InputCondition[];
  setConditions: (conditions: InputCondition[]) => void;
  index: number;
}

export default function Condition({
  condition,
  conditions,
  allConditions,
  setConditions,
  index,
}: Params) {
  return (
    <>
      <Paper
        key=""
        variant="outlined"
        sx={{
          mx: 1,
          p: 2,
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          flexGrow: 1,
          height: '100%',
        }}
      >
        <Stack spacing={2}>
          {allConditions.length > 1 && (
            <IconButton
              onClick={() => {
                const updatedConditions = conditions.filter((_, i) => i !== index);
                setConditions(updatedConditions);
              }}
              sx={{ position: 'absolute', right: 2, top: 0 }}
            >
              <Iconify sx={{ width: 18, height: 18 }} icon="bi:trash" />
            </IconButton>
          )}
          <PreviousActionsSelect
            selectedSourceInput={conditions[index].source as string}
            onPreviousActionsSelect={(source) => {
              const updatedConditions = [
                ...conditions.slice(0, index),
                { ...conditions[index], source },
                ...conditions.slice(index + 1),
              ];

              setConditions(updatedConditions);
            }}
          />
          {false && (
            <PropertySelect
              handleSelectProperty={(property) => {
                const updatedConditions = [
                  ...conditions.slice(0, index),
                  { ...conditions[index], property },
                  ...conditions.slice(index + 1),
                ];

                setConditions(updatedConditions);
              }}
              selectedSource={conditions[index].source as string}
            />
          )}
          <ConditionSelect
            selectedConditionInput={condition.condition as string}
            handleSelectCondition={(tmpCond) => {
              const updatedConditions = [
                ...conditions.slice(0, index),
                { ...conditions[index], condition: tmpCond },
                ...conditions.slice(index + 1),
              ];
              console.log('updatedConditionszzz', updatedConditions);
              setConditions(updatedConditions);
            }}
          />
          <Paper
            key=""
            variant="outlined"
            sx={{
              py: 1,
              mx: 1,
              px: 1,
              display: 'flex',
              position: 'relative',
              flexDirection: 'column',
              flexGrow: 1,
              height: '100%',
            }}
          >
            <Typography variant="subtitle2" sx={{ textAlign: 'center', paddingBottom: 1 }}>
              Condition Value
            </Typography>

            <Divider sx={{ mb: 1 }} />
            <PromptEditor
              id={index.toString()}
              height={40}
              promptInput={conditions[index].conditionValue as string}
              setPromptInput={(prompt: string) => {
                const updatedConditions = [
                  ...conditions.slice(0, index),
                  { ...conditions[index], conditionValue: prompt },
                  ...conditions.slice(index + 1),
                ];
                setConditions(updatedConditions);
              }}
            />
          </Paper>
        </Stack>
      </Paper>
      {conditions[index + 1] && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Divider sx={{ flexGrow: 1, mb: 1 }} />
          <Typography sx={{ mx: 2 }}>{condition?.operator ?? 'AND'}</Typography>
          <Divider sx={{ flexGrow: 1, mb: 1 }} />
        </Box>
      )}
    </>
  );
}
