import { Button } from '@mui/material';
import { useBoolean } from 'src/hooks/use-boolean';
import ConfigurationDialog from './ConfigurationDialog';
import { ConfigurationLogic } from 'src/api';
import { TinyStyledIcon } from '../nav-section/mini/styles';
import { ACTION_ICONS } from 'src/common/constants/icons.constants';

interface Props {
  type?: string;
  onConfirmConfiguration?: (configuration: ConfigurationLogic) => void;
  inputConfiguration?: ConfigurationLogic;
}
export default function ConfigurationButton({
  type,
  onConfirmConfiguration,
  inputConfiguration,
}: Props) {
  const openEmailConfiguration = useBoolean();
  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        startIcon={<TinyStyledIcon>{ACTION_ICONS.configurations}</TinyStyledIcon>}
        size="large"
        color="inherit"
        sx={{
          maxWidth: { md: 180 },
          maxHeight: { md: 40 },
        }}
        onClick={() => {
          openEmailConfiguration.onTrue();
        }}
      >
        Configurations
      </Button>
      <ConfigurationDialog
        inputConfiguration={inputConfiguration}
        onConfirmConfiguration={onConfirmConfiguration}
        open={openEmailConfiguration.value}
        onClose={openEmailConfiguration.onFalse}
      />
    </>
  );
}
