import { Stack } from "@mui/material";
import HubspotMethodSelect from "./HubSpotMethodSelect";
import { useDispatch, useSelector } from "src/redux/store";
import { updateSelectedAiFlowItem, updateTempSelectedAiFlowItem } from "src/redux/slices/aiflows";
import { useEffect, useState } from "react";

export default function AiFlowInputHubSpotActionSetup() {
  const [method, setMethod] = useState<string>();
  const dispatch = useDispatch();

  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  useEffect(() => {
    if (selectedItem?.inputData?.actionInputData?.inputData?.providerData?.method) {
      setMethod(selectedItem?.inputData?.actionInputData?.inputData?.providerData?.method);
    }
  },[selectedItem]);
  return <Stack direction="column"  spacing={3}>
    <HubspotMethodSelect
    selectedMethodInput={method}
    handleSelectMethod={(tmpMethod:string) => {
      setMethod(tmpMethod);
      const updatedItem = {
        ...selectedItem,
        inputData: {
          ...selectedItem?.inputData,
          actionInputData: {
            ...selectedItem?.inputData?.actionInputData,
            inputData: {
              ...selectedItem?.inputData?.actionInputData?.inputData,
              providerData: {
                ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
                method: tmpMethod,
              },
            },
          },
        },
      };
      dispatch(updateTempSelectedAiFlowItem(updatedItem));
    }} />
  </Stack>;
}