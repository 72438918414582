import { Stack } from '@mui/system';
import OpenAiInputMethodSelect from './OpenAiInputMethodSelect';
import { useDispatch, useSelector } from 'src/redux/store';
import { updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useEffect, useState } from 'react';

export default function AiFlowInputOpenAiActionSetup() {
  const dispatch = useDispatch();
  const [method, setMethod] = useState<string>('');
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.inputData?.actionInputData?.inputData?.providerData?.method) {
        setMethod(selectedItem.inputData?.actionInputData?.inputData?.providerData?.method);
      }
    }
  }, [selectedItem]);

  return (
    <Stack direction="column" spacing={2}>
      <OpenAiInputMethodSelect
      selectedMethodInput={method}
        handleSelectMethod={(tmpMethod: any) => {
          const updatedItem = {
            ...selectedItem,
            inputData: {
              ...selectedItem?.inputData,
              actionInputData: {
                ...selectedItem?.inputData?.actionInputData,
                inputData: {
                  ...selectedItem?.inputData?.actionInputData?.inputData,
                  providerData: {
                    ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
                    method: tmpMethod,
                  },
                },
              },
            },
          };
          dispatch(updateTempSelectedAiFlowItem(updatedItem));
        }}
      />
    </Stack>
  );
}
