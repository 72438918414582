import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'src/redux/store';
import FormProvider from 'src/components/hook-form';
import {
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import GenericSelect from 'src/common/widgets/GenericSelect';
import { updateSelectedAiFlowItem, updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import PromptEditor from 'src/components/prompt-editor/PromptEditor';
import Iconify from 'src/components/iconify';

export default function AiFlowHttpActionSetup() {
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const [httpAction, setHttpAction] = useState<string>('GET');
  const [url, setUrl] = useState<string>();
  const [authorization, setAuthorization] = useState<boolean>();
  const [authorizationType, setAuthorizationType] = useState<string>('BearerToken');
  const [bearerToken, setBearerToken] = useState<string>();
  const [apiKey, setApiKey] = useState<string>();
  const [apiKeyValue, setApiKeyValue] = useState<string>();
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const [queryParams, setQueryParams] = useState<{ [key: string]: string }>({});
  const [body, setBody] = useState<string | null>();

  

  const [httpActionOptions, setHttpActionOptions] = useState([
    { label: 'GET', value: 'GET' },
    { label: 'POST', value: 'POST' },
    { label: 'PUT', value: 'PUT' },
    { label: 'DELETE', value: 'DELETE' },
  ]);

  const [authorizationTypes, setAuthorizationTypes] = useState([
    { label: 'Bearer Token', value: 'BearerToken' },
    { label: 'API Key', value: 'ApiKey' },
  ]);

  const AiFlowHttpActionSetupSchema: any = Yup.object().shape({
    // reciepients: Yup.string().required('Required'),
    // subjectLine: Yup.string().required('Required'),
    // body: Yup.string().required('Required'),
  });

  const defaultValues = {
    httpAction: 'GET',
    url: '',
    queryParams: '',
    body: '',
  };

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowHttpActionSetupSchema),
    mode: 'all',
    defaultValues,
  });

  const dispatch = useDispatch();
  const {
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (selectedItem) {
      setHttpAction(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.httpInput?.httpAction ??
          'GET'
      );
      setUrl(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.httpInput?.url ?? ''
      );
      setAuthorization(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.httpInput
          ?.authorization ?? false
      );
      setAuthorizationType(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.httpInput
          ?.authorizationData?.type ?? 'BearerToken'
      );
      setBearerToken(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.httpInput
          ?.authorizationData?.bearerToken as any
      );
      setApiKey(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.httpInput
          ?.authorizationData?.apiKey ?? ''
      );
      setApiKeyValue(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.httpInput
          ?.authorizationData?.apiKeyValue ?? ''
      );
      setQueryParams(
        selectedItem?.inputData?.actionInputData?.communicationInputData?.httpInput?.queryParams ??
          {}
      );
      setBody(selectedItem?.inputData?.actionInputData?.communicationInputData?.httpInput?.body);
    }
  }, [selectedItem]);

  return (
    <>
      <FormProvider methods={methods}>
        <Stack spacing={3}>
          <GenericSelect
            disabled={runsMode}
            key="httpAction"
            selectedInput={httpAction}
            label="Select Http Action"
            name="httpAction"
            options={httpActionOptions}
            onSelect={(selectedHttpAction: string) => {
              setHttpAction(selectedHttpAction);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    communicationInputData: {
                      ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                      httpInput: {
                        ...selectedItem?.inputData?.actionInputData?.communicationInputData
                          ?.httpInput,
                        httpAction: selectedHttpAction,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
          />

          <TextField
            size="small"
            fullWidth
            disabled={runsMode}
            onChange={(event) => {
              setUrl(event.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    communicationInputData: {
                      ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                      httpInput: {
                        ...selectedItem?.inputData?.actionInputData?.communicationInputData
                          ?.httpInput,
                        url: event.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
            value={url}
            placeholder="URL"
            label="URL"
          />
          <FormControlLabel
            sx={{ mb: 2 }}
            control={
              <Switch
                onChange={(changed: any) => {
                  setAuthorization(changed.target.checked);
                }}
                value={authorization}
                checked={authorization}
              />
            }
            label="Authorization"
          />
          {authorization && (
            <>
              <GenericSelect
                disabled={runsMode}
                key="authorizationType"
                selectedInput={authorizationType}
                label="Select Authorization Type"
                name="authorizationType"
                options={authorizationTypes}
                onSelect={(selectedAuthorizationType: string) => {
                  setAuthorizationType(selectedAuthorizationType);
                  const updatedItem = {
                    ...selectedItem,
                    inputData: {
                      ...selectedItem?.inputData,
                      actionInputData: {
                        ...selectedItem?.inputData?.actionInputData,
                        communicationInputData: {
                          ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                          httpInput: {
                            ...selectedItem?.inputData?.actionInputData?.communicationInputData
                              ?.httpInput,
                            authorizationData: {
                              ...selectedItem?.inputData?.actionInputData?.communicationInputData
                                ?.httpInput?.authorizationData,
                              type: selectedAuthorizationType,
                            },
                          },
                        },
                      },
                    },
                  };
                  dispatch(updateTempSelectedAiFlowItem(updatedItem));
                }}
              />
              {authorizationType === 'BearerToken' && (
                <TextField
                  size="small"
                  fullWidth
                  onChange={(event) => {
                    setBearerToken(event.target.value);
                    const updatedItem = {
                      ...selectedItem,
                      inputData: {
                        ...selectedItem?.inputData,
                        actionInputData: {
                          ...selectedItem?.inputData?.actionInputData,
                          communicationInputData: {
                            ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                            httpInput: {
                              ...selectedItem?.inputData?.actionInputData?.communicationInputData
                                ?.httpInput,
                              authenticationData: {
                                ...selectedItem?.inputData?.actionInputData?.communicationInputData
                                  ?.httpInput?.authorizationData,
                                bearerToken: event.target.value,
                              },
                            },
                          },
                        },
                      },
                    };
                    dispatch(updateTempSelectedAiFlowItem(updatedItem));
                  }}
                  value={bearerToken}
                  placeholder="Bearer Token"
                  label="Bearer Token"
                />
              )}
              {authorizationType === 'ApiKey' && (
                <>
                  <TextField
                    size="small"
                    fullWidth
                    disabled={runsMode}
                    onChange={(event) => {
                      setApiKey(event.target.value);
                      const updatedItem = {
                        ...selectedItem,
                        inputData: {
                          ...selectedItem?.inputData,
                          actionInputData: {
                            ...selectedItem?.inputData?.actionInputData,
                            communicationInputData: {
                              ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                              httpInput: {
                                ...selectedItem?.inputData?.actionInputData?.communicationInputData
                                  ?.httpInput,
                                authenticationData: {
                                  ...selectedItem?.inputData?.actionInputData
                                    ?.communicationInputData?.httpInput?.authorizationData,
                                  apiKey: event.target.value,
                                },
                              },
                            },
                          },
                        },
                      };
                      dispatch(updateTempSelectedAiFlowItem(updatedItem));
                    }}
                    value={apiKey}
                    placeholder="API Key"
                    label="API Key"
                  />

                  <TextField
                    size="small"
                    fullWidth
                    disabled={runsMode}
                    onChange={(event) => {
                      setApiKeyValue(event.target.value);
                      const updatedItem = {
                        ...selectedItem,
                        inputData: {
                          ...selectedItem?.inputData,
                          actionInputData: {
                            ...selectedItem?.inputData?.actionInputData,
                            communicationInputData: {
                              ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                              httpInput: {
                                ...selectedItem?.inputData?.actionInputData?.communicationInputData
                                  ?.httpInput,
                                authenticationData: {
                                  ...selectedItem?.inputData?.actionInputData
                                    ?.communicationInputData?.httpInput?.authorizationData,
                                  apiKeyValue: event.target.value,
                                },
                              },
                            },
                          },
                        },
                      };
                      dispatch(updateTempSelectedAiFlowItem(updatedItem));
                    }}
                    value={apiKeyValue}
                    placeholder="API Value"
                    label="API Value"
                  />
                </>
              )}
            </>
          )}
          <Typography variant="subtitle2" sx={{ textAlign: 'center', paddingBottom: 1 }}>
            Query Params
          </Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              fullWidth
              disabled={runsMode}
              onChange={(event) => {
                setApiKey(event.target.value);
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      communicationInputData: {
                        ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                        httpInput: {
                          ...selectedItem?.inputData?.actionInputData?.communicationInputData
                            ?.httpInput,
                          authenticationData: {
                            ...selectedItem?.inputData?.actionInputData?.communicationInputData
                              ?.httpInput?.authorizationData,
                            apiKey: event.target.value,
                          },
                        },
                      },
                    },
                  },
                };
                dispatch(updateTempSelectedAiFlowItem(updatedItem));
              }}
              value={apiKey}
              placeholder="Key"
              label="Key"
            />
            <TextField
              size="small"
              fullWidth
              disabled={runsMode}
              onChange={(event) => {
                setApiKey(event.target.value);
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      communicationInputData: {
                        ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                        httpInput: {
                          ...selectedItem?.inputData?.actionInputData?.communicationInputData
                            ?.httpInput,
                        },
                      },
                    },
                  },
                };
                dispatch(updateTempSelectedAiFlowItem(updatedItem));
              }}
              // value={}
              placeholder="Value"
              label="Value"
            />
              <IconButton
              sx={{
                borderRadius: 1,
                borderColor: 'primary.main',
                borderWidth: 2,
                border: '1px solid',
              }}
              onClick={() => {
                // openSelectRunDialog.onTrue();
              }}
            >
              <Iconify sx={{ width: 18, height: 18 }} icon="material-symbols:add" />
            </IconButton>
          </Stack>
          {/* <Stack
            direction="row"
            spacing={2}
            justifyContent="cennter"
            alignContent="center"
            alignSelf="center"
          >
          
          </Stack> */}
          <Paper
            key=""
            variant="outlined"
            sx={{
              py: 1.5,
              mx: 1,
              display: 'flex',
              position: 'relative',
              flexDirection: 'column',
              flexGrow: 1,
              height: '100%',
            }}
          >
            <Typography variant="subtitle2" sx={{ textAlign: 'center', paddingBottom: 1 }}>
              Request Body
            </Typography>
            <PromptEditor
              readonly={runsMode}
              height={190}
              promptInput={body ?? ''}
              setPromptInput={(value) => {
                setBody(value);
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      communicationInputData: {
                        ...selectedItem?.inputData?.actionInputData?.communicationInputData,
                        httpInput: {
                          ...selectedItem?.inputData?.actionInputData?.communicationInputData
                            ?.httpInput,
                          body: value,
                        },
                      },
                    },
                  },
                };
                dispatch(updateTempSelectedAiFlowItem(updatedItem));
              }}
            />
          </Paper>
        </Stack>
      </FormProvider>
    </>
  );
}
