import { Dialog, DialogTitle, DialogContent, MenuItem, DialogActions, Button } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { RHSelect } from '../hook-form';
import { useEffect, useState } from 'react';
import { useSelector } from 'src/redux/store';

interface Props {
  onClose: (language?: any) => void;
  open: boolean;
}

export default function TranscribeConfigDialog({ onClose, open }: Props) {

  const [languages, setLanguages] = useState<string[]>([
    'Afrikaans',
    'Arabic',
    'Armenian',
    'Azerbaijani',
    'Belarusian',
    'Bosnian',
    'Bulgarian',
    'Catalan',
    'Chinese',
    'Croatian',
    'Czech',
    'Danish',
    'Dutch',
    'English',
    'Estonian',
    'Finnish',
    'French',
    'Galician',
    'German',
    'Greek',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Indonesian',
    'Italian',
    'Japanese',
    'Kannada',
    'Kazakh',
    'Korean',
    'Latvian',
    'Lithuanian',
    'Macedonian',
    'Malay',
    'Marathi',
    'Maori',
    'Nepali',
    'Norwegian',
    'Persian',
    'Polish',
    'Portuguese',
    'Romanian',
    'Russian',
    'Serbian',
    'Slovak',
    'Slovenian',
    'Spanish',
    'Swahili',
    'Swedish',
    'Tagalog',
    'Tamil',
    'Thai',
    'Turkish',
    'Ukrainian',
    'Urdu',
    'Vietnamese',
    'Welsh',
  ]);

  const languageMap: { [key: string]: string } = {
    Afrikaans: 'af',
    Arabic: 'ar',
    Armenian: 'hy',
    Azerbaijani: 'az',
    Belarusian: 'be',
    Bosnian: 'bs',
    Bulgarian: 'bg',
    Catalan: 'ca',
    Chinese: 'zh',
    Croatian: 'hr',
    Czech: 'cs',
    Danish: 'da',
    Dutch: 'nl',
    English: 'en',
    Estonian: 'et',
    Finnish: 'fi',
    French: 'fr',
    Galician: 'gl',
    German: 'de',
    Greek: 'el',
    Hebrew: 'he',
    Hindi: 'hi',
    Hungarian: 'hu',
    Icelandic: 'is',
    Indonesian: 'id',
    Italian: 'it',
    Japanese: 'ja',
    Kannada: 'kn',
    Kazakh: 'kk',
    Korean: 'ko',
    Latvian: 'lv',
    Lithuanian: 'lt',
    Macedonian: 'mk',
    Malay: 'ms',
    Marathi: 'mr',
    Maori: 'mi',
    Nepali: 'ne',
    Norwegian: 'no',
    Persian: 'fa',
    Polish: 'pl',
    Portuguese: 'pt',
    Romanian: 'ro',
    Russian: 'ru',
    Serbian: 'sr',
    Slovak: 'sk',
    Slovenian: 'sl',
    Spanish: 'es',
    Swahili: 'sw',
    Swedish: 'sv',
    Tagalog: 'tl',
    Tamil: 'ta',
    Thai: 'th',
    Turkish: 'tr',
    Ukrainian: 'uk',
    Urdu: 'ur',
    Vietnamese: 'vi',
    Welsh: 'cy',
  };

  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  useEffect(() => {
    if (selectedItem) {
      setSelectedLanguage(
        selectedItem.inputData?.actionInputData?.configuration?.speechToTextAudioConfigurations
          ?.language ?? 'en'
      );
    }
  }, [selectedItem]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        onClose();
      }}
      sx={{ zIndex: 2400 }}
    >
      <DialogTitle>Transcribe Configurations</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 0.55, p: 3 }}>
          <Stack spacing={3.5}>
            <RHSelect
              zIndex={2500}
              name="language"
              label="Language"
              value={Object.keys(languageMap).find((key) => languageMap[key] === selectedLanguage) || "English"}
              onChange={(e) => setSelectedLanguage(languageMap[e.target.value])}
            >
              {languages.map((language) => (
                <MenuItem key={language} value={language}>
                  {language}
                </MenuItem>
              ))}
            </RHSelect>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={() => onClose(selectedLanguage)}>
            Confirm
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
