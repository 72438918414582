import { MenuItem, Avatar } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { FailoverTypes } from 'src/@types/aiflow';
import { RHSelect } from 'src/components/hook-form/RHFSelect';
import { useSelector } from 'src/redux/store';

export interface Props {
  handleSelectFailoverType: (failOverType: FailoverTypes) => void;
  selectedFailoverTypeInput?: FailoverTypes;
  disabled?: boolean;
  px?: any;
}

export default function FailoverTypeSelect({
  handleSelectFailoverType,
  disabled,
  selectedFailoverTypeInput,
  px,
}: Props) {
  const [selectedFailoverType, setSelectedFailoverType] = useState<FailoverTypes>(
    FailoverTypes.ValidationFailure
  );
  return (
    <>
      <RHSelect
        disabled={disabled}
        name="selectFailoverType"
        sx={{ px: 1 }}
        label="Select Failover Type"
        zIndex={2500}
        value={(selectedFailoverTypeInput ?? selectedFailoverType ) || 0}
        onChange={(e) => {
            
          const ft = parseInt(e.target.value,10) as FailoverTypes;
          console.log("ft", ft);
          setSelectedFailoverType(ft);
          handleSelectFailoverType(ft);
        }}
        size="small"
        InputLabelProps={{ shrink: true }}
      >
        <MenuItem key={0} value={0}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <span>Validation Failure</span>
          </Stack>
        </MenuItem>
        <MenuItem key={1} value={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <span>Runtime Error</span>
          </Stack>
        </MenuItem>
        <MenuItem key={2} value={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <span>Validation and Runtime Erros</span>
          </Stack>
        </MenuItem>
      </RHSelect>
    </>
  );
}
