import SvgColor from 'src/components/svg-color';

const icon = (iconName: string) => (
  <SvgColor src={`/assets/icons/actions/${iconName}.svg`} sx={{ width: 1, height: 1 }} />
);

const personaIcon = (iconName: string) => (
  <SvgColor src={`/assets/icons/personas/${iconName}.svg`} sx={{ width: 1, height: 1 }} />
);

const subscriptionIcon = (iconName: string) => (
  <SvgColor src={`/assets/icons/subscription/${iconName}.svg`} sx={{ width: 1, height: 1 }} />
);

const otherIcon = (iconName: string) => (
  <SvgColor src={`/assets/icons/others/${iconName}.svg`} sx={{ width: 1, height: 1 }} />
);

const templatesIcon = (iconName: string) => (
  <SvgColor src={`/assets/icons/templates/${iconName}.svg`} sx={{ width: 1, height: 1 }} />
);

const llmProviderIcon = (iconName: string) => (
  <SvgColor src={`/assets/icons/llm_providers/${iconName}.svg`} sx={{ width: 1, height: 1 }} />
);

interface Icons {
  [key: string]: React.ReactElement;
}

export const ACTION_ICONS: Icons = {
  dripSequence: icon('drip_sequence'),
  completedRuns: icon('completed_tasks'),
  aiPersona: icon('ai_persona'),
  validation: icon('validation'),
  actions: icon('actions'),
  integrations: icon('integrations'),
  menuItem: icon('ic_menu_item'),
  enhanceImg: icon('enhance_img'),
  analyzeImage: icon('analyze_image'),
  expand: icon('expand'),
  fullEmail: icon('full_email'),
  generateEmail: icon('generate_email'),
  generateSms: icon('generate_sms'),
  sendSms: icon('send_sms'),
  sendMessage: icon('send_message'),
  campaigns: icon('campaigns'),
  generateImg: icon('generate_img'),
  analyzeContent: icon('analyze_content'),
  emailAdditionals: icon('email_additionals'),
  contentAnalysis: icon('content_analysis'),
  savePrompt: icon('save_prompt'),
  importPrompt: icon('import_prompt'),
  editInEditor: icon('edit_in_editor'),
  saveMyTemplates: icon('save_my_templates'),
  personalize: icon('personalize'),
  tailoredContent: icon('tailored_content'),
  template: icon('template'),
  prompts: icon('prompts'),
  readibilityAnalysis: icon('readibility_analysis'),
  spamAnalysis: icon('spam_analysis'),
  analysis: icon('analysis'),
  audience: icon('audience'),
  sentimentAnalysis: icon('sentiment_analysis'),
  analyzeAudience: icon('analyse_audience'),
  translate: icon('translate'),
  rephrase: icon('rephrase'),
  shorten: icon('shorten'),
  summarize: icon('summarize'),
  refine: icon('refine'),
  promptFeedback: icon('prompt_feedback'),
  contentAutomation: icon('content_automation'),
  segmentationAutomation: icon('segmentation_automation'),
  taggingAutomation: icon('tagging_automation'),
  configurations: icon('configurations'),
  saveSnippet: icon('save_snippet'),
  generatedManagement: icon('generated_management'),
  copywriting: icon('copywriting'),
  snippets: icon('snippets'),
  ideas: icon('ideas'),
  time: icon('time'),
  oneTime: icon('one_time'),
  saveIdea: icon('saveIdea'),
  saved: icon('saved'),
  aiFlows: icon('aiflows'),
  delay: icon('delay'),
  branch: icon('branch'),
  branches: icon('branches'),
  condition: icon('condition'),
  manualValidation: icon('manual_validation'),
  cta: icon('cta'),
  subject: icon('subject'),
  body: icon('body'),
  previewText: icon('preview_text'),
  importTemplate: icon('import_template'),
  exportTemplate: icon('export_template'),
  importContent: icon('import_content'),
  exportContent: icon('export_content'),
  generateReport: icon('generate_report'),
  createContact: icon('create_contact'),
  custom: icon('custom'),
  generateCustom: icon('generate_custom'),
  transcribe: icon('transcribe'),
  textAnalysis: icon('text_analysis'),
  customInput: icon('input'),
  export: icon('export'),
  generateCode: icon('generate_code'),
  refactor: icon('refactor'),
  restructure: icon('restructure'),
  http: icon('http'),
  dataAnalysis: icon('data_analysis'),
  router: icon('router'),
  repeater: icon('repeater'),
  tts: icon('tts'),
  segmentation: icon('segmentation'),
  reconstruct: icon('reconstruct'),
  extractor: icon('extractor'),
  formatter: icon('formatter'),
  scrapper: icon('scrapper'),
  prediction: icon('prediction'),
  locator: icon('locator'),
  validator: icon('validation'),
  embeddings: icon('embeddings'),
  finetune: icon('finetune'),
  operations: icon('operations'),
  generateVerification: icon('generate_verification'),
  trigger: icon('trigger'),
  paste: icon('paste'),
  runWorkflow: icon('runWorkflow'),
};

export const PERSONA_ICONS = {
  sales: personaIcon('sales'),
  analyst: personaIcon('analyst'),
  ceo: personaIcon('ceo'),
  contentCreator: personaIcon('content_creator'),
  customerService: personaIcon('customer_service'),
  manager: personaIcon('manager'),
  marketer: personaIcon('marketer'),
};

export const SUBSCRIPTION_ICONS = {
  starter: subscriptionIcon('starter'),
  professional: subscriptionIcon('professional'),
  team: subscriptionIcon('team'),
  company: subscriptionIcon('company'),
};


export const EXPRESSIONS_TEMPLATES_ICONS = {
  sales: personaIcon('sales'),
  analyst: personaIcon('analyst'),
  ceo: personaIcon('ceo'),
  contentCreator: personaIcon('content_creator'),
  customerService: personaIcon('customer_service'),
  manager: personaIcon('manager'),
  marketer: personaIcon('marketer'),
};

export const OTHER_ICONS = {
  recurringAiFlow: otherIcon('recurring_aiFlow'),
  oneTime: otherIcon('one_time'),
  aiFlowsTemplates: otherIcon('browse_flows_templates'),
  newFlow: otherIcon('new_flow'),
  brandVoiceTemplates: otherIcon('brand_voice_templates'),
  newBrandVoice: otherIcon('new_brand_voice'),
  personaTemplates: otherIcon('persona_templates'),
  newPersona: otherIcon('new_persona'),
  recurringAiFlowOff: otherIcon('recurring_aiFlow_off'),
}
export const LLM_PROVIDER_ICONS: Icons = {
  openai: llmProviderIcon('openai'),
  palm2: llmProviderIcon('palm2'),
  claude: llmProviderIcon('claude'),
}
export const TEMPLATES_ICONS = {
  abTesting: templatesIcon('ab-testing'),
  emailTemplateProofreading: templatesIcon('email-template-proofreading'),
  automatedCampaignCreation: templatesIcon('automated-campaign-creation'),
  campaignEngagementPredictionStrategy: templatesIcon('campaign-engagement-prediction-strategy'),
  campaignParametersValidation: templatesIcon('campaign-parameters-validation'),
  campaignPerformancePrediction: templatesIcon('campaign-performance-prediction'),
  campaignRefinement: templatesIcon('campaign-refinement'),
  churnPredictionRetentionStrategy: templatesIcon('churn-prediction-retention-strategy'),
  emailDeliveryPerformance: templatesIcon('email-delivery-performance'),
  emailListAccuracy: templatesIcon('email-list-accuracy'),
  emailRetargetingStrategy: templatesIcon('email-retargeting-strategy'),
  emailSequencesPerSegmentGeneration: templatesIcon('email-sequences-per-segment-generation'),
  listAndAssetValidation: templatesIcon('link-and-asset-validation'),
  listMaintenance: templatesIcon('list-maintenance'),
  personalizedEmailContentGeneration: templatesIcon('personalized-email-content-generation'),
  scheduledReporting: templatesIcon('scheduled-reporting'),
  segmentsGeneration: templatesIcon('segments-generation'),
  subjectLineGenerationPerSegment: templatesIcon('subject-line-generation-per-segment'),
  segmentsCreation: templatesIcon('segments-creation'),
  emailMarketingOptimization: templatesIcon('email-marketing-optimization'),
  emailContentCompliance: templatesIcon('email-content-compliance'),
  campaignEngagementReport: templatesIcon('campaign-engagement-report'),
  subjectLineOptimization: templatesIcon('subject-line-optimization'),
  emailBodyPersonalization: templatesIcon('email-body-personalization'),
  emailLocalizationValidation: templatesIcon('email-localization-validation'),
}