// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Box,
  Chip,
  Select,
  Checkbox,
  MenuItem,
  TextField,
  InputLabel,
  SelectProps,
  FormControl,
  OutlinedInput,
  TextFieldProps,
  FormHelperText,
  SelectChangeEvent,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import { useState } from 'react';
import { SmallActionIcon, TinyStyledIcon } from '../nav-section/mini/styles';
import { ACTION_ICONS } from 'src/common/constants/icons.constants';

// ----------------------------------------------------------------------

type RHFSelectProps = TextFieldProps & {
  name: string;
  startAdornmentIcon?: string;
  native?: boolean;
  maxHeight?: boolean | number;
  children: React.ReactNode;
  width?: number;
  loading?: boolean;
  zIndex?: number;
};

export function RHSelect({
  name,
  native,
  maxHeight = 320,
  helperText,
  error,
  startAdornmentIcon,
  loading,
  width,
  zIndex,
  children,
  ...other
}: RHFSelectProps) {
  return (
    <TextField
      select
      fullWidth
      SelectProps={{
        native,
        MenuProps: {
          style: { zIndex: zIndex ?? 'auto' },
          PaperProps: {
            sx: {
              ...(!native && {
                px: 1,

                maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                '& .MuiMenuItem-root': {
                  px: 1,

                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                },
              }),
            },
          },
        },
        sx: { textTransform: 'capitalize' },
      }}
      error={!!error}
      helperText={helperText}
      sx={{ marginTop: '10px !important', width }}
      {...other}
      InputProps={{
        endAdornment: <>{loading ? <CircularProgress color="inherit" size={20} /> : null}</>,
        startAdornment: (
          <>
            {startAdornmentIcon ? (
              <TinyStyledIcon sx = {{marginRight: 1}}>{ACTION_ICONS[startAdornmentIcon as string]}</TinyStyledIcon>
            ) : null}
          </>
        ),
      }}
    >
      {children}
    </TextField>
  );
}

export function RHFSelect({
  name,
  native,
  maxHeight = 220,
  helperText,
  zIndex,
  children,
  ...other
}: RHFSelectProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          SelectProps={{
            native,
            MenuProps: {
              style: { zIndex: zIndex ?? 'auto' },
              PaperProps: {
                sx: {
                  ...(!native && {
                    px: 1,
                    maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                    '& .MuiMenuItem-root': {
                      px: 1,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    },
                  }),
                },
              },
            },
            sx: { textTransform: 'capitalize' },
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}

// ----------------------------------------------------------------------

interface RHFMultiSelectProps {
  id?: string;
  name: string;
  sx?: any;
  label?: string;
  chip?: boolean;
  checkbox?: boolean;
  placeholder?: string;
  helperText?: React.ReactNode;
  options: {
    label: string;
    value: string;
  }[];
}

export function RHFMultiSelect({
  name,
  chip,
  label,
  options,
  checkbox,
  placeholder,
  helperText,
  sx,
  ...other
}: RHFMultiSelectProps) {
  const { control } = useFormContext();

  const renderValues = (selectedIds: string[]) => {
    const selectedItems = options.filter((item) => selectedIds.includes(item.value));

    if (!selectedItems.length && placeholder) {
      return (
        <Box component="em" sx={{ color: 'text.disabled' }}>
          {placeholder}
        </Box>
      );
    }

    if (chip) {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selectedItems.map((item) => (
            <Chip key={item.value} size="small" label={item.label} />
          ))}
        </Box>
      );
    }

    return selectedItems.map((item) => item.label).join(', ');
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl sx={sx}>
          {label && <InputLabel id={name}> {label} </InputLabel>}

          <Select
            {...field}
            multiple
            displayEmpty={!!placeholder}
            labelId={name}
            input={<OutlinedInput fullWidth label={label} error={!!error} />}
            renderValue={renderValues}
            MenuProps={{
              PaperProps: {
                sx: { px: 1, maxHeight: 280 },
              },
            }}
            {...other}
          >
            {placeholder && (
              <MenuItem
                disabled
                value=""
                sx={{
                  py: 1,
                  px: 2,
                  borderRadius: 0.75,
                  typography: 'body2',
                }}
              >
                <em> {placeholder} </em>
              </MenuItem>
            )}

            {options.map((option) => {
              const selected = field.value.includes(option.value);

              return (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    py: 1,
                    px: 2,
                    borderRadius: 0.75,
                    typography: 'body2',
                    ...(selected && {
                      fontWeight: 'fontWeightMedium',
                    }),
                    ...(checkbox && {
                      p: 0.25,
                    }),
                  }}
                >
                  {checkbox && <Checkbox disableRipple size="small" checked={selected} />}

                  {option.label}
                </MenuItem>
              );
            })}
          </Select>

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
interface RHMultiSelectProps {
  id?: string;
  name: string;
  label?: string;
  chip?: boolean;
  checkbox?: boolean;
  placeholder?: string;
  sx?: any;
  helperText?: React.ReactNode;
  options: {
    label: string;
    value: string;
  }[];
}
export function RHMultiSelect({ name, id, options, ...other }: RHMultiSelectProps) {
  const [fieldValues, setFieldValue] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof fieldValues>) => {
    const {
      target: { value },
    } = event;
    setFieldValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={fieldValues}
        name="aadd"
        label="aadd"
        onChange={handleChange}
        input={<OutlinedInput label="Tag" />}
        size="small"
        sx={{ maxWidth: { md: 300 } }}
        renderValue={(selected) => selected.join(', ')}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={fieldValues.indexOf(option.value) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
