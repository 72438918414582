export enum ModelType {
  gpt_3_5_turbo_16k = 'gpt-3.5-turbo-16k',
  gpt_3_5_turbo_0125 = 'gpt-3.5-turbo-0125',
  gpt_o1_preview = 'o1-preview',
  gpt_o1_mini = 'o1-mini',
  gpt_4 = 'gpt-4',
  gpt_4_turbo = 'gpt-4-turbo',
  gpt_4_o = 'gpt-4o',
  gpt_4_o_mini = 'gpt-4o-mini',
  gpt_vision = 'gpt-vision',
  dall_e_2 = 'dall-e-2',
  dall_e_3 = 'dall-e-3',
  gemini_vision = 'gemini-pro-vision',
  claude_vision = 'claude-vision',
  Gemini1Pro = 'Gemini 1.0 Pro',
  Gemini15Pro = 'Gemini 1.5 Pro',
  ClaudeSonnet35 = 'claude-3-5-sonnet-latest',
  ClaudeSHaiku35 = 'claude-3-5-haiku-latest',
  Claude3Haiku = 'claude-3-haiku-20240307',
  Claude3Sonnet = 'claude-3-sonnet-20240229',
  Claude3Opus = 'claude-3-opus-latest',
}

export enum FineTuneModelType {
  gpt_3_5_turbo_0125 = 'gpt-3.5-turbo-0125',
  gpt_3_5_turbo_1106 = 'gpt-3.5-turbo-1106',
  gpt_4_0613 = 'gpt-4-0613',
  gpt_4o = 'gpt-4o-2024-08-06',
  gpt_4o_mini = 'gpt-4o-mini-2024-07-18',
}

export enum EmbeddingsModelType {
  text_embedding_3_small = 'text-embedding-3-small',
  text_embedding_3_large = 'text-embedding-3-large',
  text_embedding_ada_002 = 'text-embedding-ada-002',
}

export const ModelTypeMaxTokens = {
  'gpt-3.5-turbo-16k': 16350,
  'gpt-3.5-turbo-0125': 4050,
  'gpt-4-turbo': 4050,
  'gpt-4o': 4050,
  'o1-preview': 32760,
  'o1-mini': 65540,
  'gpt-4o-mini': 16350,
  'gpt-4': 8150,
  'gpt-vision': 4050,
  'Gemini 1.0 Pro': 32750,
  'Gemini 1.5 Pro': 1050000,
  'claude-3-5-sonnet-latest': 200000,
  'claude-3-5-haiku-latest': 8192,
  'claude-3-haiku-20240307': 200000,
  'claude-3-sonnet-20240229': 200000,
  'claude-3-opus-20240229': 200000,
};

export const llmModels = {
  OpenAI: {
    Generate: [
      ModelType.gpt_4_o_mini,
      ModelType.gpt_4_o,
      ModelType.gpt_o1_mini,
      ModelType.gpt_o1_preview,
      ModelType.gpt_4_turbo,
      ModelType.gpt_4,
    ],
    Vision: [ModelType.gpt_4_o, ModelType.gpt_4_o_mini],
    VisionGenerate: [ModelType.dall_e_2, ModelType.dall_e_3],
  },
  Gemini: {
    Generate: [ModelType.Gemini1Pro, ModelType.Gemini15Pro],
    Vision: [ModelType.Gemini15Pro],
    VisionGenerate: [],
  },
  Claude: {
    Generate: [
      ModelType.ClaudeSonnet35,
      ModelType.ClaudeSHaiku35,
      ModelType.Claude3Opus,
      ModelType.Claude3Sonnet,
      ModelType.Claude3Haiku,
    ],
    Vision: [ModelType.claude_vision],
    VisionGenerate: [],
  },
};

export const modelCompanyModelNameMap = {
  OpenAI: [
    'gpt-3.5-turbo-16k',
    'gpt-3.5-turbo-0125',
    'gpt-4',
    'gpt-4-turbo',
    'gpt-4o',
    'gpt-4o-mini',
    'gpt-vision',
  ],
  Gemini: ['Gemini 1.0 Pro', 'Gemini 1.5 Pro'],
  Claude: ['Claude Haiku', 'Claude Sonnet', 'Claude Opus', 'claude-vision'],
};

export const fineTuneLlmModels = {
  OpenAI: [
    FineTuneModelType.gpt_4o_mini,
    FineTuneModelType.gpt_4o,
    FineTuneModelType.gpt_3_5_turbo_0125,
    FineTuneModelType.gpt_4_0613,
    FineTuneModelType.gpt_3_5_turbo_1106,
  ],
};

export const embeddingsLlmModels = {
  OpenAI: [
    EmbeddingsModelType.text_embedding_3_small,
    EmbeddingsModelType.text_embedding_3_large,
    EmbeddingsModelType.text_embedding_ada_002,
  ],
};

export function findIntegrationByModelType(
  modelTypeValue: string,
  type: 'Generate' | 'Vision' = 'Generate'
): string | undefined {
  const modelType = modelTypeValue as ModelType;
  for (const [integration, models] of Object.entries(llmModels)) {
    if (models[type].includes(modelType)) {
      return integration;
    }
  }
  return undefined; // Return undefined if the model type value is not found
}

export function getIntegrationAndModelDisplayName(integrationName: string): string | undefined {
  // Convert the integrationName string to the corresponding enum value
  const modelType = ModelType[integrationName as keyof typeof ModelType];
  if (!modelType) {
    return undefined; // Return undefined if the integration name is not valid
  }

  const integration = findIntegrationByModelType(modelType);
  if (integration) {
    return `${integration} ${modelType}`;
  }
  return undefined;
}
