import { Button, IconButton, Paper, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { AiFlowItemLogic } from 'src/api';
import Iconify from 'src/components/iconify';
import { useDispatch, useSelector } from 'src/redux/store';
import NewBranchDialog from './NewBranchDialog';
import { useBoolean } from 'src/hooks/use-boolean';
import {
  deleteAiFlowItems,
  selectAiFlowItem,
  updateSelectedAiFlowItem,
  updateTempSelectedAiFlowItem,
} from 'src/redux/slices/aiflows';
import ConfirmDialog from 'src/components/confirm-dialog';

export default function AiFlowBranchesActionSetup() {
  const dispatch = useDispatch();
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const openDeleteConfirm = useBoolean(false);
  const [selectedItemBranch, setSelectedItemBranch] = useState<AiFlowItemLogic | null>(null);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const [branchItems, setBranchItems] = useState<AiFlowItemLogic[]>([]);
  const [branchTitle, setBranchTitle] = useState('');
  const newBranchDialogOpen = useBoolean(false);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  useEffect(() => {
    if (selectedItem) {
      setBranchItems(
        selectedAiFlow?.items?.filter(
          (item) =>
            item?.parentSequence === selectedItem.sequence &&
            item.header === 'Branch' &&
            item.aiFlowVersionId === selectedItem?.aiFlowVersionId
        ) ?? []
      );
      setBranchTitle(
        selectedItem?.inputData?.actionInputData?.branchingInputData?.branchesInputData
          ?.title ?? ''
      );
    }
  }, [selectedItem, selectedAiFlow]);

  return (
    <>
      <Stack spacing={2} sx={{ mt: 2, mx: 1 }}>
        <TextField
          fullWidth
          label="Title(Optional)"
          value={branchTitle}
          onChange={(e) => {
            setBranchTitle(e.target.value);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  branchingInputData: {
                    ...selectedItem?.inputData?.actionInputData?.branchingInputData,
                    branchesInputData: {
                      ...selectedItem?.inputData?.actionInputData?.branchingInputData
                        ?.branchesInputData,
                      title: e.target.value,
                    },
                  },
                },
              },
            };
            dispatch(updateTempSelectedAiFlowItem(updatedItem));
          }}
        />
        <Typography variant="h5">Branches</Typography>
        {branchItems?.map((branchItem, index) => (
          <Stack spacing={2}>
            <Paper
              variant="outlined"
              sx={{
                p: 1,
                minWidth: 350,
                maxHeight: 130,
                position: 'relative',
                '&:hover': {
                  border: '1px solid',
                  borderColor: 'primary.main',
                },
              }}
            >
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Typography variant="subtitle1">
                  Branch {index + 1}:{'  '}
                  {
                    branchItem.inputData?.actionInputData?.branchingInputData?.branchInputData
                      ?.branchName
                  }
                </Typography>
                <IconButton
                  disabled={branchItems.length <= 2}
                  size="small"
                  onClick={() => {
                    setSelectedItemBranch(branchItem);
                    openDeleteConfirm.onTrue();
                  }}
                >
                  <Iconify icon="bi:trash" />
                </IconButton>
              </Stack>
            </Paper>
          </Stack>
        ))}
        <Button
          sx={{ marginTop: 4 }}
          variant="outlined"
          onClick={() => newBranchDialogOpen.onTrue()}
        >
          Add Branch
        </Button>
      </Stack>
      <ConfirmDialog
        zIndex={4500}
        stopPropagation
        open={openDeleteConfirm.value}
        onClose={() => {
          openDeleteConfirm.onFalse();
        }}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={(event) => {
              event.stopPropagation();
              if (selectedItemBranch === null) {
                return;
              }
              const branchSequence = selectedItemBranch.sequence;
              const itemsBelongToBranch: AiFlowItemLogic[] = [];
              // add to itemsBelongToBranch all items that parentSequence is bigger just by one than branchSequence
              selectedAiFlow
                ?.items!.filter(
                  (item: any) => (item.sequence as number) > (branchSequence as number)
                )
                .some((item: any) => {
                  if (item.parentSequence + 1 === item.sequence) {
                    itemsBelongToBranch.push(item);
                    return false; // continue to the next iteration
                  } else {
                    return true; // exit the loop
                  }
                });
              openDeleteConfirm.onFalse();

              const itemsBelongToBranchWithBranchItem = [
                selectedItemBranch,
                ...itemsBelongToBranch,
              ];
              dispatch(selectAiFlowItem(null));
              dispatch(deleteAiFlowItems(itemsBelongToBranchWithBranchItem));
            }}
          >
            Delete
          </Button>
        }
      />
      <NewBranchDialog open={newBranchDialogOpen.value} onClose={newBranchDialogOpen.onFalse} />
    </>
  );
}
