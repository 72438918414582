import { Stack } from '@mui/system';
import ConnectionSelect from 'src/components/connection/ConnectionSelect';
import ProviderSelect from 'src/components/provider-select/ProviderSelect';
import { setActionValidated, updateSelectedAiFlowItem, updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import AiFlowInputGoogleAnalyticsActionSetup from './system/input/providers/google-analytics/AiFlowInputGoogleAnalyticsActionSetup';
import AiFlowInputHubSpotActionSetup from './system/input/providers/hubspot/AiFlowInputHubSpotActionSetup';
import AiFlowInputMailchimpActionSetup from './system/input/providers/mailchimp/AiFlowInputMailchimpActionSetup';
import AiFlowInputSalesforceActionSetup from './system/input/providers/salesforce/AiFlowInputSalesforceActionSetup';
import { useDispatch, useSelector } from 'src/redux/store';
import { useEffect, useState } from 'react';
import AiFlowOperationsMailchimpActionSetup from './system/input/providers/mailchimp/AiFlowOperationsMailchimpActionSetup';
import PreviousActionsSelect from 'src/components/previous-actions/PreviousActionsSelect';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'src/components/hook-form';

export default function AiFlowOperationsActionSetup() {
  const [provider, setProvider] = useState<string>('');
  const [selectedOperationsSource, setSelectedOperationsSource] = useState<string>();
  const [selectedConnection, setSelectedConnection] = useState<number>();
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const runsMode = useSelector((state) => state.aiFlows.runsMode);

  const defaultValues = {
    selectedSource: '',
  };

  const AiFlowExtractorSchema: any = Yup.object().shape({
 //   selectedSource: Yup.string().required('Required'),
  });
  const methods = useForm<any>({
    resolver: yupResolver(AiFlowExtractorSchema),
    mode: 'all',
    defaultValues,
  });
  const {
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
    trigger,
  } = methods;

  useEffect(() => {
    dispatch(setActionValidated(isValid));
  }, [isValid, dispatch]);

  useEffect(() => {
    if (selectedItem) {
      setSelectedOperationsSource(
        selectedItem?.inputData?.actionInputData?.operationsInputData?.operationsSource as string
      );
      setSelectedConnection(
        selectedItem?.inputData?.actionInputData?.operationsInputData?.providerData
          ?.connectionId as number
      );
      methods.setValue(
        'selectedSource',
        selectedItem?.inputData?.actionInputData?.operationsInputData?.operationsSource as string
      );
      methods.trigger();
      if (selectedItem?.inputData?.actionInputData?.operationsInputData?.providerData) {
        setProvider(
          selectedItem?.inputData?.actionInputData?.operationsInputData?.providerData
            ?.provider as string
        );
      }
    }
  }, [selectedItem, methods]);

  return (
    <>
      <FormProvider methods={methods}>
        <Stack spacing={3}>
          <ProviderSelect
          notInGroups={['large language models']}
          canOnlyBeUsedIn={['Operations']}
          px = {1}
            disabled={runsMode}
            selectedProviderInput={provider}
            handleSelectProvider={(selectedProvider) => {
              setProvider(selectedProvider);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    operationsInputData: {
                      ...selectedItem?.inputData?.actionInputData?.operationsInputData,
                      providerData: {
                        ...selectedItem?.inputData?.actionInputData?.operationsInputData
                          ?.providerData,
                        provider: selectedProvider,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
          />
          <ConnectionSelect
            px={1}
            disabled={runsMode}
            selectedIntegration={provider}
            selectedConnectionInput={selectedConnection}
            onConnectionSelect={(connectionId: number) => {
              setSelectedConnection(connectionId);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    operationsInputData: {
                      ...selectedItem?.inputData?.actionInputData?.operationsInputData,
                      providerData: {
                        ...selectedItem?.inputData?.actionInputData?.operationsInputData
                          ?.providerData,
                        connectionId,
                      },
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
          />
          {/* <PreviousActionsSelect
            selectedSourceInput={selectedOperationsSource}
            onPreviousActionsSelect={(source: string) => {
              setSelectedOperationsSource(source);
              setValue('selectedSource', source);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    operationsInputData: {
                      ...selectedItem?.inputData?.actionInputData?.operationsInputData,
                      operationsSource: source,
                    },
                  },
                },
              };
              dispatch(updateTempSelectedAiFlowItem(updatedItem));
            }}
          /> */}
          {provider === 'Mailchimp' && <AiFlowOperationsMailchimpActionSetup />}
          {provider === 'GoogleAnalytics' && <AiFlowInputGoogleAnalyticsActionSetup />}
          {provider === 'HubSpot' && <AiFlowInputHubSpotActionSetup />}
          {provider === 'Salesforce' && <AiFlowInputSalesforceActionSetup />}
        </Stack>
      </FormProvider>
    </>
  );
}
