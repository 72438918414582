import { Stack, TextField } from '@mui/material';
import MailchimpMethodSelect from './MailchimpMethodSelect';
import { useDispatch, useSelector } from 'src/redux/store';
import { updateSelectedAiFlowItem, updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useEffect, useState } from 'react';
import MailchimpCampaignSelect from './MailchimpCampaignSelect';
import MailchimpListSelect from './MailchimpListSelect';

export default function AiFlowOperationsMailchimpActionSetup() {
  const [selectedCampaignId, setSelectedCampaignId] = useState<string>('');
  const [selectedListId, setSelectedListId] = useState<string>('');
  const [selectedMethod, setSelectedMethod] = useState<string>('List campaigns');
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  useEffect(() => {
    if (selectedItem) {
      setSelectedCampaignId(
        selectedItem?.inputData?.actionInputData?.operationsInputData?.providerData?.mailchimpInputData
          ?.campaignId ?? ''
      );
      setSelectedListId(
        selectedItem?.inputData?.actionInputData?.operationsInputData?.providerData?.mailchimpInputData?.listId ??
          ''
      );
      setSelectedMethod(
        selectedItem?.inputData?.actionInputData?.operationsInputData?.providerData?.mailchimpInputData?.method ??
          ''
      );
    }
  }, [selectedItem]);

  return (
    <Stack direction="column" spacing={2}>
      <MailchimpMethodSelect
        px = {1}
        operations
        selectedMethodInput={selectedMethod}
        handleSelectMethod={(method: any) => {
          const updatedItem = {
            ...selectedItem,
            inputData: {
              ...selectedItem?.inputData,
              actionInputData: {
                ...selectedItem?.inputData?.actionInputData,
                operationsInputData: {
                  ...selectedItem?.inputData?.actionInputData?.operationsInputData,
                  providerData: {
                    ...selectedItem?.inputData?.actionInputData?.operationsInputData?.providerData,
                    method,
                    mailchimpInputData: {
                      ...selectedItem?.inputData?.actionInputData?.operationsInputData?.providerData
                        ?.mailchimpInputData,
                      method,
                    },
                  },
                },
              },
            },
          };

          dispatch(updateTempSelectedAiFlowItem(updatedItem));
        }}
      />

      {selectedMethod === 'List all contacts' && (
        <MailchimpListSelect
        connectionId={selectedItem?.inputData?.actionInputData?.inputData?.providerData?.connectionId ?? 0}
          selectedListInput={selectedListId}
          handleSelectMethod={(listId: string) => {
            setSelectedListId(listId);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  inputData: {
                    ...selectedItem?.inputData?.actionInputData?.inputData,
                    providerData: {
                      ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
                      mailchimpInputData: {
                        ...selectedItem?.inputData?.actionInputData?.inputData?.providerData
                          ?.mailchimpInputData,
                        listId,
                      },
                    },
                  },
                },
              },
            };
            dispatch(updateTempSelectedAiFlowItem(updatedItem));
          }}
        />
      )}
      {(selectedMethod === 'Get campaign info' || selectedMethod === 'Get campaign content') && (
        <MailchimpCampaignSelect
          selectedCampaignInput={selectedCampaignId}
          handleSelectMethod={(campaignId: string) => {
            setSelectedCampaignId(campaignId);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  inputData: {
                    ...selectedItem?.inputData?.actionInputData?.inputData,
                    providerData: {
                      ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
                      mailchimpInputData: {
                        ...selectedItem?.inputData?.actionInputData?.inputData?.providerData
                          ?.mailchimpInputData,
                        campaignId,
                      },
                    },
                  },
                },
              },
            };
            dispatch(updateTempSelectedAiFlowItem(updatedItem));
          }}
        />
      )}
    </Stack>
  );
}
