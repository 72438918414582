import { FormHelperText, Link, Stack, TextField, Typography } from '@mui/material';
import GoogleAnalyticsDimensionsSelect from './GoogleAnalyticsDimensionsSelect';
import { useDispatch, useSelector } from 'src/redux/store';
import GoogleAnalyticsMetricsSelect from './GoogleAnalyticsMetricsSelect';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

import { useEffect, useState } from 'react';
import { RHRadioGroup } from 'src/components/hook-form/RHFRadioGroup';
import { updateSelectedAiFlowItem, updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { DateRange } from '@mui/lab';

export default function AiFlowInputGoogleAnalyticsActionSetup() {
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const [selectedQuickSelect, setSelectedQuickSelect] = useState('Today');
  const [propertyId, setPropertyId] = useState('');
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const [timeFrameOptions, setTimeframeOptions] = useState([
    { label: 'Quick Select', value: 'QuickSelect', disabled: runsMode },
    { label: 'Custom Range', value: 'CustomRange', disabled: runsMode },
  ]);
  const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);
  const [selectedDimensions, setSelectedDimensions] = useState<string[]>([]);
  const [selectedMetrics, setSelectedMetrics] = useState<string[]>([]);
  const [timeframeType, setTimeframeType] = useState('QuickSelect');

  useEffect(() => {
    if (selectedItem) {
      setSelectedDimensions(
        selectedItem?.inputData?.actionInputData?.inputData?.providerData?.googleAnalyticsInputData
          ?.dimensions ?? []
      );
      setSelectedMetrics(
        selectedItem?.inputData?.actionInputData?.inputData?.providerData?.googleAnalyticsInputData
          ?.metrics ?? []
      );
      setTimeframeType(
        selectedItem?.inputData?.actionInputData?.inputData?.providerData?.googleAnalyticsInputData
          ?.timeframeType ?? 'QuickSelect'
      );
      setSelectedQuickSelect(
        selectedItem?.inputData?.actionInputData?.inputData?.providerData?.googleAnalyticsInputData
          ?.selectedQuickSelect ?? 'Last7Days'
      );
      setPropertyId(
        selectedItem?.inputData?.actionInputData?.inputData?.providerData?.googleAnalyticsInputData
          ?.propertyId ?? ''
      );
      const from =
        selectedItem?.inputData?.actionInputData?.inputData?.providerData?.googleAnalyticsInputData
          ?.from;
      const to =
        selectedItem?.inputData?.actionInputData?.inputData?.providerData?.googleAnalyticsInputData
          ?.to;
      if (from && to) {
        setDateRange([new Date(from), new Date(to)
          
        ]);
        console.log('from', from);
        console.log('to', to);
      }
    }
  }, [selectedItem]);
  const dispatch = useDispatch();
  return (
    <Stack direction="column" spacing={2}>
      <TextField
        size="small"
        autoFocus
        value={propertyId}
        onChange={(event) => {
          const value = event.target.value;
          if (!/^\d{0,9}$/.test(value)) {
            return;
          }
          setPropertyId(event.target.value);
          const updatedItem = {
            ...selectedItem,
            inputData: {
              ...selectedItem?.inputData,
              actionInputData: {
                ...selectedItem?.inputData?.actionInputData,
                inputData: {
                  ...selectedItem?.inputData?.actionInputData?.inputData,
                  providerData: {
                    ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
                    googleAnalyticsInputData: {
                      ...selectedItem?.inputData?.actionInputData?.inputData?.providerData
                        ?.googleAnalyticsInputData,
                      propertyId: event.target.value,
                    },
                  },
                },
              },
            },
          };
          dispatch(updateTempSelectedAiFlowItem(updatedItem));
        }}
        label="Property Id"
        placeholder="e.g. 492466184"
        helperText={
          <FormHelperText sx={{ marginLeft: '0px !important' }}>
            <Typography variant="caption">
              Enter your Property Id. For more information, visit{' '}
              <Link
                href="https://developers.google.com/analytics/devguides/reporting/data/v1/property-id"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Analytics Help
              </Link>
              .
            </Typography>
          </FormHelperText>
        }
      />

      <GoogleAnalyticsDimensionsSelect
        dimensionsInput={selectedDimensions}
        onDimensionsSelect={(dimensions: any[]) => {
          const updatedItem = {
            ...selectedItem,
            inputData: {
              ...selectedItem?.inputData,
              actionInputData: {
                ...selectedItem?.inputData?.actionInputData,
                inputData: {
                  ...selectedItem?.inputData?.actionInputData?.inputData,
                  providerData: {
                    ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
                    googleAnalyticsInputData: {
                      ...selectedItem?.inputData?.actionInputData?.inputData?.providerData
                        ?.googleAnalyticsInputData,
                      dimensions: dimensions.map((dimension) => dimension.name),
                    },
                  },
                },
              },
            },
          };
          dispatch(updateTempSelectedAiFlowItem(updatedItem));
        }}
      />
      <GoogleAnalyticsMetricsSelect
        metricsInput={selectedMetrics}
        onMetricsSelect={(metrics: any[]) => {
          const updatedItem = {
            ...selectedItem,
            inputData: {
              ...selectedItem?.inputData,
              actionInputData: {
                ...selectedItem?.inputData?.actionInputData,
                inputData: {
                  ...selectedItem?.inputData?.actionInputData?.inputData,
                  providerData: {
                    ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
                    googleAnalyticsInputData: {
                      ...selectedItem?.inputData?.actionInputData?.inputData?.providerData
                        ?.googleAnalyticsInputData,
                      metrics: metrics.map((metric) => metric.name),
                    },
                  },
                },
              },
            },
          };
          dispatch(updateTempSelectedAiFlowItem(updatedItem));
        }}
      />
      <RHRadioGroup
        value={timeframeType}
        onChange={(event) => {
          setTimeframeType(event.target.value);
          const updatedItem = {
            ...selectedItem,
            inputData: {
              ...selectedItem?.inputData,
              actionInputData: {
                ...selectedItem?.inputData?.actionInputData,
                inputData: {
                  ...selectedItem?.inputData?.actionInputData?.inputData,
                  providerData: {
                    ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
                    googleAnalyticsInputData: {
                      ...selectedItem?.inputData?.actionInputData?.inputData?.providerData
                        ?.googleAnalyticsInputData,
                      timeframeType: event.target.value,
                    },
                  },
                },
              },
            },
          };
          dispatch(updateTempSelectedAiFlowItem(updatedItem));
        }}
        row
        spacing={4}
        name="timeframes"
        options={timeFrameOptions}
      />
      {timeframeType === 'QuickSelect' && (
        <RHRadioGroup
          value={selectedQuickSelect}
          onChange={(event) => {
            setSelectedQuickSelect(event.target.value);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  inputData: {
                    ...selectedItem?.inputData?.actionInputData?.inputData,
                    providerData: {
                      ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
                      googleAnalyticsInputData: {
                        ...selectedItem?.inputData?.actionInputData?.inputData?.providerData
                          ?.googleAnalyticsInputData,
                        selectedQuickSelect: event.target.value,
                      },
                    },
                  },
                },
              },
            };
            dispatch(updateTempSelectedAiFlowItem(updatedItem));
          }}
          row
          spacing={5}
          name="quickSelectTimeframes"
          options={[
            { label: 'Today', value: 'Today' },
            { label: 'Yesterday', value: 'Yesterday' },
            { label: 'Last 7 Days', value: 'Last7Days' },
            { label: 'Last 30 Days', value: 'Last30Days' },
            { label: 'Last 90 Days', value: 'Last90Days' },
            { label: 'Last 365 Days', value: 'Last365Days' },
          ]}
        />
      )}
      {timeframeType === 'CustomRange' && (
        <DateRangePicker
          value={dateRange}
          onChange={(value) => {
            const toUTCMidnight = (date:Date) => {
              if (!date) return null;
              const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
              return utcDate.toUTCString();
            };
        
            const fromTmp = value[0] ? toUTCMidnight(new Date(value[0])) : null;
            const toTmp = value[1] ? toUTCMidnight(new Date(value[1])) : null;
            
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  inputData: {
                    ...selectedItem?.inputData?.actionInputData?.inputData,
                    providerData: {
                      ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
                      googleAnalyticsInputData: {
                        ...selectedItem?.inputData?.actionInputData?.inputData?.providerData
                          ?.googleAnalyticsInputData,
                        from: fromTmp,
                        to: toTmp
                      },
                    },
                  },
                },
              },
            };
            dispatch(updateTempSelectedAiFlowItem(updatedItem));
          }}
          slotProps={{
            popper: {
              // Targeting the popover component
              sx: {
                // Adding styles to the popover component
                zIndex: 9000, // Set your desired zIndex value
              },
            },
          }}
          localeText={{ start: 'Start Date', end: 'End Date' }}
        />
      )}
    </Stack>
  );
}
