// utils

import { createSlice, Dispatch } from '@reduxjs/toolkit';
import {
  ContentGeneration,
  ContentIds,
  ContentTransformation,
  CraftGeneration,
  IContentState as ICraftState,
} from 'src/@types/content';
import {
  CraftApi,
  CraftRequestLogic,
  PauseGenerateContentRequest,
} from 'src/api';
import { setOpenPromptFeedbackToolbar } from './promptFeedbackToolbar';
import { ContentType } from 'src/common/enums/content-type.enum';

// ----------------------------------------------------------------------

const initialState: ICraftState = {
  error: null,
  lastCrafted: {
    craftedContent: {},
    contentType: ContentType.Generated,
    finishedCrafting: false,
    isCrafting: false,
    contentId: {},
  },
  crafted: {},
  followUps: [],
  loading: false,
};

const slice = createSlice({
  name: 'craft',
  initialState,
  reducers: {
    // setSetupGeneratedContent(state, action) {
    //   if (state.lastGenerated) {
    //     if (!state.lastGenerated?.contentId) {
    //       state.lastGenerated.contentId = {};
    //     }
    //     if (action.payload.completionStarting && state.lastGenerated.actionType) {
    //       // state.lastGenerated.contentId[state.lastGenerated.actionType] =
    //       //   action.payload.generatedId;
    //     } else if (action.payload.completionFinished) {
    //       state.lastGenerated.finishedGenerating = true;
    //       state.lastGenerated.isGenerating = false;
    //     } else {
    //       const content = state.lastGenerated.generatedContent;
    //       state.lastGenerated.generatedContent = content + action.payload.content;
    //       state.lastGenerated.lastWord = action.payload.content;
    //     }
    //     // if (action.payload.additionalData) {
    //     //   state.generated.additionalData =
    //     //     action.payload.additionalData;
    //     // }
    //   }
    // },
    initCrafted(state) {
      const crafted = state.lastCrafted as CraftGeneration;
      crafted.finishedCrafting = false;
      crafted.isCrafting = true;
    },
    setCraftedContent(state, action) {
      const crafted = state.lastCrafted as CraftGeneration;
      if (!crafted.craftedContent[action.payload.contentType]) {
        crafted.craftedContent[action.payload.contentType] = {};
      }
      crafted.craftedContent[action.payload.contentType][action.payload.actionType] =
        action.payload.content;
      if (!crafted.contentId[action.payload.contentType]) {
        crafted.contentId[action.payload.contentType] = {};
      }
      crafted.contentId[action.payload.contentType][action.payload.actionType] =
        action.payload.contentId;
      crafted.finishedCrafting = true;
      crafted.isCrafting = false;
    },
    // pausedGeneratedContent(state, action) {
    //   if (state.lastGenerated) {
    //     state.lastGenerated.isGenerating = false;
    //     state.lastGenerated.finishedGenerating = true;
    //   }
    // },
    // setGeneratedContent(state, action) {
    //   if (state.lastGenerated) {
    //     if (!state.lastGenerated?.contentId) {
    //       state.lastGenerated.contentId = {};
    //     }

    //     state.lastGenerated.generatedContent[action.payload.type] = action.payload.content;
    //     state.lastGenerated.actionType = action.payload.type;
    //     state.lastGenerated.contentId[action.payload.type] = action.payload.generatedId;

    //     state.lastGenerated.finishedGenerating = true;
    //     state.lastGenerated.isGenerating = false;
    //   }
    // },
    startFetching(state) {
      state.loading = true;
    },
    addFollowUp(state, action) {
      state.followUps.push(action.payload);
    },
    setCrafted(state, action) {
      state.crafted[action.payload.contentType] = action.payload.craftedContents;
      state.loading = false;
    },
    addCrafted(state, action) {
      state.crafted[action.payload.contentType].push(action.payload.craftedContent);
    },
    // deleteUserGeneratedContentsSuccess(state, action) {
    //   const userGeneratedContentsIds = action.payload.userGeneratedContentsIds as number[];
    //   state.generatedContents = state.generatedContents.filter(
    //     (generatedContent) =>
    //       !userGeneratedContentsIds.includes(generatedContent. as number)
    //   );
    // },
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
 // setSetupGeneratedContent,
  setCrafted,
  setCraftedContent: setContent,
  addCrafted,
  addFollowUp,
  hasError,
} = slice.actions;

// ----------------------------------------------------------------------

export function getGeneratedContentStream(generateContentRequest: any) {
  return async (dispatch: Dispatch) => {
    try {
      //   dispatch(slice.actions.initGeneratedContent(generateContentRequest));
      const craftApi = new CraftApi();
      await craftApi.apiCraftGenerateContentStreamPost(generateContentRequest);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function pauseGeneratedContentStream(
  pauseRequest: PauseGenerateContentRequest,
  type: string
) {
  return async (dispatch: Dispatch) => {
    try {
      const craftApi = new CraftApi();
      await craftApi.apiCraftPauseGenerateContentStreamPut(pauseRequest);
  //    dispatch(slice.actions.pausedGeneratedContent({ type }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function fetchGeneratedContent(craftContentRequest: CraftRequestLogic) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.initCrafted());
      const craftApi = new CraftApi();

      const response = await craftApi.apiCraftGeneratePost(craftContentRequest);
      const obj = {
        actionType: craftContentRequest.header,
        content: response.data.content,
        contentType: ContentType.Generated,
        contentId: response.data.contentId,
      };
      
      dispatch(setContent(obj));
      dispatch(addCrafted(obj));
      dispatch(setOpenPromptFeedbackToolbar(true));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function fetchTransformedContent(craftContentRequest: CraftRequestLogic) {
//   return async (dispatch: Dispatch) => {
//     try {
//       dispatch(slice.actions.initCrafted());
//       const craftApi = new CraftApi();
//       const response = await craftApi.apiCraftTransformPost(craftContentRequest);
//       const obj = {
//         actionType: craftContentRequest.actionType,
//         content: response.data.content,
//         contentType: ContentType.Transformed,
//         contentId: response.data.contentId,
//       };
//       dispatch(setContent(obj));
//       dispatch(addCrafted(obj));
//       dispatch(setOpenPromptFeedbackToolbar(true));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }